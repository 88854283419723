import bowser from 'bowser';

export const getClientInfo = () => {
  const client = bowser.parse(window.navigator.userAgent);
  const { browser, os, platform } = client;
  return {
    browser: {
      name: browser.name,
      version: browser.version
    },
    os: {
      name: os.name,
      version: os.version
    },
    platform: {
      type: platform.type,
      model: platform.model,
      version: platform.version
    }
  };
};
