import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { resetProgress } from 'store/actions/student';
import { setAppModule } from 'store/actions/ui';
import wdtLogo from 'assets/photo/login/wdt-logo.png';
import Header from './components/Header';
import { getAudioAsset } from '../../utils/get-audio-asset';
import { useLwtStore } from '@lwtears/lwt-common-frontend/lib/LwtApp';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const Modules = ({ history, modules, resetProgress, setAppModule }) => {
  if (!modules) return null;
  const wdt = modules.find(m => m.module === 'wdt');
  if (!wdt) return null;
  const capitals = wdt.submodules.find(m => m.module === 'wdt-capitals');
  const numbers = wdt.submodules.find(m => m.module === 'wdt-numbers');
  const lowercase = wdt.submodules.find(m => m.module === 'wdt-lowercase');

  const [disabled, setDisabled] = useState(false);
  const [
    {
      settings: {
        accessibility: {
          displaySettings: { contentScaling }
        }
      }
    }
  ] = useLwtStore();

  useEffect(() => {
    const moduleType = sessionStorage.getItem('moduleType');
    const loaded = sessionStorage.getItem('loaded');
    if (moduleType && loaded) {
      progress(wdt.submodules.find(m => m.module === moduleType));
      sessionStorage.removeItem('loaded');
      sessionStorage.removeItem('moduleType');
    }
  });

  const progress = ({ module }) => {
    resetProgress();
    setAppModule(module);
    history.push('/reward-screen');
  };

  const onSelect = async module => {
    if (disabled) return;
    if (!module || module.disabled) return;

    setDisabled(true);
    const sound = await getAudioAsset('', module.audio.src);
    if ('once' in sound) {
      sound.once('end', () => progress(module));
      sound.once('end', () => sound.unload());
      sound.play();
    } else {
      progress(module);
    }
  };

  const appCardsDetails = [
    { id: 'capitals', appType: capitals, headerTitle: tA11y('printCapitals') },
    { id: 'numbers', appType: numbers, headerTitle: tA11y('numbers') },
    { id: 'lowercase', appType: lowercase, headerTitle: tA11y('printLowercase') }
  ];

  return (
    <div className="login">
      <div className={`container zoom${contentScaling}`}>
        <Header />
        <img alt={tA11y('logo')} src={wdtLogo} className="wdt-logo" />
        <h1 className="wdt-h1 appSelectionHeader">{tA11y('selectType')}</h1>
        <div className="group">
          {appCardsDetails.map(appCardDetail => (
            <button
              id={appCardDetail.id}
              key={appCardDetail.id}
              onClick={() => onSelect(appCardDetail.appType)}
              className={[
                'wdt-button',
                'box',
                appCardDetail.appType.disabled ? 'disabled' : ''
              ].join(' ')}
              aria-label={appCardDetail.headerTitle}
            >
              <img src={appCardDetail.appType.image.src} alt={appCardDetail.headerTitle} />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

Modules.propTypes = {
  history: PropTypes.object,
  modules: PropTypes.array,
  setAppModule: PropTypes.func,
  resetProgress: PropTypes.func,
  contentScaling: PropTypes.number
};

const mapStateToProps = state => ({
  modules: state.login.modules
});

const mapDispatchToProps = {
  resetProgress,
  setAppModule
};

const ConnectedModule = connect(mapStateToProps, mapDispatchToProps)(Modules);
export default ConnectedModule;
