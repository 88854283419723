const characterAssets = require.context('../assets/photo/character-lists', true, /.png$/);

export const getCharacterAssets = path => {
  let assetPath = null;

  return new Promise(resolve => {
    try {
      assetPath = characterAssets(`${path}`);
    } catch (e) {
      assetPath = '';
    }
    resolve(assetPath.default);
  });
};
