import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DrawActivityContainer from 'components/draw-activity/draw-activity-container';
import DrawActivityIntro from 'components/draw-activity/draw-activity-intro';
import DrawActivityTutorial from 'components/draw-activity/draw-activity-tutorial';
import Transition from 'components/transition/transition';
import DrawActivityGame from 'components/draw-activity/draw-activity-game';
import { errorLoggerLogError } from 'store/actions/errorLogger';
import { getLiteData, handleActivityStart, handleRoundEnd } from 'store/actions/activity';
import { setHideBackButton, setShowBackButton, setAnimationsMode } from 'store/actions/ui';
import TimeoutList from '../../utils/timeout-list';
import { SoundTypes } from 'constants/enums/sound-types';
import { audioManager } from 'utils/draw-activity/audio-manager';
import getStickerPath from 'utils/get-sticker-path';
import { CharacterType, CharacterCase } from 'constants/enums/characters';
import { withRouter } from 'react-router-dom';
import paper from 'paper/dist/paper-full.min';
import getDrawConfiguration from 'utils/draw-config';
import {
  formatCharacterCase,
  getNextCharacter,
  getNextCharacterCase
} from 'utils/lite-route-char-arr-query-param-util';

import KWTModal from 'components/KWTModal';
import ButtonOverlay from 'components/ButtonOverlay';
import SuccessErrorMessage from 'components/SuccessErrorMessage';
import ZoomScrollManager from '../../utils/activity-zoom-scroll-manager';

class DrawActivityPage extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.drawComplete = false;
    this._canvas = React.createRef();
    this._container = React.createRef();
    this.currentScope = null;
    this.introSoundTypes = [
      SoundTypes.FIRST_WATCH_ME,
      SoundTypes.NOW_YOU_TRY,
      SoundTypes.LETS_TRY,
      SoundTypes.STARTS,
      SoundTypes.CARD_DESCRIPTION
    ];

    const {
      activityData: { character: { character, characterType, characterCase } } = {},
      accessibilityMode
    } = props;

    this.stickerPath = getStickerPath(characterType, characterCase, character);

    this.timeoutList = new TimeoutList();
    this.showStartButton = false;
    this.handleRestart = this.handleRestart.bind(this);

    this.zoomScrollManager = new ZoomScrollManager(
      newTolerance => this.setState({ zoomToleranceFactor: newTolerance }),
      this.handleRestart,
      accessibilityMode
    );
  }

  getInitialState() {
    return {
      shouldRenderChildren: true,
      displayIntro: true,
      displayTutorial: false,
      displayGame: false,
      renderDashedChalkboard: false,
      displayCongrats: false,
      introSoundsLoaded: false,
      svgSoundsLoaded: false,
      svgLoaded: false,
      gameStarted: false,
      introPlayFinished: false,
      zoomToleranceFactor: 1
    };
  }

  componentDidMount() {
    const {
      props: {
        setHideBackButton,
        setShowBackButton,
        isLiteVersion,
        parentZoomFactor,
        activityData: {
          character: { character, characterType, characterCase }
        }
      }
    } = this;

    setHideBackButton();
    if (!character) {
      setShowBackButton();
      return;
    } else {
      this.drawConfig = getDrawConfiguration(character, characterType, characterCase);
      this.currentScope = new paper.PaperScope();
      // don't start game, user needs to click play button to activate sounds
      !isLiteVersion && this.handleStartGame();
    }

    this.zoomScrollManager.initialize(this.activityContainerRef, parentZoomFactor);
  }

  initializeGame() {
    audioManager.pauseSound(SoundTypes.BACKGROUND);
    audioManager.resetSounds();
  }

  handleStartGame = () => {
    if (!this.state.gameStarted) {
      this.setState({ gameStarted: true });
      this.playIntroSounds();
    }
  };

  playIntroSounds = () => {
    audioManager.playSound(SoundTypes.LETS_TRY, () => {
      audioManager.playSound(SoundTypes.FIRST_WATCH_ME, () => {
        audioManager.playSound(SoundTypes.STARTS, playbackData => {
          this.timeoutList.add(
            () => this.setState({ introPlayFinished: true }),
            playbackData && playbackData.emptyAudio ? 1000 : 0
          );
        });
      });
    });
  };

  handleSvgLoaded = () => {
    this.setState({ svgLoaded: true, svgSoundsLoaded: true });
    !this.props.isLiteVersion && this.handleStartGame();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.introPlayFinished && this.state.svgSoundsLoaded && this.state.displayIntro) {
      this.stopIntro();
    }

    if (prevState.shouldRenderChildren !== this.state.shouldRenderChildren) {
      if (!this.state.shouldRenderChildren) {
        this.manageRestart();
      }
    }
  }

  manageRestart() {
    this.timeoutList.cancelAll();
    this.timeoutList.add(() => {
      // don't play intro sounds for lite version as it has an overlay with a play button
      !this.props.isLiteVersion && this.playIntroSounds();
      this.setState({ ...this.getInitialState(), svgSoundsLoaded: true });
    }, 400);
  }

  componentWillUnmount() {
    audioManager.cancelDownloads();
    audioManager.resetPointerFlag();

    this.paperScope && this.paperScope.clear();
    const CVS = document.getElementById('project');
    if (CVS !== null) {
      const context = CVS.getContext('2d');
      context.clearRect(0, 0, CVS.width, CVS.height);
      CVS.remove();
    }
    paper.clear();
    this.timeoutList.cancelAll();

    this.zoomScrollManager.removeWindowResizeEvents();
  }

  handleBackgroundVisible = () => {
    this.setState({
      displayTutorial: true
    });
  };

  handleTutorialComplete = () => {
    this.timeoutList.add(
      () =>
        this.setState({
          displayGame: true
        }),
      2000
    );

    this.props.handleActivityStart();
  };

  handleDrawComplete = () => {
    this.drawComplete = true;
  };

  handleGameComplete = noCongrats => {
    setTimeout(
      () => {
        this.handleCongratsEnd();
      },
      noCongrats ? 1000 : 0
    );
  };

  stopIntro = () => {
    this.setState({
      displayIntro: false
    });
  };

  handleIntroOver = () => {
    const {
      props: {
        activityData: {
          character: { characterCase, characterType }
        }
      }
    } = this;

    const hasFrame = this.hasFrame(characterType, characterCase);

    this.setState({
      renderDashedChalkboard: !hasFrame,
      displayTutorial: hasFrame
    });
  };

  handleCongratsEnd = async () => {
    if (!this.props.isLiteVersion) {
      await this.props.handleRoundEnd();
      this.handleBack(true);
    } else {
      const {
        activityData: {
          character: { character, characterCase, characterType },
          level,
          liteCharacterSequence,
          liteCharacterCaseSequence
        },
        getLiteData
      } = this.props;
      const currentLiteCharacterPosition = liteCharacterSequence.indexOf(
        formatCharacterCase(character, CharacterCase.LOWERCASE)
      );
      const currentLiteCharacterCasePosition = liteCharacterCaseSequence.indexOf(characterCase);

      const nextLiteCharacter = getNextCharacter({
        currentCharPos: currentLiteCharacterPosition,
        currentCharCasePos: currentLiteCharacterCasePosition,
        charSeq: liteCharacterSequence,
        charCaseSeq: liteCharacterCaseSequence
      });
      const nextLiteCharacterCase = getNextCharacterCase(
        currentLiteCharacterCasePosition,
        liteCharacterCaseSequence
      );

      nextLiteCharacter
        ? await getLiteData({
            character: formatCharacterCase(nextLiteCharacter, nextLiteCharacterCase),
            liteCharacterSequence,
            characterType,
            characterCase: nextLiteCharacterCase,
            liteCharacterCaseSequence,
            level
          })
        : console.log('game ended!'); // eslint-disable-line no-console
    }
  };

  handleRestart = () => {
    audioManager.pauseAllSounds();

    this.setState({
      shouldRenderChildren: false
    });
    this.props.setAnimationsMode(true);
  };

  handleBack = isAtCongratsEnd => {
    if (!this.drawComplete || isAtCongratsEnd) {
      this.props.history.push('/reward-screen');
    }
  };

  hasFrame(characterType, characterCase) {
    return !(
      (characterType === CharacterType.PRINT && characterCase === CharacterCase.LOWERCASE) ||
      characterType === CharacterType.CURSIVE
    );
  }

  hasHighSingleLineBackground(characterType, character) {
    return (
      characterType === CharacterType.CURSIVE &&
      (character === 'J' || character === 'Y' || character === 'Z')
    );
  }

  isFullScreenDrawActivity() {
    const {
      props: {
        activityData: {
          character: { characterCase, characterType }
        }
      }
    } = this;

    return characterCase === CharacterCase.LOWERCASE || characterType === CharacterType.CURSIVE;
  }

  isLandscapeActivity() {
    const {
      props: {
        activityData: {
          character: { character }
        }
      }
    } = this;

    return character && character.length === 2;
  }

  handleTabTableSelection() {
    document
      .getElementById('navigation')
      ?.querySelector('button')
      .focus();
  }

  render() {
    const { show: showSuccesError, message } = this.props.successErrorMessage;
    const {
      props: {
        activityData: {
          character: { character, characterCase, characterType },
          level,
          segments,
          svg
        },
        isLiteVersion,
        isWebApplication,
        setShowBackButton,
        errorLoggerLogError
      },
      state: {
        gameStarted,
        renderDashedChalkboard,
        shouldRenderChildren,
        displayIntro,
        displayTutorial,
        displayGame
      }
    } = this;

    return (
      <div
        className={`draw-activity-pg ${isLiteVersion && 'lite'} ${
          this.isLandscapeActivity() ? 'landscape' : ''
        }`}
        ref={node => (this.activityContainerRef = node)}
        // onKeyUp={e => (e.key === 'Tab' ? this.handleTabTableSelection() : '')}
      >
        {process.env.REACT_APP_BUILD_ENV !== 'production' &&
          process.env.REACT_APP_BUILD_ENV !== 'stage' &&
          !isLiteVersion && (
            <button
              className={'wdt-button secondary skip-draw'}
              onClick={() => this.handleGameComplete()}
            >
              Skip me!
            </button>
          )}
        {isLiteVersion && !gameStarted && (
          <KWTModal whiteBg={true} show={!gameStarted}>
            <ButtonOverlay onClick={this.handleStartGame} />
          </KWTModal>
        )}
        <DrawActivityContainer
          show={gameStarted}
          noBackButton={true}
          noRestartButton={!isLiteVersion}
          onBack={this.handleBack}
          onRestart={this.handleRestart}
          landscapeBackground={this.isLandscapeActivity()}
          singleLineBackground={
            characterType === CharacterType.CURSIVE && characterCase === CharacterCase.UPPERCASE
          }
          highLine={this.hasHighSingleLineBackground(characterType, character)}
          hasFrame={this.hasFrame(characterType, characterCase)}
          frameHasCorners={false}
          displayBackground={renderDashedChalkboard}
          renderChildren={shouldRenderChildren}
          onBackgroundVisible={this.handleBackgroundVisible}
        >
          {showSuccesError && <SuccessErrorMessage message={message} />}
          <Transition
            name="fade"
            startDuration={5000}
            endDuration={100}
            className="intro-container"
          >
            {displayIntro ? (
              <DrawActivityIntro
                key="1"
                stickerPath={this.stickerPath}
                starAmount={level}
                smallSticker={false}
                isLiteVersion={isLiteVersion}
                onLeave={this.handleIntroOver}
                character={character}
                characterType={characterType}
                characterCase={characterCase}
              />
            ) : null}
          </Transition>
          {displayTutorial ? (
            <>
              <div className="draw-activity-stage" ref={this._container}>
                <canvas ref={this._canvas} id="project" />
              </div>
              <DrawActivityTutorial
                key="1"
                characterSVGPath={svg}
                character={character}
                characterType={characterType}
                characterCase={characterCase}
                landscape={this.isLandscapeActivity()}
                fullscreen={this.isFullScreenDrawActivity()}
                leftHand={false}
                onComplete={this.handleTutorialComplete}
                isWebApplication={isWebApplication}
                paperScope={this.currentScope}
                canvas={this._canvas}
                container={this._container}
                setShowBackButton={setShowBackButton}
                segments={segments}
                handleAutoScroll={this.zoomScrollManager.handleIntroAutoScroll}
              />
            </>
          ) : null}{' '}
          {displayGame ? (
            <DrawActivityGame
              characterSVGPath={svg}
              character={character}
              characterType={characterType}
              svgNameList={this.svgNameList}
              characterCase={characterCase}
              strayTolerance={characterCase === CharacterCase.LOWERCASE ? 45 : 30}
              easierStrokeTolerance={false}
              starAmount={level}
              leftHand={false}
              landscape={this.isLandscapeActivity()}
              fullscreen={this.isFullScreenDrawActivity()}
              isWebApplication={isWebApplication}
              onDrawComplete={this.handleDrawComplete}
              onComplete={this.handleGameComplete.bind(this, false)}
              onUpdateErrorCount={errorLoggerLogError}
              paperScope={this.currentScope}
              canvas={this._canvas}
              segments={segments}
              zoomToleranceFactor={this.zoomScrollManager.toleranceFactor}
              handleAutoScroll={this.zoomScrollManager.handleGameAutoScroll}
              resetAutoScroll={this.zoomScrollManager.resetAutoScrollPosition}
            />
          ) : null}{' '}
        </DrawActivityContainer>

        <object
          style={{ width: 0, height: 0, position: 'absolute', top: 0, left: 0 }}
          data={svg}
          type="image/svg+xml"
          id="alphasvg"
          onLoad={this.handleSvgLoaded}
        />
      </div>
    );
  }
}

DrawActivityPage.propTypes = {
  activityData: PropTypes.object,
  appMode: PropTypes.string,
  singleDrawActivity: PropTypes.bool,
  history: PropTypes.object,
  isWebApplication: PropTypes.bool,
  errorLoggerLogError: PropTypes.func.isRequired,
  handleActivityStart: PropTypes.func.isRequired,
  handleRoundEnd: PropTypes.func.isRequired,
  setHideBackButton: PropTypes.func,
  setShowBackButton: PropTypes.func,
  isLiteVersion: PropTypes.bool,
  accessibilityMode: PropTypes.bool,
  successErrorMessage: PropTypes.shape({
    show: PropTypes.bool,
    message: PropTypes.string
  }),
  animationsModeOn: PropTypes.bool,
  onGoingAnimation: PropTypes.object,
  setAnimationsMode: PropTypes.func,
  parentZoomFactor: PropTypes.number,
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  getLiteData: PropTypes.func
};

const mapStateToProps = state => ({
  activityData: state.activity.data,
  appMode: state.ui.appMode,
  level: state.activity.data.level,
  levelOverride: state.activity.levelOverride,
  isWebApplication: !state.ui.isMobile,
  isLiteVersion: state.ui.isLiteVersion,
  successErrorMessage: state.ui.successErrorMessage,
  animationsModeOn: state.ui.animationsModeOn,
  onGoingAnimation: state.ui.onGoingAnimation,
  parentZoomFactor: state.ui.parentZoomFactor,
  accessibilityMode: state.settings.accessibilityMode
});

const mapActionsToProps = {
  handleActivityStart,
  handleRoundEnd,
  errorLoggerLogError,
  setHideBackButton,
  setShowBackButton,
  setAnimationsMode,
  getLiteData
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(DrawActivityPage));
