import { getZoomFactor } from '@lwtears/lwt-common-frontend/lib/@common/util/zoom-util';
import TimeoutList from './timeout-list';

export const zoomedContainerClassName = 'content-zoomed';

const GAME_AUTO_SCROLL_AMOUNT = 5;
const GAME_TRANSPORT_PATH_AUTO_SCROLL_AMOUNT = 10;
const GAME_AUTO_SCROLL_THRESHOLD = 50;

const INTRO_AUTO_SCROLL_AMOUNT = 20;
const INTRO_AUTO_SCROLL_THRESHOLD_TOP = 150;
const INTRO_AUTO_SCROLL_THRESHOLD_BOTTOM = 20;

const TOLERANCE_FACTOR = 3;

class ZoomScrollManager {
  constructor(
    onToleranceChangedCallback,
    onZoomChangedCallback,
    hasNav = false,
    activityContainerClassname
  ) {
    this.activityContainerClassname = activityContainerClassname;
    this.onToleranceChangedCallback = onToleranceChangedCallback;
    this.onZoomChangedCallback = onZoomChangedCallback;
    this.gameScrollTreshold = GAME_AUTO_SCROLL_THRESHOLD;
    this.gameScrollAmount = GAME_AUTO_SCROLL_AMOUNT;
    this.timeoutList = new TimeoutList();
    this.hasNav = hasNav;
  }

  isZoomed = () => this.zoomFactor > 1;

  getZoomToleranceFactor = () => (this.isZoomed() ? TOLERANCE_FACTOR : 1);

  initialize = (containerRef, parentZoomFactor) => {
    this.containerRef = containerRef;
    this.parentZoomFactor = parentZoomFactor;
    this.setZoomParams();
    this.setZoomContainerClass();
    this.registerWindowResizeEvents();
    this.setDefaultActivitySize(this.containerRef, this.zoomFactor, [
      'height',
      'width',
      'max-height'
    ]);
    this.setActivitySize(this.containerRef);
  };

  setZoomParams = () => {
    this.zoomFactor = this.parentZoomFactor || getZoomFactor();
    this.toleranceFactor = this.getZoomToleranceFactor();
    this.onToleranceChangedCallback(this.toleranceFactor);
  };

  resetZoomedStyles = () => {
    const appContainer = document.querySelector('#root');
    appContainer && appContainer.setAttribute('style', '');
    this.containerRef && this.containerRef.setAttribute('style', '');
    const doubleLinesBg = document.querySelector(
      '.draw-activity-container .background.frameless > img'
    );
    doubleLinesBg && doubleLinesBg.setAttribute('style', '');
  };

  setZoomContainerClass = () => {
    const query = this.activityContainerClassname ? `.${this.activityContainerClassname}` : 'body';
    const gameContainer = document.querySelector(query);

    if (!gameContainer) {
      return;
    }

    if (this.isZoomed()) {
      gameContainer.classList.add(zoomedContainerClassName);
      gameContainer.setAttribute('tabindex', '0');
    } else {
      gameContainer.classList.remove(zoomedContainerClassName);
      gameContainer.setAttribute('tabindex', null);
      this.resetZoomedStyles();
    }
  };

  onWindowResize = () => {
    this.timeoutList.cancelAll();

    this.timeoutList.add(() => {
      this.setZoomParams();
      this.setZoomContainerClass();
      this.onZoomChangedCallback();
      this.setActivitySize(this.containerRef);
    }, 1000);
  };

  registerWindowResizeEvents = () => {
    window.addEventListener('resize', this.onWindowResize);
  };

  removeWindowResizeEvents = () => {
    window.removeEventListener('resize', this.onWindowResize);
  };

  resetAutoScrollPosition = () => {
    const container = document.querySelector(`.${zoomedContainerClassName}`);

    if (!container) {
      return;
    }

    container.scroll(0, 0);
  };

  handleGameAutoScroll = (cursorX, cursorY, isTransportPath) => {
    const container = document.querySelector(`.${zoomedContainerClassName}`);

    let scrollX = 0;
    let scrollY = 0;

    if (!container) {
      return;
    }

    const scrollAmount = isTransportPath
      ? GAME_TRANSPORT_PATH_AUTO_SCROLL_AMOUNT
      : this.gameScrollAmount;

    if (container.clientHeight + container.scrollTop - cursorY <= this.gameScrollTreshold) {
      scrollY = container.scrollTop + scrollAmount;
    } else if (cursorY - container.scrollTop <= this.gameScrollTreshold) {
      scrollY = container.scrollTop - scrollAmount;
    } else {
      scrollY = container.scrollTop;
    }

    if (container.clientWidth + container.scrollLeft - cursorX <= this.gameScrollTreshold) {
      scrollX = container.scrollLeft + scrollAmount;
    } else if (cursorX - container.scrollLeft <= this.gameScrollTreshold) {
      scrollX = container.scrollLeft - scrollAmount;
    } else {
      scrollX = container.scrollLeft;
    }

    container.scroll(scrollX, scrollY);
  };

  handleIntroAutoScroll = (cursorX, cursorY) => {
    const container = document.querySelector(`.${zoomedContainerClassName}`);

    let scrollX = 0;
    let scrollY = 0;

    if (!container) {
      return;
    }

    if (
      container.clientHeight + container.scrollTop - cursorY <=
      INTRO_AUTO_SCROLL_THRESHOLD_BOTTOM
    ) {
      scrollY = container.scrollTop + INTRO_AUTO_SCROLL_AMOUNT;
    } else if (cursorY - container.scrollTop <= INTRO_AUTO_SCROLL_THRESHOLD_TOP) {
      scrollY = container.scrollTop - INTRO_AUTO_SCROLL_AMOUNT;
    } else {
      scrollY = container.scrollTop;
    }

    if (
      container.clientWidth + container.scrollLeft - cursorX <=
      INTRO_AUTO_SCROLL_THRESHOLD_BOTTOM / 2
    ) {
      scrollX = container.scrollLeft + INTRO_AUTO_SCROLL_AMOUNT / 2;
    } else if (cursorY - container.scrollLeft <= INTRO_AUTO_SCROLL_THRESHOLD_TOP / 2) {
      scrollX = container.scrollLeft - INTRO_AUTO_SCROLL_AMOUNT / 2;
    } else {
      scrollX = container.scrollLeft;
    }

    container.scroll(scrollX, scrollY);
  };

  onScrollParamsChanged = ({ scrollAmount, scrollThreshold }) => {
    this.gameScrollAmount = scrollAmount;
    this.gameScrollTreshold = scrollThreshold;
  };

  setDefaultActivitySize = (activityHtmlElement, initialBrowserZooom, cssAttributes) => {
    if (activityHtmlElement) {
      const elementStyle = window.getComputedStyle(activityHtmlElement);
      this.defaultActivitySize = {};
      cssAttributes.forEach(
        attribute =>
          (this.defaultActivitySize[attribute] = `calc(
            ${elementStyle.getPropertyValue(attribute)} * ${
            this.parentZoomFactor ? 1 : initialBrowserZooom
          }
            )`)
      );
    }
  };

  setActivitySize = activityHtmlElement => {
    if (activityHtmlElement && this.isZoomed()) {
      const footerHeight = this.hasNav ? '64px' : '0';
      let zoomedStyles = '';
      let parentStyles = {
        width: '',
        height: ''
      };

      Object.entries(this.defaultActivitySize).forEach(([key, value]) => {
        zoomedStyles += ` ${key}:calc(${value} * ${this.zoomFactor})!important;`;

        if (key === 'height') {
          parentStyles.height = ` ${key}:calc(${value} * ${this.zoomFactor} + ${footerHeight})!important;`;
        }
        if (key === 'width') {
          parentStyles.width = `${key}:max(99vw, calc(${value} * ${this.zoomFactor}))!important;`;
        }
      });

      activityHtmlElement.setAttribute('style', zoomedStyles);

      const appContainer = document.querySelector('#root');
      appContainer &&
        appContainer.setAttribute('style', zoomedStyles + parentStyles.height + parentStyles.width);

      const doubleLinesBg = document.querySelector(
        '.draw-activity-container .background.frameless > img'
      );
      doubleLinesBg &&
        this.zoomFactor > 1.75 &&
        doubleLinesBg.setAttribute('style', 'margin-left: 0');
    }
  };
}

export default ZoomScrollManager;
