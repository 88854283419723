import { showSpinner, hideSpinner } from 'store/actions/ui';
import { SET_MODULES } from 'store/types/login';
import Api from 'utils/api';

export const setModules = modules => ({ type: SET_MODULES, payload: modules });

const { REACT_APP_GSS_LOGIN_URL } = process.env;

export const requestModules = () => async dispatch => {
  dispatch(showSpinner());

  const { status, error, data: modules } = await Api.get('/modules', {
    params: { includeDisabled: true, include: 'submodules' }
  });

  if (status > 400 || error) {
    // session expired
    window.location.href = REACT_APP_GSS_LOGIN_URL;
    return;
  }

  if (status < 400 && modules && !error) {
    dispatch(setModules(modules));
    dispatch(hideSpinner());
  }
};
