import { CharacterCase } from 'constants/enums/characters';

export function resizeCanvas(canvasEl, containerEl) {
  const { height, width } = containerEl.getBoundingClientRect();

  canvasEl.setAttribute('height', height);
  canvasEl.setAttribute('width', width);
}

export function getScaleDegree(characterType, characterCase, character, scaleAlteration = 1) {
  if (character.length === 2) return 0.5 * scaleAlteration;

  if (characterCase === CharacterCase.LOWERCASE) return 0.75 * scaleAlteration;

  return scaleAlteration;
}
