import {
  SET_STUDENT_DATA,
  SET_STUDENT_POSITION,
  SET_STUDENT_NEXT_POSITION,
  SET_TELEMETRY_DATA,
  SET_STUDENT_TOLERANCE,
  RESET_PROGRESS
} from 'store/types/student';

import { TOLERANCES } from 'constants/enums/tolerances';

export const initialState = {
  id: '',
  firstName: '',
  lastName: '',
  username: '',
  gradeLevel: '',
  locale: 'en-US',
  classroom: {},
  currentPosition: [],
  nextPosition: null,
  characterOrder: '',
  freePlay: false,
  leftHandOption: false,
  rightToLeft: false,
  tolerance: TOLERANCES.RELAXED
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STUDENT_DATA:
      return {
        ...state,
        ...action.payload
      };
    case SET_TELEMETRY_DATA:
      return { ...state, ...action.payload };
    case RESET_PROGRESS:
      return {
        ...state,
        currentPosition: [],
        nextPosition: null
      };
    case SET_STUDENT_POSITION:
      return {
        ...state,
        currentPosition: action.payload,
        nextPosition: null
      };
    case SET_STUDENT_NEXT_POSITION:
      return {
        ...state,
        nextPosition: action.payload
      };
    case SET_STUDENT_TOLERANCE:
      return {
        ...state,
        tolerance: action.payload
      };
    default:
      return state;
  }
};
