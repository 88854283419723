import React from 'react';
import border from 'assets/svg/login/black-line.svg';
import logo from 'assets/svg/login/logo.svg';
import { getSessionId } from '@lwtears/lwt-common-frontend/lib/@common';
import { A11ySettingsPopover } from '@lwtears/lwt-common-frontend/lib/@a11y/A11ySettings';
import { applyA11ySettings } from '@lwtears/lwt-common-frontend/lib/@common/util/a11y-util';
import { getAudioVendor } from 'utils/accessibilityUtils';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const { REACT_APP_GSS_LOGIN_URL } = process.env;

const Header = () => {
  const goBack = () => {
    window.location = `${REACT_APP_GSS_LOGIN_URL}/app-selection?session=${getSessionId()}`;
  };

  return (
    <div className="header">
      <div className="header-content">
        <button
          className="wdt-button left go-back"
          onClick={goBack}
          aria-label={tA11y('goBackAppScreen')}
          id="backButtonHeader"
        >
          <span className="icon bw_arrow_back_header" />
        </button>
        <img className="center logo" src={logo} alt={tA11y('gssLogo')} />
        <div>
          <A11ySettingsPopover
            onSubmit={response => applyA11ySettings(response.data ?? response, getAudioVendor())}
          />
        </div>
      </div>
      <img className="border" src={border} alt={tA11y('border')} />
    </div>
  );
};

export default Header;
