import { CharacterType } from 'constants/enums/characters';

const _getAsset = path => {
  try {
    const asset = require(`../assets/photo/character-lists/plain-stickers${path}`);
    return asset.default;
  } catch (err) {
    return;
  }
};

export default function(characterType, characterCase, character) {
  if (characterType === CharacterType.NUMBER)
    return _getAsset(`/numbers/plain-sticker-${character}.png`);

  if (character.length === 2)
    return _getAsset(`/${characterType}/connection/plain-sticker-${character}.png`);

  return _getAsset(`/${characterType}/${characterCase}/plain-sticker-${character}.png`);
}
