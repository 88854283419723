export const getScreenReaderCardDetails = card => {
  const {
    status,
    levelsCompleted,
    levels,
    character: { characterCase, characterType, character }
  } = card;

  const characterDescription =
    characterType === 'number' ? '' : `${characterCase} ${characterType}`;
  return `${status} ${characterDescription} ${character} with ${levelsCompleted} of ${levels} stars`;
};
