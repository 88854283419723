import {
  API_ERROR,
  SHOW_SPINNER,
  HIDE_SPINNER,
  SET_APP_MODULE,
  SHOW_NAVIGATION,
  HIDE_NAVIGATION,
  HIDE_BACKBUTTON,
  SHOW_BACKBUTTON,
  TOGGLE_TTS,
  SET_ASSETS_LOADED,
  SET_ACTIVITY_TIMESTAMP,
  SET_LITE_VERSION,
  SHOW_VISUAL_CUE,
  HIDE_VISUAL_CUE,
  TOGGLE_MODAL,
  SET_CURRENT_CC,
  SET_ANIMATIONS_MODE,
  SET_ONGOING_ANIMATION,
  SET_PARENT_ZOOM_FACTOR,
  SET_SOUND_TYPE
} from 'store/types/ui';

import AssetLoader from 'utils/assetLoader';

import {
  numbersList,
  plainStickersNumbers,
  stickersNumbers,
  lowerCaseCharacterList,
  plainStickersLowerCase,
  stickersLowerCase,
  upperCaseCharacterList,
  plainStickersUpperCase,
  stickersUpperCase,
  commonImages
} from 'utils/commonAssets';

let TIMEOUT = null;

export const showSpinner = () => (dispatch, getState) => {
  const {
    ui: { loading }
  } = getState();
  clearTimeout(TIMEOUT);
  !loading && dispatch({ type: SHOW_SPINNER });
};

export const hideSpinner = () => dispatch => {
  TIMEOUT = setTimeout(() => dispatch({ type: HIDE_SPINNER }), 500);
};

export const showVisualCue = message => ({ type: SHOW_VISUAL_CUE, payload: message });
export const hideVisualCue = () => ({ type: HIDE_VISUAL_CUE });

export const setAppModule = data => ({ type: SET_APP_MODULE, payload: data });
export const logApiError = error => ({ type: API_ERROR, payload: error });

export const showNavigation = () => ({ type: SHOW_NAVIGATION });
export const hideNavigation = () => ({ type: HIDE_NAVIGATION });

export const setShowBackButton = () => ({ type: SHOW_BACKBUTTON });
export const setHideBackButton = () => ({ type: HIDE_BACKBUTTON });

export const toggleTextToSpeech = () => ({ type: TOGGLE_TTS });

export const setAssetsLoaded = assetsLoaded => ({ type: SET_ASSETS_LOADED, payload: assetsLoaded });

export const loadAssets = () => async (dispatch, getStore) => {
  const {
    ui: { module },
    login: { modules }
  } = getStore();

  dispatch(showSpinner());

  const assetLoader = new AssetLoader();

  if (!module) {
    // load common assets and module images.
    commonImages.forEach(key => assetLoader.queueImage(key));
    modules.forEach(module => assetLoader.queueImage(module.image));
  } else if (module === 'wdt-numbers') {
    numbersList.forEach(key => assetLoader.queueImage(key));
    plainStickersNumbers.forEach(key => assetLoader.queueImage(key));
    stickersNumbers.forEach(key => assetLoader.queueImage(key));
  } else if (module === 'wdt-capitals') {
    upperCaseCharacterList.forEach(key => assetLoader.queueImage(key));
    plainStickersUpperCase.forEach(key => assetLoader.queueImage(key));
    stickersUpperCase.forEach(key => assetLoader.queueImage(key));
  } else if (module === 'wdt-lowercase') {
    lowerCaseCharacterList.forEach(key => assetLoader.queueImage(key));
    plainStickersLowerCase.forEach(key => assetLoader.queueImage(key));
    stickersLowerCase.forEach(key => assetLoader.queueImage(key));
  }

  await assetLoader.downloadAll();

  if (module) dispatch(setAssetsLoaded(true));

  dispatch(hideSpinner());
};

export const setActivityTimestamp = () => ({
  type: SET_ACTIVITY_TIMESTAMP,
  payload: new Date().getTime()
});

export const setLiteVersion = isLite => ({
  type: SET_LITE_VERSION,
  payload: isLite
});

export const toggleModal = isModalOpen => ({ type: TOGGLE_MODAL, isModalOpen });

export const setCurrentCaption = caption => ({ type: SET_CURRENT_CC, payload: caption });

export const setAnimationsMode = (animationsModeOn, lineInstructionWasStopped) => ({
  type: SET_ANIMATIONS_MODE,
  payload: {
    animationsModeOn: animationsModeOn,
    lineInstructionWasStopped: lineInstructionWasStopped
  }
});

export const setSoundType = soundType => ({ type: SET_SOUND_TYPE, payload: soundType });

export const setOnGoingAnimation = onGoingAnimation => ({
  type: SET_ONGOING_ANIMATION,
  payload: onGoingAnimation
});

export const setParentZoomFactor = parentZoomFactor => ({
  type: SET_PARENT_ZOOM_FACTOR,
  parentZoomFactor
});
