class TimeoutList {
  constructor() {
    this.timeouts = [];
  }

  add(func, milliseconds) {
    this.timeouts.push(setTimeout(func, milliseconds));
  }

  cancelAll() {
    this.timeouts.forEach(function(timeout) {
      clearTimeout(timeout);
    });
    this.timeouts = [];
  }
}

export default TimeoutList;
