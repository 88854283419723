import React from 'react';
import { Route } from 'react-router-dom';
import RewardScreen from 'views/RewardScreen';
import ActivityContainer from 'views/ActivityContainer';
import Modules from 'views/ModuleSelection/Modules';
import StyleGuide from 'views/StyleGuide';

const routes = () => (
  <>
    <Route path="/reward-screen" component={RewardScreen}></Route>
    <Route path="/draw-character" component={ActivityContainer}></Route>
    <Route path="/modules" component={Modules} />
    <Route path="/styles" component={StyleGuide}></Route>
  </>
);

export default routes;
