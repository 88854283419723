import React from 'react';
import PropTypes from 'prop-types';

import playButton from 'assets/svg/images/play-button.svg';
import { eventKeyOnEnterOrSpace } from '../../utils/accessibilityUtils';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

export default function ButtonOverlay(props) {
  return (
    <div
      tabIndex={-1}
      className="button-overlay"
      onTouchEnd={props.onClick}
      onClick={props.onClick}
    >
      <div
        aria-label={tA11y('play')}
        role="button"
        tabIndex={0}
        onKeyDown={eventKeyOnEnterOrSpace(props.onClick)}
        onClick={props.onClick}
        className="play-button border-class"
        style={{
          display: 'flex',
          flex: 'inherit',
          flexDirection: 'column',
          height: 'auto',
          width: 140,
          backgroundColor: 'transparent'
        }}
      >
        <img src={playButton} alt={tA11y('play')} />
      </div>
    </div>
  );
}

ButtonOverlay.propTypes = {
  onClick: PropTypes.func.isRequired
};
