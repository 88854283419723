export const SET_STUDENT_DATA = 'SET_STUDENT';
export const SET_STUDENT_POSITION = 'SET_STUDENT_POSITION';
export const SET_STUDENT_NEXT_POSITION = 'SET_STUDENT_NEXT_POSITION';
export const SET_TELEMETRY_DATA = 'SET_TELEMETRY_DATA';
export const RESET_STUDENT = 'RESET_STUDENT';
export const RESET_PROGRESS = 'RESET_PROGRESS';
export const SET_STUDENT_TOLERANCE = 'SET_STUDENT_TOLERANCE';
export const SET_STUDENT_AUDIO_VOLUME = 'SET_STUDENT_AUDIO_VOLUME';
export const SET_STUDENT_AUDIO_MUTE = 'SET_STUDENT_AUDIO_MUTE';
export const SET_STUDENT_CC_STATUS = 'SET_STUDENT_CC_STATUS';
export const SET_STUDENT_CURRENT_CC = 'SET_STUDENT_CURRENT_CC';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
