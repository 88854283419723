import React from 'react';
import { Tween, Timeline } from 'react-gsap';
import PropTypes from 'prop-types';
import ErrorImage from 'assets/svg/images/Error.svg';
import SuccessImage from 'assets/svg/images/Success.svg';
import style from './SuccessErrorMessage.module.scss';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
// eslint-disable-next-line react/prop-types
const SuccessErrorMessage = ({ message }, ref) => {
  return (
    <div id="success-error-msg" className={style.container}>
      <Timeline
        target={
          <img
            ref={ref}
            id={message}
            alt={`${message} ${tA11y('image')}`}
            src={message === 'success' ? SuccessImage : ErrorImage}
          />
        }
      >
        <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.5} ease={'Back.easeOut'} />
      </Timeline>
    </div>
  );
};

export default React.forwardRef(SuccessErrorMessage);

SuccessErrorMessage.propTypes = {
  message: PropTypes.string,
  className: PropTypes.array
};
