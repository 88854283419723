import {
  SET_STUDENT_DATA,
  SET_STUDENT_POSITION,
  SET_STUDENT_NEXT_POSITION,
  RESET_STUDENT,
  SET_TELEMETRY_DATA,
  SET_STUDENT_TOLERANCE,
  RESET_PROGRESS,
  SET_STUDENT_AUDIO_VOLUME,
  SET_STUDENT_AUDIO_MUTE,
  SET_STUDENT_CC_STATUS
} from '../types/student';

import { showSpinner, hideSpinner } from './ui';
import Api from 'utils/api';
import { validateSession } from '@lwtears/lwt-common-frontend/lib/@common';

const { REACT_APP_GSS_LOGIN_URL } = process.env;

export const setStudentData = data => ({ type: SET_STUDENT_DATA, payload: data });
export const setStudentPosition = data => ({ type: SET_STUDENT_POSITION, payload: data });
export const setStudentNextPosition = data => ({ type: SET_STUDENT_NEXT_POSITION, payload: data });
export const setTelemetryData = data => ({ type: SET_TELEMETRY_DATA, payload: data });
export const resetStudentData = () => ({ type: RESET_STUDENT });
export const resetProgress = () => ({ type: RESET_PROGRESS });
export const setStudentTolerance = tolerance => ({
  type: SET_STUDENT_TOLERANCE,
  payload: tolerance
});

export const setStudentAudioVolume = data => ({ type: SET_STUDENT_AUDIO_VOLUME, payload: data });
export const setStudentAudioMute = data => ({ type: SET_STUDENT_AUDIO_MUTE, payload: data });
export const setStudentCCStatus = enabled => ({ type: SET_STUDENT_CC_STATUS, payload: enabled });

export const requestStudentSession = (sessionId, lwtStoreDispatch) => async dispatch => {
  try {
    dispatch(showSpinner());
    const res = await validateSession(sessionId);

    dispatch(setStudentData(res));
    lwtStoreDispatch.setSettings(res.settings);

    dispatch(hideSpinner());
  } catch (err) {
    window.location.href = REACT_APP_GSS_LOGIN_URL;
  }
};

export const getCurrentPosition = () => async (dispatch, getState) => {
  const {
    student: { currentPosition },
    ui: { module }
  } = getState();

  if (!module) return;

  dispatch(showSpinner());
  const { status, data } = await Api.get('/students/me/progress', { module });

  if (status < 400) {
    const { characterOrder, freePlay, groups } = data;
    dispatch(setTelemetryData({ characterOrder, freePlay }));
    if (!currentPosition || currentPosition.length === 0) {
      dispatch(setStudentPosition(groups));
    } else dispatch(setStudentNextPosition(groups));
    dispatch(hideSpinner());
  }
};

export const updateStudentPosition = () => (dispatch, getState) => {
  const {
    student: { nextPosition }
  } = getState();
  dispatch(setStudentPosition(nextPosition));
};
