import React, { Component } from 'react';
import './StyleGuide.scss';

export default class StyleGuide extends Component {
  render() {
    return (
      <div className={'style-guide'}>
        <h1>Header 1</h1>
        <h2>Header 2</h2>
        <h3>Header 3</h3>
        <h4>Header 4</h4>
        <p>Paragraph</p>
        <p className={'small'}>Small Paragraph</p>
        <a href={'#'}>Hyperlink</a>
        <p className={'error'}>This is an error text</p>
        <button className="wdt-button">Button Primary</button>
        <button className={'wdt-button secondary'}>Secondary Button</button>
        <input className={'wdt-input'} type={'text'} defaultValue={'This is a text input'} />
        <input
          className={'wdt-input'}
          type={'text'}
          disabled={true}
          defaultValue={'This is a disabled input'}
        />
        <input
          type={'text'}
          className={'wdt-input input-error'}
          defaultValue={'This is an error'}
        />
        <div className={'icons'}>
          {/* wrapper is just for grouping, not necessary */}
          <span className={'icon arrow_forward'} />
          <span className={'icon arrow_back'} />
          <span className={'icon accessibility'} />
          <span className={'icon minus'} />
          <span className={'icon plus'} />
          <span className={'icon record'} />
          <span className={'icon refresh'} />
          <span className={'icon volume'} />
          <span className={'icon volume_muted'} />
        </div>
        <div className={'bw-icons'}>
          {/* wrapper is just for grouping, not necessary */}
          <span className={'icon bw_arrow_forward'} />
          <span className={'icon bw_arrow_back'} />
          <span className={'icon bw_accessibility'} />
          <span className={'icon bw_minus'} />
          <span className={'icon bw_plus'} />
          <span className={'icon bw_record'} />
          <span className={'icon bw_refresh'} />
          <span className={'icon bw_volume'} />
          <span className={'icon bw_volume_muted'} />
        </div>
      </div>
    );
  }
}
