import { combineReducers } from 'redux';
import activityReducer from './activity';
import awardReducer from './award';
import errorLogger from './errorLogger';
import loginReducer from './login';
import studentReducer from './student';
import uiReducer from './ui';
import settingsReducer from './settings';

export const reducers = combineReducers({
  activity: activityReducer,
  award: awardReducer,
  errorLogger,
  login: loginReducer,
  student: studentReducer,
  ui: uiReducer,
  settings: settingsReducer
});
