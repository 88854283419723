import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router';
import { connect, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  A11ySettingsPopover,
  ExternalControl
} from '@lwtears/lwt-common-frontend/lib/@a11y/A11ySettings';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { useLwtStore } from '@lwtears/lwt-common-frontend/lib/LwtApp';
import AudioCaptions from 'components/AudioCaptions';
import { setAssetsLoaded } from 'store/actions/ui';
import { setAnimationsMode } from 'store/actions/ui';
import { applyA11ySettings } from '@lwtears/lwt-common-frontend/lib/@common/util/a11y-util';
import { getAudioVendor } from 'utils/accessibilityUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop, faPlay } from '@fortawesome/free-solid-svg-icons';

const Navigation = props => {
  const history = useHistory();
  const pathname = history.location.pathname;
  const [ariaLabel, setAriaLabel] = useState('');
  const [buttonPressed, setButtonPressed] = useState(false);
  const { animationsModeOn, onGoingAnimation, soundType } = useSelector(state => state.ui);

  const [state] = useLwtStore();
  const {
    settings: {
      accessibility: {
        displaySettings: { contentScaling }
      }
    }
  } = state;
  const dispatch = useDispatch();
  const { showBackButton, isLiteVersion, hasNavigation, accessibilityMode } = props;
  const maybeApplyLiteRouteMargin = isLiteVersion ? { marginRight: 20 } : { marginRight: 120 };

  useEffect(() => {
    switch (pathname) {
      case '/draw-character':
        setAriaLabel(tA11y('goBackLNScreen'));
        break;
      case '/reward-screen':
        setAriaLabel(tA11y('goBackModuleScreen'));
        break;
      default:
        break;
    }
  }, [pathname]);

  useEffect(() => {
    if (buttonPressed) {
      if (animationsModeOn) {
        onGoingAnimation.play();
      } else {
        onGoingAnimation.stop();
      }
      setButtonPressed(false);
    }
  }, [animationsModeOn, buttonPressed]);

  const goBack = () => {
    if (pathname === '/reward-screen') {
      setAssetsLoaded(false);
      sessionStorage.removeItem('moduleType');
      history.push('/modules');
    } else if (pathname === '/draw-character') {
      history.push('/reward-screen');
    } else return false;
  };

  const toggleAnimationsMode = () => {
    setButtonPressed(true);
    dispatch(setAnimationsMode(!animationsModeOn, soundType === 'charInstructions'));
  };

  return (
    hasNavigation && (
      <div id="navigation" className={`navigation zoom${contentScaling}`}>
        <div className={'backButtonDiv'} id="backButtonDiv">
          {showBackButton && !isLiteVersion && (
            <button
              className="wdt-button backButton"
              id="backButton"
              onClick={goBack}
              aria-label={ariaLabel}
            >
              <span className="icon bw_arrow_back" />
            </button>
          )}
        </div>
        {accessibilityMode && (
          <>
            <div id="ccDiv" className="ccDiv">
              <AudioCaptions />
            </div>
            <button
              disabled={!onGoingAnimation}
              onClick={toggleAnimationsMode}
              id="animationBtn"
              className="toggleAnimations"
              aria-label={tA11y('toggleAnimationsButton')}
              aria-pressed={!animationsModeOn}
            >
              <FontAwesomeIcon icon={animationsModeOn || !onGoingAnimation ? faStop : faPlay} />
            </button>
            <div style={maybeApplyLiteRouteMargin}>
              <A11ySettingsPopover
                externalControls={[ExternalControl.AUDIO_CONTROLS, ExternalControl.CC_BUTTON]}
                onSubmit={response =>
                  applyA11ySettings(response.data ?? response, getAudioVendor())
                }
              />
            </div>
          </>
        )}
      </div>
    )
  );
};

Navigation.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string
  }),
  history: PropTypes.object,
  hasNavigation: PropTypes.bool.isRequired,
  setAssetsLoaded: PropTypes.func,
  showBackButton: PropTypes.bool,
  isLiteVersion: PropTypes.bool,
  onGoingAnimation: PropTypes.object,
  animationsModeOn: PropTypes.bool,
  accessibilityMode: PropTypes.bool,
  soundType: PropTypes.string
};

const mapStateToProps = state => ({
  hasNavigation: state.ui.navigation.showNavigation,
  showBackButton: state.ui.navigation.showBackButton,
  isLiteVersion: state.ui.isLiteVersion,
  onGoingAnimation: state.ui.onGoingAnimation,
  animationsModeOn: state.ui.animationsModeOn,
  accessibilityMode: state.settings.accessibilityMode,
  soundType: state.ui.soundType
});

const mapActionsToProps = {
  setAssetsLoaded
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Navigation));
