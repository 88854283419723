import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

class DrawActivityIntro extends PureComponent {
  componentWillUnmount() {
    if (this.props.onLeave) {
      this.props.onLeave();
    }
  }

  renderStarSection = () => {
    const {
      props: { starAmount }
    } = this;

    const starsMarkup = [];

    for (let i = 0; i < 3; i++) {
      starsMarkup.push(
        starAmount > i + 1 ? (
          <div key={`star-${i}`}>
            <FontAwesomeIcon key={i} icon={fasStar} size={'3x'} />
          </div>
        ) : (
          <div key={`star-${i}`}>
            <FontAwesomeIcon key={i} icon={farStar} size={'3x'} />
          </div>
        )
      );
    }

    return starsMarkup;
  };

  render() {
    const { character, characterCase, characterType } = this.props;
    const altText =
      characterType === 'number'
        ? `${characterType} ${character} ${tA11y('sticker')}`
        : `${characterType} ${characterCase} ${character} ${tA11y('sticker')}`;

    return (
      <div className={`draw-activity-intro ${this.props.smallSticker ? 'small-sticker' : ''}`}>
        <div className={`img-container ${!this.props.isLiteVersion ? 'b-and-w ' : ''}`}>
          <img src={this.props.stickerPath} alt={altText} />
        </div>
        {!this.props.isLiteVersion && (
          <div className={'icon-container'}>{this.renderStarSection()}</div>
        )}
      </div>
    );
  }
}

DrawActivityIntro.propTypes = {
  starAmount: PropTypes.number.isRequired,
  stickerPath: PropTypes.string,
  isLiteVersion: PropTypes.bool,
  smallSticker: PropTypes.bool,
  onLeave: PropTypes.func,
  character: PropTypes.string,
  characterType: PropTypes.string,
  characterCase: PropTypes.string
};

export default DrawActivityIntro;
