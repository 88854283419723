export const API_ERROR = 'API_ERROR';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const SET_APP_MODULE = 'SET_APP_MODULE';
export const SHOW_NAVIGATION = 'SHOW_NAVIGATION';
export const HIDE_NAVIGATION = 'HIDE_NAVIGATION';
export const SHOW_BACKBUTTON = 'SHOW_BACKBUTTON';
export const HIDE_BACKBUTTON = 'HIDE_BACKBUTTON';
export const TOGGLE_TTS = 'TOGGLE_TTS';
export const SET_ASSETS_LOADED = 'SET_ASSETS_LOADED';
export const SET_ACTIVITY_TIMESTAMP = 'SET_ACTIVITY_TIMESTAMP';
export const SET_LITE_VERSION = 'SET_LITE_VERSION';
export const SHOW_VISUAL_CUE = 'SHOW_VISUAL_CUE';
export const HIDE_VISUAL_CUE = 'HIDE_VISUAL_CUE';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SET_CURRENT_CC = 'SET_CURRENT_CC';
export const SET_ANIMATIONS_MODE = 'SET_ANIMATIONS_MODE';
export const SET_ONGOING_ANIMATION = 'SET_ONGOING_ANIMATION';
export const SET_PARENT_ZOOM_FACTOR = 'SET_PARENT_ZOOM_FACTOR';
export const SET_SOUND_TYPE = 'SET_SOUND_TYPE';
