import store from 'store';
import { setCurrentCaption } from 'store/actions/ui';

let DISPATCH_INTERVAL = null;

const syncAudioCaption = (audio, captions) => {
  const getTimeStamp = () => {
    if (audio.playing()) {
      const timeStamp = audio.seek();
      displayCaption(timeStamp, captions);
      setTimeout(getTimeStamp, 100);
    }
  };

  getTimeStamp();
};

const initListeners = (audio, captions) => {
  const { captionsEnabled = true } = store.getState();

  if (!captionsEnabled || !captions) return;

  audio.on('play', () => syncAudioCaption(audio, captions));
};

const displayCaption = (audioTimeStamp, { cues } = {}) => {
  const timeStamp = audioTimeStamp.toFixed(1);
  const found = cues.find(({ start }) => formatTimeStamp(start) === timeStamp);

  if (found) {
    const { start, end, payload } = found;
    store.dispatch(setCurrentCaption(payload));

    clearInterval(DISPATCH_INTERVAL);

    DISPATCH_INTERVAL = setTimeout(() => {
      store.dispatch(setCurrentCaption(''));
    }, (formatTimeStamp(end) - formatTimeStamp(start)) * 1500);
  }
};

const formatTimeStamp = time => {
  const [h, m, s] = time.split(':');
  return parseFloat(h * 3600 + m * 60 + s * 1).toFixed(1);
};

const HowlWithCCs = (howl, captions) => {
  initListeners(howl, captions);
  return howl;
};

export default HowlWithCCs;
