import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import RewardCard from './RewardCard';
import { getCurrentPosition, updateStudentPosition } from 'store/actions/student';
import { getActivitySequence } from 'store/actions/activity';
import { loadAssets } from 'store/actions/ui';
import { hideAward, setCurrentCard } from 'store/actions/award';
import AwardStar from 'components/AwardStar';
import { audioManager } from 'utils/draw-activity/audio-manager';
import { SoundTypes } from 'constants/enums/sound-types';
import { getScreenReaderCardDetails } from 'utils/screen-reader-info';
import { withLwtStore } from '@lwtears/lwt-common-frontend/lib/LwtApp';

class RewardScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { currentPosition: null };
    this.starRef = null;
    this.timers = [];
  }

  async componentDidMount() {
    const {
      props: { haveAward, assetsLoaded }
    } = this;
    const {
      props: { getCurrentPosition, loadAssets }
    } = this;

    if (!assetsLoaded) {
      await loadAssets();
    }

    await getCurrentPosition();

    audioManager.loadAudioObjects([SoundTypes.PICK_AND_PRACTICE]);

    if (!haveAward) {
      this.timers.push(
        setTimeout(() => audioManager.playSound(SoundTypes.PICK_AND_PRACTICE), 1000)
      );
    }
  }

  componentWillUnmount() {
    this.timers.length && this.timers.forEach(id => clearTimeout(id));
    audioManager.pauseAllSounds();
    audioManager.unloadSounds([SoundTypes.PICK_AND_PRACTICE]);
  }

  onComplete = () => {
    const {
      props: { haveAward, hideAward, updateStudentPosition }
    } = this;
    if (haveAward) {
      updateStudentPosition();
      hideAward();

      audioManager.loadAudioObjects([SoundTypes.PICK_AND_PRACTICE]);
      this.timers.push(
        setTimeout(() => audioManager.playSound(SoundTypes.PICK_AND_PRACTICE), 1000)
      );
    }
  };

  handleClick = async character => {
    const {
      props: { getActivitySequence, setCurrentCard }
    } = this;
    setCurrentCard(character);
    audioManager.unloadAllSounds();

    const status = await getActivitySequence(character);

    if (status < 400) this.redirectToActivity();
  };

  redirectToActivity = () => {
    sessionStorage.setItem('moduleType', this.props.module);
    this.props.history.push('/draw-character');
  };

  renderCharacters = characters => {
    const {
      props: { haveAward, module, target }
    } = this;
    const content = [];

    if (haveAward) this.starRef = React.createRef();
    {
      characters.length > 0 &&
        characters.map(row => {
          content.push(
            <RewardCard
              char={row.character.character}
              module={module}
              key={row.character.character}
              starAmount={row.levelsCompleted}
              disabled={row.complete}
              onSelection={this.handleClick}
              passedRef={target === row.character.character ? this.starRef : undefined}
            />
          );
        });
    }
    return content;
  };

  render() {
    const {
      activityData: {
        level,
        character: { character },
        card
      },
      characterOrder,
      currentPosition,
      haveAward,
      module,
      target
    } = this.props;

    if (haveAward) this.starRef = React.createRef();

    const {
      accessibility: {
        displaySettings: { contentScaling }
      }
    } = this.props.lwtStoreState.settings;

    return (
      <>
        <div className={`reward-screen zoom${contentScaling} ${module} ${characterOrder}`}>
          {currentPosition.length > 0 &&
            currentPosition.map((row, idx) => {
              return (
                <div className={`row`} key={idx}>
                  {row.group && (
                    <div className={'col-100'}>
                      <h1 className="wdt-h1 section-header">{row.group}</h1>
                    </div>
                  )}
                  {row.characters.length > 0 &&
                    row.characters.map(row => (
                      <RewardCard
                        char={row.character.character}
                        module={module}
                        key={row.character.character}
                        starAmount={row.levelsCompleted}
                        complete={row.complete}
                        status={row.status}
                        onSelection={this.handleClick}
                        passedRef={target === row.character.character ? this.starRef : undefined}
                        ariaLabel={getScreenReaderCardDetails(row)}
                        contentScaling={contentScaling}
                      />
                    ))}
                </div>
              );
            })}
          {true === haveAward && this.starRef !== null && (
            <AwardStar
              character={character}
              level={level}
              module={module}
              onComplete={this.onComplete}
              target={this.starRef}
              card={card}
            />
          )}
        </div>
      </>
    );
  }
}

RewardScreen.propTypes = {
  lwtStoreState: PropTypes.any,
  activityData: PropTypes.shape({
    character: PropTypes.shape({
      id: PropTypes.number,
      character: PropTypes.string,
      characterCase: PropTypes.string,
      characterType: PropTypes.string
    }),
    sequenceActivityId: PropTypes.number,
    activityId: PropTypes.number,
    activityTemplateId: PropTypes.number,
    type: PropTypes.string,
    level: PropTypes.number,
    card: PropTypes.object
  }),
  characterOrder: PropTypes.string,
  currentPosition: PropTypes.arrayOf(
    PropTypes.shape({
      character: PropTypes.shape({
        character: PropTypes.string,
        id: PropTypes.number
      }),
      stars: PropTypes.number
    })
  ),
  getActivitySequence: PropTypes.func.isRequired,
  getCurrentPosition: PropTypes.func,
  haveAward: PropTypes.bool,
  hideAward: PropTypes.func,
  history: PropTypes.object,
  module: PropTypes.string,
  setCurrentCard: PropTypes.func,
  target: PropTypes.string,
  updateStudentPosition: PropTypes.func,
  hideForwardButton: PropTypes.func,
  loadAssets: PropTypes.func,
  touchANumber: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  touchALetter: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  assetsLoaded: PropTypes.bool.isRequired
};

export const mapStateToProps = state => ({
  activityData: state.activity.data,
  characterOrder: state.student.characterOrder,
  currentPosition: state.student.currentPosition,
  haveAward: state.award.showAward,
  module: state.ui.module,
  target: state.award.currentCard,
  assetsLoaded: state.ui.assetsLoaded
});

export default connect(mapStateToProps, {
  getCurrentPosition,
  getActivitySequence,
  hideAward,
  setCurrentCard,
  updateStudentPosition,
  loadAssets
})(withLwtStore(withRouter(RewardScreen)));
