import { SET_MODULES } from 'store/types/login';

const initLogin = {
  modules: []
};

export default (state = initLogin, action) => {
  switch (action.type) {
    case SET_MODULES:
      return { ...state, modules: action.payload };
    default:
      return state;
  }
};
