import { shuffle } from 'lodash';
import { getScaleDegree } from './canvas-manager';

class TextureManager {
  constructor() {
    this._textureDegrees = [15, 30, 45, 60, 75, 90, -15, -30, -45, -60, -75, -90];
    this.textureRaster = null;
    this.canvas = null;
  }

  initializeTexture(textureType, paperInstance, canvasEl) {
    this.canvas = canvasEl;
    const raster = new paperInstance.Raster(textureType);

    raster.opacity = 0;

    return new Promise(resolve => {
      raster.onLoad = () => {
        this.textureRaster = raster;
        resolve(raster);
      };
    });
  }

  drawDenseTexturePoint({ x, y, characterType, characterCase, character }) {
    for (let i = 0; i < 3; i++) {
      this.drawTexturePoint({ x, y, characterType, characterCase, character });
    }
  }

  drawTexturePoint({ x, y, characterType, characterCase, character }) {
    const raster = this.textureRaster.clone();
    const { width: canvasWidth } = this.canvas.getBoundingClientRect();
    const textureRadius = getScaleDegree(
      characterType,
      characterCase,
      character,
      (6.3 / 100) * canvasWidth
    );

    raster.setWidth(textureRadius);
    raster.setHeight(textureRadius);
    raster.rotate(shuffle(this._textureDegrees)[0]);
    raster.position = { x, y };
    raster.opacity = 1;
  }
}

export default new TextureManager();
