import a11yEn from './en-US/a11y.json';
import a11yEs from './es-US/a11y.json';

export const i18n = {
  resourceMap: {
    'en-US': {
      a11y: a11yEn
    },
    'es-US': {
      a11y: a11yEs
    }
  }
};
