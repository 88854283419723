import React, { Component } from 'react';
import PropTypes from 'prop-types';
import woodenFramePath from 'assets/photo/backgrounds/frame-wood-smiley.png';
import cornerlessWoodenFramePath from 'assets/photo/backgrounds/frame-wood-no-corners.png';
import backButtonWoodPath from 'assets/photo/tour/left-arrow-button-wood.png';
import restartButtonWoodPath from 'assets/photo/draw-stage/restart-button-wood.png';
import backButtonPath from 'assets/photo/draw-stage/back-button.png';
import restartButtonPath from 'assets/photo/draw-stage/restart-button.png';
import doubleLineBlackboardPath from 'assets/photo/backgrounds/double-line-blackboard.png';
import singleLineBlackboardLowPath from 'assets/photo/backgrounds/single-line-blackboard-low.jpg';
import singleLineBlackboardHighPath from 'assets/photo/backgrounds/single-line-blackboard-high.jpg';
import blackboardLandscape from 'assets/photo/backgrounds/blackboard-landscape.jpg';
import { eventKeyOnEnterOrSpace } from '../../utils/accessibilityUtils';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

class DrawActivityContainer extends Component {
  shouldComponentUpdate(nextProps) {
    return !(!nextProps.renderChildren && nextProps.displayBackground);
  }

  renderFrameBackground() {
    return (
      <div className="background">
        {this.props.frameHasCorners ? (
          <img src={woodenFramePath} alt={tA11y('woodenFrame')} />
        ) : (
          <img src={cornerlessWoodenFramePath} alt={tA11y('cornerlessWoodenFrame')} />
        )}
      </div>
    );
  }

  renderSingleLineBlackboard() {
    return this.props.highLine ? (
      <img src={singleLineBlackboardHighPath} alt={tA11y('singleLineBlackboardHigh')} />
    ) : (
      <img src={singleLineBlackboardLowPath} alt={tA11y('singleLineBlackboardLow')} />
    );
  }

  renderFramelessBackground() {
    return (
      <div
        className={`background frameless ${this.props.displayBackground ? 'visible' : ''}`}
        onTransitionEnd={this.props.onBackgroundVisible}
      >
        {this.props.singleLineBackground ? (
          this.renderSingleLineBlackboard()
        ) : this.props.landscapeBackground ? (
          <img src={blackboardLandscape} alt={tA11y('blackboardLandscape')} />
        ) : (
          <img src={doubleLineBlackboardPath} alt={tA11y('doubleLineBlackboard')} />
        )}
      </div>
    );
  }

  renderFramelessButtons() {
    return (
      <div className="button-container">
        <div
          aria-label={tA11y('back')}
          role="button"
          className={`back-button-container no-margin ${this.props.noBackButton ? 'hidden' : ''}`}
          onTouchEnd={this.props.onBack}
          onClick={this.props.onBack}
        >
          <img
            className={`back-button ${this.props.noBackButton ? 'hidden' : ''}`}
            src={backButtonPath}
            alt={tA11y('back')}
          />
        </div>
        {!this.props.noRestartButton && (
          <div
            aria-label={tA11y('restart')}
            role="button"
            onKeyDown={eventKeyOnEnterOrSpace(this.props.onRestart)}
            tabIndex={this.props.show ? 0 : -1}
            className={`restart-button-container border-class no-margin`}
            onTouchEnd={this.props.onRestart}
            onClick={this.props.onRestart}
          >
            <img className={`restart-button`} src={restartButtonPath} alt={tA11y('restart')} />
          </div>
        )}
      </div>
    );
  }

  renderFrameButtons() {
    return (
      <div className="button-container">
        <div
          aria-label={tA11y('back')}
          role="button"
          className={`back-button-container ${this.props.noBackButton ? 'hidden' : ''}`}
          onTouchEnd={this.props.onBack}
          onClick={this.props.onBack}
        >
          <img
            className={`back-button ${this.props.noBackButton ? 'hidden' : ''}`}
            src={backButtonWoodPath}
            alt={tA11y('back')}
          />
        </div>
        {!this.props.noRestartButton && (
          <div
            aria-label={tA11y('restart')}
            role="button"
            onKeyDown={eventKeyOnEnterOrSpace(this.props.onRestart)}
            tabIndex={this.props.show ? 0 : -1}
            className={`restart-button-container border-class`}
            onTouchEnd={this.props.onRestart}
            onClick={this.props.onRestart}
          >
            <img className={`restart-button`} src={restartButtonWoodPath} alt={tA11y('restart')} />
          </div>
        )}
      </div>
    );
  }

  renderBackground(hasFrame) {
    return hasFrame ? this.renderFrameBackground() : this.renderFramelessBackground();
  }

  renderButtons(hasFrame) {
    return hasFrame ? this.renderFrameButtons() : this.renderFramelessButtons();
  }

  render() {
    return (
      <div className="draw-activity-container">
        {this.renderBackground(this.props.hasFrame)}
        {this.renderButtons(this.props.hasFrame)}
        <div className="content-bounds">
          {this.props.renderChildren ? this.props.children : null}
        </div>
      </div>
    );
  }
}

DrawActivityContainer.propTypes = {
  children: PropTypes.node,
  noRestartButton: PropTypes.bool,
  noBackButton: PropTypes.bool,
  singleLineBackground: PropTypes.bool,
  landscapeBackground: PropTypes.bool,
  highLine: PropTypes.bool,
  hasFrame: PropTypes.bool,
  frameHasCorners: PropTypes.bool,
  renderChildren: PropTypes.bool,
  svgPath: PropTypes.string,
  onBack: PropTypes.func,
  onRestart: PropTypes.func,
  displayBackground: PropTypes.bool,
  onBackgroundVisible: PropTypes.func,
  show: PropTypes.bool
};

export default DrawActivityContainer;
