import store from 'store';
import { TOLERANCES, TOLERANCE_VALUES } from 'constants/enums/tolerances';

class ToleranceManager {
  constructor() {}
  calculateTolerance() {
    const {
      student: { tolerance = TOLERANCES.RELAXED }
    } = store.getState();

    const baseTolerance = 32;
    const finalTolerance = baseTolerance * TOLERANCE_VALUES[tolerance];
    /* eslint-disable-next-line no-console */
    console.log({ tolerance, finalTolerance });

    return finalTolerance;
  }
}

export default new ToleranceManager();
