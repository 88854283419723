import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

function Transition(props) {
  return (
    <div className={`fade-container ${props.className ? props.className : ''}`}>
      <ReactCSSTransitionGroup
        transitionName={props.name}
        transitionAppear={true}
        transitionEnter={false}
        transitionAppearTimeout={props.startDuration}
        transitionLeaveTimeout={props.endDuration}
      >
        {props.children}
      </ReactCSSTransitionGroup>
    </div>
  );
}

Transition.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  startDuration: PropTypes.number,
  endDuration: PropTypes.number,
  className: PropTypes.string
};

export default Transition;
