import React from 'react';
import LwtApp from '@lwtears/lwt-common-frontend/lib/LwtApp';
import SettingsApi from '@lwtears/lwt-common-frontend/lib/@api/settings-api';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './store';
import Main from 'views/App';
import { i18n } from './translations';

const config = {
  api: { saveSettings: SettingsApi.saveStudentSettings },
  i18n
};

const App = () => (
  <LwtApp {...config}>
    <Provider store={store}>
      <Router>
        <Main />
      </Router>
    </Provider>
  </LwtApp>
);

export default App;
