import { Howl } from 'howler';
import * as Promise from 'bluebird';
import { get } from 'axios';
import store from 'store';
import { setOnGoingAnimation, setAnimationsMode, setSoundType } from 'store/actions/ui';

import getRandomSound from '../../utils/get-random-sound.js';
import { SoundTypes } from '../../constants/enums/sound-types';
import HowlerWithCCs from '../captionsHelper';

const ERR_HOWL = (src, msg) => ({
  /* eslint-disable no-console */
  play: () => console.warn(`There was an error loading the sound ${src}, unable to play it.`),
  stop: () => console.warn(`There was an error loading the sound ${src}, unable to stop it.`),
  pause: () => console.warn(`There was an error loading the sound ${src}, unable to pause it.`),
  mute: () => console.warn(`There was an error loading the sound ${src}, ${msg}`),
  on: () => {}
  /* eslint-enable no-console */
});

Promise.config({ cancellation: true });

class AudioManager {
  constructor() {
    //set audio options
    this.sounds = {};
    this.soundPaths = {};
    this.previousSound = {};
    this.pointerInProgress = false;
    this.muteMode = false;
    this.isWebApplication = false;
    this.audioEnabled = {
      background: true,
      instructions: true,
      effects: true,
      charInstructions: true,
      generalInstructions: true
    };

    this.instructionsInProgress = false;

    this.timeoutList = [];

    this.downloadList = null;
    this.genericGameSoundsLoaded = false;
  }

  resetPointerFlag() {
    this.pointerInProgress = false;
  }

  toggleSounds() {
    if (this.muteMode) {
      this.muteMode = false;
      this.audioEnabled = {
        background: false,
        instructions: false,
        effects: false,
        charInstructions: false,
        generalInstructions: false
      };
    } else {
      this.muteMode = true;
      this.audioEnabled = {
        background: true,
        instructions: true,
        effects: true,
        charInstructions: true,
        generalInstructions: true
      };
    }
  }

  updateAudioSettings({ backgroundMusic = true, soundEffects = true, voiceInstruction = true }) {
    this.audioEnabled.background = backgroundMusic;
    this.audioEnabled.effects = soundEffects;
    this.audioEnabled.instructions = voiceInstruction;
    this.audioEnabled.charInstructions = voiceInstruction;
    this.audioEnabled.generalInstructions = voiceInstruction;
  }

  pauseAllSounds() {
    Object.keys(this.sounds).forEach(key => {
      if (typeof this.sounds[key] === 'object') {
        let audio = this.sounds[key];
        audio.pause();
        if (!isNaN(audio.duration)) {
          audio.currentTime = 0;
        }
      }
    });
    this.instructionsInProgress = false;
    store.dispatch(setOnGoingAnimation(null));
  }

  muteAllSounds() {
    this.muteMode = !this.muteMode;
    Object.keys(this.sounds).forEach(key => {
      if (typeof this.sounds[key] === 'object') {
        this.setMuteModeForSound(key);
        this.setMuteModeForSound(key);
      }
    });

    if (this.muteMode === false) {
      this.instructionsInProgress = false;
      this.pointerInProgress = false;
    }
  }

  setMuteModeForSound(key) {
    if (this.sounds[key]) {
      this.sounds[key].mute(this.muteMode);
    }
  }

  restartSound(soundType) {
    let audio = this.sounds[soundType.name];

    if (audio && !isNaN(audio.duration)) {
      audio.currentTime = 0;
    }
  }

  resetSounds() {
    //foreach key, besides generalSounds, clear values
    this.clearDownloadTimeout();

    if (!this.audioEnabled.background) {
      this.pauseSound(SoundTypes.BACKGROUND);
    }

    Object.keys(this.sounds).forEach(key => {
      if (typeof this.sounds[key] === 'object' && 'once' in this.sounds[key]) {
        this.sounds[key].stop();
        this.sounds[key].off();
        if (this.sounds[key].state() !== 'unloaded') this.sounds[key].unload();
      }
      delete this.sounds[key];
    });

    this.instructionsInProgress = false;
    this.genericGameSoundsLoaded = false;
  }

  unloadSounds(soundTypes) {
    this.clearDownloadTimeout();
    if (!this.audioEnabled.background) {
      this.pauseSound(SoundTypes.BACKGROUND);
    }

    if (soundTypes.length) {
      soundTypes.forEach(el => {
        if (el.name) {
          if (this.sounds[el.name]) {
            if (typeof this.sounds[el.name] === 'object' && 'once' in this.sounds[el.name]) {
              this.sounds[el.name].stop();
              this.sounds[el.name].off();
              if (this.sounds[el.name].state() !== 'unloaded') this.sounds[el.name].unload();
            }
            delete this.sounds[el.name];
          }
        }
      });
    }
    store.dispatch(setOnGoingAnimation(null));
    store.dispatch(setAnimationsMode(true, false));
  }

  unloadAllSounds() {
    this.clearDownloadTimeout();
    if (!this.audioEnabled.background) {
      this.pauseSound(SoundTypes.BACKGROUND);
    }

    Object.keys(this.sounds).forEach(sound => {
      if (typeof sound === 'object' && 'once' in sound) {
        sound.stop();
        sound.off();
        if (sound.state() !== 'unloaded') sound.unload();
      }
    });

    this.sounds = {};
    store.dispatch(setOnGoingAnimation(null));
    store.dispatch(setAnimationsMode(true, false));
  }

  isEmptyAudio(soundType) {
    return !(this.sounds[soundType.name] && this.sounds[soundType.name]._src);
  }

  playSound(soundType, callback, refreshSound, wasPaused = false) {
    if (soundType && soundType.category && soundType.category !== 'effects') {
      store.dispatch(setSoundType(soundType.category));
    }
    if (soundType && this.sounds[soundType.name]) {
      this.sounds[soundType.name].muted = false;
      this.setMuteModeForSound(soundType.name);
      if (this.muteMode === true) {
        this.instructionsInProgress = false;
      }
    }
    if (this.instructionsInProgress && !wasPaused) {
      return;
    }

    // if sound wasn't defined or found, skip to callback
    if (!soundType || this.isEmptyAudio(soundType)) {
      if (callback) {
        callback({ emptyAudio: true });
      }

      return;
    }
    if (['chalk', 'sponge', 'paper'].includes(soundType.name) && this.pointerInProgress) {
      return;
    }
    if (['chalk', 'sponge', 'paper'].includes(soundType.name)) {
      this.pointerInProgress = true;
    }

    // else, if soundType was defined, check if sound category is enabled from settings
    let soundCategory = soundType.category;

    // if sound category is disabled from settings, skip to callback
    if (!this.audioEnabled[soundCategory]) {
      if (callback) {
        callback();
      }

      return;
    }

    if (soundType.category === 'instructions' && !this.isEmptyAudio(soundType)) {
      this.inProgressFile = soundType;
      this.instructionsInProgress = true;
    }

    if (refreshSound) {
      this.sounds[soundType.name].once('end', () => {
        this.sounds[soundType.name].unload();
        this.getRandomSound(soundType).then(audioEl => {
          this.sounds[soundType.name] = audioEl;
          this.setMuteModeForSound(soundType.name);
        });
      });
    }

    this.sounds[soundType.name].once('end', () => {
      store.dispatch(setOnGoingAnimation(null));
      if (soundType.category === 'instructions') {
        this.instructionsInProgress = false;
      } else if (['chalk', 'sponge', 'paper'].includes(soundType.name)) {
        this.pointerInProgress = false;
      }

      if (callback) {
        callback();
      }
    });

    this.sounds[soundType.name].once('playerror', () => {
      if (soundType.category === 'instructions') {
        this.instructionsInProgress = false;
      }
      if (callback) {
        callback();
      }
    });

    if (soundCategory && soundCategory === 'effects') {
      // store.dispatch(setOnGoingAnimation(null));
    } else {
      store.dispatch(setOnGoingAnimation(this.sounds[soundType.name]));
    }
    this.sounds[soundType.name].play();
  }

  isPlaying(soundType) {
    return this.sounds[soundType.name] && this.sounds[soundType.name].playing();
  }

  pauseSound(soundType) {
    if (this.sounds[soundType.name]) {
      this.sounds[soundType.name].pause();
      if (['chalk', 'sponge', 'paper'].includes(soundType.name)) {
        this.pointerInProgress = false;
      }
    }
  }

  stopInstructions() {
    this.instructionsInProgress = false;
  }

  loadAudioObjects(soundTypeList) {
    soundTypeList.forEach(soundType => {
      let soundCategory = soundType.category;

      //remove old sound if it will be replaced
      if (typeof this.sounds[soundType.name] === 'object') {
        this.sounds[soundType.name].pause();
      }
      delete this.sounds[soundType.name];
      //get anouther random sound
      if (this.audioEnabled[soundCategory]) {
        this.getRandomSound(soundType).then(audioEl => {
          this.sounds[soundType.name] = audioEl;
        });
      } else {
        this.sounds[soundType.name] = ERR_HOWL(soundType.name);
      }
    });
  }

  getRandomSound(soundType) {
    let randSnd = getRandomSound(soundType);

    return new Promise(resolve => {
      this.getAudioAsset(randSnd).then(
        audioEl => {
          resolve(audioEl);
        },
        () => {
          resolve(ERR_HOWL(randSnd.src));
        }
      );
    });
  }

  loadActivitySpecificSounds = async data => {
    const drawSounds = [
      SoundTypes.CHALK,
      SoundTypes.SPONGE,
      SoundTypes.PAPER,
      SoundTypes.DING_WRONG,
      SoundTypes.DING_CORRECT,
      SoundTypes.DING_CONTINUE,
      SoundTypes.DING_PICKUP,
      SoundTypes.SUCCESS,
      SoundTypes.FINGER_DOWN,
      SoundTypes.OUT_OF_BOUNDS,
      SoundTypes.REVERSAL,
      SoundTypes.NO_CLUES,
      SoundTypes.REPLAY_DEMO
    ];

    const {
      segments,
      instructions: { afterActivity, beforeActivity, beforeRound } //eslint-disable-line
    } = data;

    const filesToLoad = {};

    if (!this.genericGameSoundsLoaded) {
      drawSounds.forEach(soundType => {
        const snd = getRandomSound(soundType);
        filesToLoad[soundType.name] = snd;
      });
    }

    segments && segments.forEach(segment => (filesToLoad[segment.id] = segment.audio));

    afterActivity &&
      Object.keys(afterActivity).forEach(key => (filesToLoad[key] = afterActivity[key]));

    beforeActivity &&
      Object.keys(beforeActivity).forEach(key => (filesToLoad[key] = beforeActivity[key]));

    beforeRound && Object.keys(beforeRound).forEach(key => (filesToLoad[key] = beforeRound[key]));

    return this.fetchAudioList(filesToLoad);
  };

  applyObjectKeys(destination, source) {
    Object.keys(source).forEach(key => {
      destination[key] = source[key];
    });
  }

  clearDownloadTimeout() {
    this.timeoutList.forEach(downloadTimeout => {
      downloadTimeout.resolved = true;
      clearTimeout(downloadTimeout.timeoutId);
    });
    //[TODO] -  clear list from time to time
    //this.timeoutList = [];
  }

  getAudioAsset(soundObject, options = {}) {
    const { src, captions } = soundObject;

    return new Promise((resolve, reject, onCancel) => {
      const loadAudio = new Promise(resolve => {
        const audio = new Howl({
          src,
          html5: false,
          ...options,
          onload: () => resolve(audio),
          onloaderror: (e, msg) => {
            resolve(ERR_HOWL(src, msg));
          }
        });
        onCancel(() => audio && audio.unload());
      });

      return Promise.all([loadAudio, this.loadCaptionObject(captions)]).then(([howl, caps]) =>
        resolve(HowlerWithCCs(howl, caps))
      );
    });
  }

  loadCaptionObject = caption =>
    new Promise(resolve => {
      if (typeof caption === 'object') {
        resolve(caption);
      } else {
        get(caption)
          .then(response => resolve(response.data))
          .catch(err => {
            console.log(err); //eslint-disable-line no-console
            resolve({ cues: [] });
          });
      }
    });

  fetchAudioList(audioPaths, options) {
    let downloadErrorLog = [];
    let downloadSuccessLog = [];

    const loadAudio = (key, src) =>
      new Promise((resolve, reject, onCancel) => {
        let audio = null;

        if (src === '') {
          this.sounds[key] = ERR_HOWL(src);
          resolve(ERR_HOWL(src));
        } else {
          try {
            audio = new Howl({
              src,
              html5: false,
              // ...defaultOptions,
              ...options,
              onload: () => {
                downloadSuccessLog.push({ key });

                resolve(audio);
              },
              onloaderror: (error, msg) => {
                downloadErrorLog.push({
                  key,
                  error,
                  msg
                });
                this.sounds[key] = ERR_HOWL(src);
                resolve(ERR_HOWL(src));
              }
            });
          } catch (err) {
            this.sounds[key] = ERR_HOWL(src);
            resolve(ERR_HOWL(src));
          }
        }

        onCancel(() => audio && audio.unload());
      });

    this.downloadList = Object.keys(audioPaths).map(async key => {
      const { src = '', captions = '' } = audioPaths[key] || {};

      return new Promise(resolve => {
        Promise.all([loadAudio(key, src), this.loadCaptionObject(captions)]).then(
          ([howl, caps]) => {
            this.sounds[key] = HowlerWithCCs(howl, caps);
            resolve();
          }
        );
      });
    });

    return new Promise(resolve => {
      Promise.all(this.downloadList)
        .then(() => {
          this.downloadList = [];
          resolve();
        })
        .catch(err => {
          console.log(err); // eslint-disable-line no-console
          resolve();
        });
    });
  }

  cancelDownloads() {
    if (!this.downloadList || this.downloadList.length === 0) return;

    this.downloadList.forEach(el => {
      if (el instanceof Promise) el.cancel();
    });

    this.downloadList = [];
  }
}

export const audioManager = new AudioManager();
