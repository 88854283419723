import { Howler } from 'howler';

const getAudioVendor = () => Howler;

const handleSettingChangeSubscription = (setting, newValue) => {
  switch (setting) {
    case 'muteSounds': {
      Howler.mute(newValue);
      break;
    }
    case 'volume': {
      Howler.volume(newValue / 100);
      break;
    }
  }
};

const eventKeyOnEnterOrSpace = cb => e => {
  if (e.key === 'Enter' || e.keyCode == 32) cb();
};

export { getAudioVendor, handleSettingChangeSubscription, eventKeyOnEnterOrSpace };
