import {
  SET_APP_MODULE,
  SHOW_SPINNER,
  HIDE_SPINNER,
  SHOW_NAVIGATION,
  HIDE_NAVIGATION,
  TOGGLE_TTS,
  HIDE_BACKBUTTON,
  SHOW_BACKBUTTON,
  SET_ASSETS_LOADED,
  SET_ACTIVITY_TIMESTAMP,
  SET_LITE_VERSION,
  SHOW_VISUAL_CUE,
  HIDE_VISUAL_CUE,
  TOGGLE_MODAL,
  SET_CURRENT_CC,
  SET_ANIMATIONS_MODE,
  SET_ONGOING_ANIMATION,
  SET_PARENT_ZOOM_FACTOR,
  SET_SOUND_TYPE
} from '../types/ui';

export const initialState = {
  loading: false,
  apiError: null,
  isMobile: false,
  appMode: 'curriculum',
  module: null,
  textToSpeech: false,
  assetsLoaded: false,
  activityTimestamp: 0,
  isLiteVersion: false,
  parentZoomFactor: null,
  isModalOpen: false,
  navigation: {
    showNavigation: false,
    showForwardBtn: false,
    showRestartBtn: false,
    showBackButton: true
  },
  successErrorMessage: {
    show: false,
    message: ''
  },
  captions: {
    currentCaption: ''
  },
  animationsModeOn: true,
  onGoingAnimation: null,
  lineInstructionWasStopped: false,
  soundType: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_MODULE:
      return { ...state, module: action.payload };
    case SHOW_SPINNER:
      return { ...state, loading: true };
    case HIDE_SPINNER:
      return { ...state, loading: false };
    case SHOW_NAVIGATION:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          showNavigation: true
        }
      };
    case SHOW_BACKBUTTON:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          showBackButton: true
        }
      };
    case HIDE_BACKBUTTON:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          showBackButton: false
        }
      };
    case HIDE_NAVIGATION:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          showNavigation: false
        }
      };
    case TOGGLE_TTS:
      return {
        ...state,
        textToSpeech: !state.textToSpeech
      };

    case SET_ANIMATIONS_MODE:
      return {
        ...state,
        animationsModeOn: action.payload.animationsModeOn,
        lineInstructionWasStopped: action.payload.lineInstructionWasStopped
      };

    case SET_SOUND_TYPE:
      return {
        ...state,
        soundType: action.payload
      };

    case SET_ONGOING_ANIMATION:
      return {
        ...state,
        onGoingAnimation: action.payload
      };

    case SET_ASSETS_LOADED:
      return {
        ...state,
        assetsLoaded: action.payload
      };

    case SET_ACTIVITY_TIMESTAMP:
      return { ...state, activityTimestamp: action.payload };

    case SET_LITE_VERSION:
      return { ...state, isLiteVersion: action.payload };
    case SHOW_VISUAL_CUE:
      return {
        ...state,
        successErrorMessage: {
          ...state.successErrorMessage,
          show: true,
          message: action.payload
        }
      };

    case HIDE_VISUAL_CUE:
      return {
        ...state,
        successErrorMessage: {
          ...state.successErrorMessage,
          show: false,
          message: ''
        }
      };
    case TOGGLE_MODAL:
      return { ...state, isModalOpen: action.isModalOpen };

    case SET_CURRENT_CC:
      return {
        ...state,
        captions: { currentCaption: action.payload }
      };

    case SET_PARENT_ZOOM_FACTOR:
      return { ...state, parentZoomFactor: action.parentZoomFactor };

    default:
      return state;
  }
};
