import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DrawActivityPage from './draw-activity-page';
import { showSpinner, hideSpinner } from 'store/actions/ui';
import { getClientInfo } from 'utils/get-client-info';

class ActivityContainer extends Component {
  constructor(props) {
    super(props);
    this.timers = [];
  }

  orientationView = '';
  browserName = getClientInfo().browser.name;

  componentDidMount() {
    if (this.browserName === 'Safari') {
      window.addEventListener('orientationchange', this.resetScreenOrientation);
    } else {
      this.setScreenOrientation();
      window.addEventListener('resize', this.checkScreenOrientation);
    }
  }

  componentWillUnmount() {
    this.clearTimers();
    if (this.browserName === 'Safari') {
      window.removeEventListener('orientationchange', this.resetScreenOrientation);
    } else {
      window.removeEventListener('resize', this.checkScreenOrientation);
    }
  }

  clearTimers = () => {
    this.timers.length && this.timers.forEach(id => clearTimeout(id));
  };

  setScreenOrientation = () => {
    this.orientationView = screen.width > screen.height ? 'landscape' : 'portrait';
  };

  checkScreenOrientation = () => {
    if (
      (screen.width > screen.height && this.orientationView === 'portrait') ||
      (screen.width < screen.height && this.orientationView === 'landscape')
    ) {
      this.setScreenOrientation();
      this.resetScreenOrientation();
    }
  };

  resetScreenOrientation = () => {
    const {
      props: { showSpinner, hideSpinner }
    } = this;

    this.clearTimers();

    showSpinner();
    this.timers.push(setTimeout(() => hideSpinner(), 1000));
  };

  render() {
    const {
      props: { isLoading }
    } = this;

    if (!isLoading) return <DrawActivityPage />;
    return <></>;
  }
}

ActivityContainer.propTypes = {
  isLoading: PropTypes.bool,
  showSpinner: PropTypes.func,
  hideSpinner: PropTypes.func
};

const mapStateToProps = state => ({
  isLoading: state.ui.loading
});

const mapActionsToProps = {
  showSpinner,
  hideSpinner
};

export default connect(mapStateToProps, mapActionsToProps)(ActivityContainer);
