import React from 'react';
import PropTypes from 'prop-types';

function KWTModal(props) {
  return (
    <div className={`kwt-modal ${props.whiteBg ? 'white-bg' : ''} ${props.show ? '' : 'hidden'}`}>
      {props.children}
    </div>
  );
}

KWTModal.propTypes = {
  show: PropTypes.bool,
  whiteBg: PropTypes.bool,
  children: PropTypes.element
};

export default KWTModal;
