import LwtApi, { AuthStrategy } from '@lwtears/lwt-common-frontend/lib/@common/util/api-util';
import { getSessionId, Header } from '@lwtears/lwt-common-frontend/lib/@common';
import store from 'store';
import { setActivityTimestamp } from 'store/actions/ui';

export const getEventContext = module => {
  if (!module) return null;

  const {
    activity: {
      data: {
        activityId,
        activityTemplateId,
        character: { character, characterCase, characterType } = {},
        level,
        sequenceId,
        sequenceActivityId,
        type: activityType
      }
    },
    student: { classroom, characterOrder, freePlay }
  } = store.getState();

  return {
    activityId,
    activityTemplateId,
    activityType,
    character: { character, characterCase, characterType },
    characterOrder,
    classroomId: classroom.id,
    freePlay,
    level,
    module,
    sequenceId,
    sequenceActivityId,
    sessionId: getSessionId()
  };
};

const onRequestDone = () => {
  if (getSessionId()) store.dispatch(setActivityTimestamp());
};

const addHeaders = () => ({ [Header.LOCALE]: 'en-US' });

const addErrorLogMetadata = props => {
  const { context: ctx } = props;
  const context = getEventContext(ctx && ctx.module);

  return { ...(context && { context }) };
};

const sanitizeUrl = ({ url, module, ...options }) => {
  const sanitizedUrl = module ? `/${module}${url}` : url;

  return { url: sanitizedUrl, ...options };
};

const setContext = options => ({ ...(options.module && { module: options.module }) });

export const createEvent = (type, { module, ...data }) =>
  Api.createTelemetryEvent({ payload: { type, data: { ...data, ...getEventContext(module) } } });

const Api = LwtApi()
  .addDefaultAuthStrategy(AuthStrategy.SESSION)
  .addRequestContext(setContext)
  .addRequestMiddleware(sanitizeUrl)
  .addHeaders(addHeaders)
  .addErrorLogMetadata(addErrorLogMetadata)
  .onDone(onRequestDone);

export default Api;
