class DistanceManager {
  constructor(formerX, formerY, distanceThreshold = 5) {
    this.formerCoordinates = {
      x: formerX,
      y: formerY
    };

    this.currentCoordinates = {
      x: formerX,
      y: formerY
    };

    this.distanceThreshold = distanceThreshold;
  }

  getCoordinateDifference(x1, y1, x2, y2) {
    return {
      diffX: x1 - x2 < 0 ? -(x1 - x2) : x1 - x2,
      diffY: y1 - y2 < 0 ? -(y1 - y2) : y1 - y2
    };
  }

  thresholdMet(x1, y1, x2, y2, threshold) {
    const diff = this.getCoordinateDifference(x1, y1, x2, y2);

    return diff.diffX > threshold || diff.diffY > threshold;
  }

  setFormerCoordinates(x, y) {
    this.formerCoordinates.x = x;
    this.formerCoordinates.y = y;
  }

  checkThreshold(x, y) {
    let thresholdMet = false;

    if (
      this.thresholdMet(
        x,
        y,
        this.formerCoordinates.x,
        this.formerCoordinates.y,
        this.distanceThreshold
      )
    ) {
      this.setFormerCoordinates(x, y);
      thresholdMet = true;
    }

    this.currentCoordinates.x = x;
    this.currentCoordinates.y = y;

    return thresholdMet;
  }
}

export default DistanceManager;
