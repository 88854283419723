import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tween, Timeline } from 'react-gsap';
import oneStar from 'assets/icons/one-star.png';
import chalk from 'assets/photo/backgrounds/chalk_background.png';
import { SoundTypes } from 'constants/enums/sound-types.js';
import { audioManager } from 'utils/draw-activity/audio-manager';
import AudioCaptions from 'components/AudioCaptions';

export class AwardStar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      background: `url(${chalk}) center top / cover no-repeat`,
      x: 0,
      y: 0,
      ready: false
    };

    this.congratulation =
      props.level === 1
        ? SoundTypes.EARNED_A_STAR
        : props.level === 2
        ? SoundTypes.EARNED_ANOTHER_STAR
        : SoundTypes.EARNED_CARD;

    this.stickerPath = null;

    this.timers = [];
    this.timeline = null;
  }

  async componentDidMount() {
    const {
      props: { level, target }
    } = this;

    let [x, y] = [0, 0];
    const { left, top } = this.selfRef.getBoundingClientRect();
    const offset = level === 3 ? 0 : 44; // for levels 1 & 2 star starts at 100px but needs to be scaled down to 12px hence 44px offset

    if (target && target.current && 'getBoundingClientRect' in target.current) {
      let star = target.current.getBoundingClientRect();
      [x, y] = [star.left - left - offset, star.top - top - offset];
    }

    this.startSounds();

    this.setState({ x, y, ready: true });
  }

  componentWillUnmount() {
    this.timers.length && this.timers.forEach(id => clearTimeout(id));
    audioManager.resetSounds();
  }

  startSounds = () => {
    const {
      props: { level }
    } = this;

    this.timers.push(
      setTimeout(
        () => {
          audioManager.playSound(this.congratulation, () => {
            if (level < 3) return;
            audioManager.playSound(SoundTypes.CARD_DESCRIPTION, () => this.timelineRef.resume());
          });
        },
        3 === level ? 500 : 2000
      )
    );
  };

  handleBreak = () => {
    if (audioManager.isPlaying(this.congratulation) && this.props.level === 3) {
      this.timelineRef.pause();
    }
  };

  makeSpiralCoords = () => {
    let radius = 300;
    const step = 100;
    const coords = [];
    while (radius >= 0) {
      if (0 === radius) {
        coords.push({ x: 0, y: 0 });
      } else {
        coords.push(
          { x: radius, y: 0 },
          { x: 0, y: radius },
          { x: -radius, y: 0 },
          { x: 0, y: -radius }
        );
      }

      radius -= step;
    }
    return coords;
  };

  render() {
    const {
      props: { onComplete, card },
      state: { background, ready, x, y }
    } = this;

    const imagePath = card ? card.src : oneStar;
    const finalScale = card ? 1 : 0.2;
    const awardClass = card ? 'award-star reward-card' : 'award-star';
    const imgAltText = card ? card.description : 'reward star';

    return (
      <div className={'award-overlay'} style={{ background }}>
        <div className={'award-star-screen'}>
          <div className={awardClass} ref={ref => (this.selfRef = ref)}>
            {x !== 0 && y !== 0 && ready ? (
              <Timeline
                target={<img src={imagePath} alt={imgAltText} />}
                onComplete={onComplete}
                ref={ref => (this.timeline = ref)}
              >
                <Tween
                  duration={0.1}
                  onComplete={() => {
                    this.timelineRef = this.timeline.getGSAP();
                  }}
                />
                <Tween
                  to={{ scale: 4 }}
                  bezier={{ curviness: 2, values: this.makeSpiralCoords() }}
                  duration={5}
                  ease={'Power0.ease'}
                />
                <Tween to={{ scale: 8 }} ease={'Elastic.easeOut'} duration={1.2} />
                <Tween
                  to={{ scale: 4 }}
                  ease={'Elastic.easeIn'}
                  duration={1.1}
                  onComplete={() => {
                    this.handleBreak();
                    this.setState({ background: 'transparent' });
                  }}
                />
                <Tween
                  to={{ scale: finalScale, x: x, y: y }}
                  delay={1}
                  duration={1.4}
                  ease={'Bounce.easeOut'}
                />
              </Timeline>
            ) : (
              <></>
            )}
          </div>
        </div>
        {background !== 'transparent' && (
          <AudioCaptions style={{ position: 'fixed', bottom: 100, width: '100%' }} />
        )}
      </div>
    );
  }
}

AwardStar.propTypes = {
  character: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  card: PropTypes.object,
  level: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
  target: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

export default AwardStar;
