import {
  GET_ACTIVITY_DATA_SUCCESS,
  GET_ACTIVITY_DATA_ERROR,
  START_ACTIVITY,
  RESET_ACTIVITY_STORE
} from 'store/types/activity';

export const initialState = {
  data: {
    level: 0,
    character: {
      character: ''
    }
  },

  dataLoaded: false,
  assetsLoaded: false,
  started: false,
  levelOverride: 0,
  isVideoModalOpen: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITY_DATA_SUCCESS:
      return { ...state, data: action.payload };
    case GET_ACTIVITY_DATA_ERROR:
      return { ...state, error: action.payload };
    case START_ACTIVITY:
      return { ...state, started: true };

    case RESET_ACTIVITY_STORE:
      return { ...initialState };
    default:
      return state;
  }
};
