import { Howl } from 'howler';

const ERR_HOWL = src => ({
  /* eslint-disable no-console */
  play: () => console.warn(`There was an error loading the sound ${src}, unable to play it.`),
  stop: () => console.warn(`There was an error loading the sound ${src}, unable to stop it.`)
  /* eslint-enable no-console */
});

const _isValidAudioSrc = src => src.endsWith('.mp3') || src.includes('.mp3?');

export default class AssetLoader {
  constructor() {
    this.cache = new Map();
    this.queue = {
      images: [],
      sounds: []
    };
  }
  _notInCache = filename => !this.cache.has(filename);

  _addToQueue = (assets, queueName) => {
    if (!assets || !queueName) {
      return;
    }

    const assetsDistinct = assets
      .filter(
        asset =>
          asset !== undefined &&
          asset !== null &&
          (Array.isArray(asset) ? asset.find(x => x !== undefined && x !== null) : true)
      )
      .map(asset =>
        Array.isArray(asset)
          ? [...new Set(asset.filter(x => x !== undefined && x !== null))]
          : asset
      );
    this.queue[queueName] = this.queue[queueName].concat(
      ...assetsDistinct.filter(this._notInCache)
    );
  };

  _addToCache = (src, asset) => this.cache.set(src, asset);

  _loadImage = src =>
    new Promise(resolve => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = () => resolve(image);
      image.src = src;
    });

  _loadAudio = options => (src = '') =>
    new Promise(resolve => {
      if (typeof src !== 'string' || !_isValidAudioSrc(src)) {
        return resolve(this._addToCache(src, ERR_HOWL(src)));
      }

      const audio = new Howl({
        src,
        ...options,
        onload: () => resolve(this._addToCache(src, audio)),
        onloaderror: () => resolve(this._addToCache(src, ERR_HOWL(src)))
      });
    });

  _downloadAssets = async (queue, loader) => {
    await Promise.all(queue.map(loader));

    // reset queue
    this.queue = {
      images: [],
      sounds: []
    };

    return this.cache;
  };

  _downloadImages = () => this._downloadAssets(this.queue.images, this._loadImage);

  _downloadAudio = () => {
    const audioLoader = this._loadAudio({
      html5: true,
      preload: true
    });

    return this._downloadAssets(this.queue.sounds, audioLoader);
  };

  queueImage = (...imageAssetPaths) => this._addToQueue(imageAssetPaths, 'images');

  queueAudio = (...audioAssetPaths) => this._addToQueue(audioAssetPaths, 'sounds');

  async downloadAll() {
    await Promise.all([
      this._downloadImages(this.queue.images),
      this._downloadAudio(this.queue.sounds)
    ]);
    return Promise.resolve(this.cache);
  }
}
