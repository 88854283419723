export const TOLERANCES = {
  RELAXED: 'relaxed',
  STANDARD: 'standard',
  STRICT: 'strict'
};

export const TOLERANCE_VALUES = {
  relaxed: 1.7,
  standard: 1.4,
  strict: 1.1
};
