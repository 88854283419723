/* eslint-disable */
import { SoundsMapping } from './sounds-mapping';
import { sample } from 'lodash';

const transformPathToUrl = (snd, path) => {
  const { REACT_APP_S3_PREFIX } = process.env;

  return {
    src: `${REACT_APP_S3_PREFIX}/${path}/${snd.src}`,
    captions: `${REACT_APP_S3_PREFIX}/${path}/${snd.captions}`
  };
};

export default function getRandomSound(soundType, language = 'en') {
  if (SoundsMapping[soundType.name]) {
    const { fileList, path = '' } = SoundsMapping[soundType.name];

    if (fileList && fileList.length > 0) {
      const randSnd = sample(fileList);
      return transformPathToUrl(randSnd, path);
    }

    return { src: '', captions: '' };
  }

  return { src: '', captions: '' };
}
