import { CharacterCase } from 'constants/enums/characters';

const formatCharacterCase = (character, characterCase) => {
  if (!characterCase || ![CharacterCase.LOWERCASE, CharacterCase.UPPERCASE].includes(characterCase))
    return character;

  return characterCase === CharacterCase.UPPERCASE
    ? character.toUpperCase()
    : character.toLowerCase();
};

const getNextCharacter = ({ currentCharPos, currentCharCasePos, charSeq, charCaseSeq }) => {
  return currentCharPos !== -1
    ? charSeq[currentCharCasePos === charCaseSeq.length - 1 ? currentCharPos + 1 : currentCharPos]
    : null;
};

const getNextCharacterCase = (currentCharCasePos, charCaseSeq) => {
  return currentCharCasePos === charCaseSeq.length - 1
    ? charCaseSeq[0]
    : charCaseSeq[currentCharCasePos + 1];
};

const getSequence = val => {
  return Array.isArray(val) ? val.map(item => item.toLowerCase()) : [val.toLowerCase()];
};

export { formatCharacterCase, getNextCharacter, getNextCharacterCase, getSequence };
