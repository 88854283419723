export const SoundTypes = {
  PICK_AND_PRACTICE: { name: 'pickAndPractice', category: 'generalInstructions' },

  SUCCESS: { name: 'success', category: 'instructions' },
  EARNED_A_STAR: { name: 'earnedAStar', category: 'instructions' },
  EARNED_ANOTHER_STAR: { name: 'earnedAnotherStar', category: 'instructions' },
  EARNED_CARD: { name: 'earnedACard', category: 'instructions' },
  CARD_DESCRIPTION: { name: 'cardDescription', category: 'instructions' },

  REVERSAL: { name: 'reversal', category: 'instructions' },
  FINGER_DOWN: { name: 'fingerDown', category: 'instructions' },
  OUT_OF_BOUNDS: { name: 'outOfBounds', category: 'instructions' },
  REPLAY_DEMO: { name: 'replayDemo', category: 'instructions' },
  // NOW_YOU: { name: 'nowYou', category: 'instructions' },

  NOW_WET: { name: 'nowWet', category: 'instructions' },
  NOW_DRY: { name: 'nowDry', category: 'instructions' },
  NOW_TRY: { name: 'nowTry', category: 'instructions' },

  WATCH_FOR_CLUE: { name: 'watchForClue', category: 'instructions' },
  NO_CLUES: { name: 'noClues', category: 'instructions' },

  DING_WRONG: { name: 'dingWrong', category: 'effects' },
  DING_CORRECT: { name: 'dingCorrect', category: 'effects' },
  DING_CONTINUE: { name: 'dingContinue', category: 'effects' },
  DING_PICKUP: { name: 'dingPickup', category: 'effects' },

  CHALK: { name: 'chalk', category: 'effects' },
  PAPER: { name: 'paper', category: 'effects' },
  SPONGE: { name: 'sponge', category: 'effects' },

  BUTTON_CLICK: { name: 'buttonClick', category: 'effects' },

  LETS_TRY: { name: 'letsTry', category: 'instructions' },
  FIRST_WATCH_ME: { name: 'watchMe', category: 'instructions' },
  STARTS: { name: 'startsIn', category: 'instructions' },
  NOW_YOU_TRY: { name: 'nowYouTry', category: 'instructions' }
};
