import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getCharacterAssets } from 'utils/get-character-card-assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as fasStar, faLock } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

class RewardCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imagePath: '',
      stickerPath: ''
    };

    this.assetPath =
      props.module === 'wdt-capitals'
        ? 'print/uppercase'
        : props.module === 'wdt-lowercase'
        ? 'print/lowercase'
        : props.module === 'wdt-numbers'
        ? 'numbers'
        : '';
  }

  componentDidMount() {
    this.loadAssets();
  }

  loadAssets = async () => {
    const imagePath = await getCharacterAssets(
      `./characters/${this.assetPath}/char-${this.props.char}.png`
    );
    const stickerPath = await getCharacterAssets(
      `./stickers/${this.assetPath}/sticker-${this.props.char}.png`
    );
    this.setState({ imagePath, stickerPath });
  };

  renderLock = () => {
    return <FontAwesomeIcon icon={faLock} />;
  };

  renderStarSection = () => {
    const {
      props: { passedRef, starAmount }
    } = this;

    const starsMarkup = [];

    for (let i = 0; i < 3; i++) {
      starsMarkup.push(
        starAmount > i ? (
          <div key={`star-${i}`} data-star-earned={true} data-star-index={i + 1}>
            <FontAwesomeIcon key={i} icon={fasStar} size={'1x'} />
          </div>
        ) : (
          <div
            data-star-earned={false}
            data-star-index={i + 1}
            key={`star-${i}`}
            ref={passedRef !== undefined && i === starAmount && i < 2 ? passedRef : undefined}
          >
            <FontAwesomeIcon key={i} icon={farStar} size={'1x'} />
          </div>
        )
      );
    }

    return starsMarkup;
  };

  render() {
    const {
      state: { imagePath, stickerPath },
      props: { complete, passedRef, status, starAmount, char, ariaLabel, contentScaling }
    } = this;

    return (
      <div className="col">
        <button
          id={`char-${char}`}
          className={`wdt-button reward-card zoom${contentScaling} ${
            status === 'locked' ? 'disabled' : 'enabled'
          } ${complete ? 'complete' : ''}`}
          onClick={() =>
            !complete && status === 'unlocked' && this.props.onSelection(this.props.char)
          }
          aria-label={ariaLabel}
        >
          <div className={'image-container'}>
            {complete ? (
              <img className="sticker" src={stickerPath} alt={tA11y('sticker')} />
            ) : (
              <img
                ref={2 === starAmount ? passedRef : undefined}
                src={imagePath}
                alt={`${tA11y('char')} ${char}`}
              />
            )}
          </div>
          <div className={'icon-container'}>
            {status === 'locked' ? this.renderLock() : this.renderStarSection()}
          </div>
        </button>
      </div>
    );
  }
}

RewardCard.propTypes = {
  blackAndWhite: PropTypes.bool,
  char: PropTypes.string,
  complete: PropTypes.bool,
  hideStars: PropTypes.bool,
  module: PropTypes.string,
  onSelection: PropTypes.func,
  starAmount: PropTypes.number,
  passedRef: PropTypes.any,
  status: PropTypes.string,
  ariaLabel: PropTypes.string,
  contentScaling: PropTypes.number
};

export default RewardCard;
