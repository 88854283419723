import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { shuffle } from 'lodash';
import paper from 'paper/dist/paper-full.min';
import getDrawConfiguration from 'utils/draw-config';
import TimeoutList from 'utils/timeout-list';
import chalkTexturePath from 'assets/photo/draw-stage/chalk-texture.png';
import spongeTexturePath from 'assets/photo/draw-stage/sponge-texture.png';
import clothTexturePath from 'assets/photo/draw-stage/cloth-texture.png';
import chalkPointerPath from 'assets/photo/draw-stage/chalk-pointer.png';
import chalkPointerLeftHandPath from 'assets/photo/draw-stage/chalk-pointer-left-hand.png';
import spongePointerPath from 'assets/photo/draw-stage/sponge-pointer.png';
import clothPointerPath from 'assets/photo/draw-stage/cloth-pointer.png';
import { SoundTypes } from 'constants/enums/sound-types';
import { ErrorTypes } from 'constants/enums/error-types';
import { audioManager } from 'utils/draw-activity/audio-manager';
import { getScaleDegree } from 'utils/draw-activity/canvas-manager';
import { guidelineManager } from 'utils/draw-activity/guideline-manager';
import { showVisualCue, hideVisualCue } from 'store/actions/ui';
import DistanceManager from 'utils/draw-activity/distance-manager';
import ToleranceManager from 'utils/draw-activity/tolerance-manager';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

class DrawActivityGame extends Component {
  constructor() {
    super();

    this.wrongStart = 0;
    this.startingSpotErrors = [];
    this.drawEnabled = false;
    this.flags = [];
    this.formerFlagIndex = -1;
    this.guideLineIndex = -1;
    this.consecutiveErrorsAmount = 0;
    this.guideLineSVGs = null;
    this.textureTrails = [];
    this.currentDrawInstrument = null;
    this.textureGroup = null;
    this.textureDegrees = [15, 30, 45, 60, 75, 90, -15, -30, -45, -60, -75, -90];
    this.timeoutList = new TimeoutList();

    this.drawnDotPath = false;
    this.dotDrawStartValid = false;
    this.paperScopes = [];

    this.previousCursorPosition = {
      x: null,
      y: null
    };

    this.message = '';
    this.helpInProgress = false;
    // used for testing purposes, must be 0 in production
    this.flagOpacity = 0;

    this.drawInstrumentStates = [
      {
        texturePath: spongeTexturePath,
        pointerPath: spongePointerPath,
        soundType: SoundTypes.SPONGE,
        soundTypeInstruction: SoundTypes.NOW_WET,
        name: 'sponge'
      },
      {
        texturePath: clothTexturePath,
        pointerPath: clothPointerPath,
        soundType: SoundTypes.PAPER,
        soundTypeInstruction: SoundTypes.NOW_DRY,
        name: 'cloth'
      },
      {
        texturePath: chalkTexturePath,
        pointerPath: chalkPointerPath,
        soundType: SoundTypes.CHALK,
        soundTypeInstruction: SoundTypes.NOW_TRY,
        name: 'chalk'
      }
    ];

    this.state = {
      roundIndex: -1
    };

    this.currentTolerance = 0;
    this.distanceManager = new DistanceManager(0, 0, 10);

    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleDraw = this.handleDraw.bind(this);
    this.handleDrawEnd = this.handleDrawEnd.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
  }

  componentDidMount() {
    this.drawConfig = getDrawConfiguration(
      this.props.character,
      this.props.characterType,
      this.props.characterCase
    );
    this.nextRound();
  }

  componentWillUnmount() {
    if (this.timeoutList && this.timeoutList.length > 0) {
      this.timeoutList.cancelAll();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.roundIndex !== this.state.roundIndex) {
      this.currentScope = this.props.paperScope;
      this.addTouchEventListeners();
      this.textureGroup = this.initializeTextureGroup(this.props.fullscreen);
      this.setNextRoundState();
    }

    if (prevProps.zoomToleranceFactor !== this.props.zoomToleranceFactor) {
      this.computeTolerance();
    }
  }

  addTouchEventListeners() {
    this.props.canvas.current.addEventListener('mousedown', this.handleTouchStart);
    this.props.canvas.current.addEventListener('mousemove', this.handleDraw);
    this.props.canvas.current.addEventListener('mouseup', this.handleTouchEnd);
    this.props.canvas.current.addEventListener('touchstart', this.handleTouchingStart);
    this.props.canvas.current.addEventListener('touchmove', this.handleTouchDraw);
    this.props.canvas.current.addEventListener('touchend', this.handleTouchingEnd);
  }

  removeTouchEventListeners() {
    if (this.props.canvas.current) {
      this.props.canvas.current.removeEventListener('mousedown', this.handleTouchStart);
      this.props.canvas.current.removeEventListener('mousemove', this.handleDraw);
      this.props.canvas.current.removeEventListener('mouseup', this.handleTouchEnd);
      this.props.canvas.current.removeEventListener('touchstart', this.handleTouchingStart);
      this.props.canvas.current.removeEventListener('touchmove', this.handleTouchDraw);
      this.props.canvas.current.removeEventListener('touchend', this.handleTouchingEnd);
    }
  }

  handleTouchingStart = event => {
    event.preventDefault();
    this.handleTouchStart(event);
  };

  handleTouchDraw = event => {
    event.preventDefault();
    this.handleDraw(event);
  };

  handleTouchingEnd = event => {
    event.preventDefault();
    this.handleTouchEnd(event);
  };

  calculatePercentDone(currentGuideLine) {
    const segments = [];

    this.textureTrails[this.guideLineIndex].forEach(raster => {
      const segment = [raster.position.x, raster.position.y];

      segments.push(segment);
    });

    const drawnPath = new paper.Path({
      segments: segments,
      strokeColor: 'white',
      visible: false,
      selected: false
    });
    const qty = (drawnPath.length / currentGuideLine.length) * 100;

    drawnPath.remove();

    return qty;
  }

  addVisualCue(action, message, interval = 1000) {
    if (action === 'handleDrawEndInvalid' && this.message === 'error') return;
    this.message = message;
    this.props.showVisualCue(message);
    this.timeoutList.add(setTimeout(() => this.props.hideVisualCue(), interval));
  }

  constructAndLogError(currentGuideLine, errorType, direction) {
    const timeStamp = new Date().toISOString();
    const error = {};
    const percentComplete = parseInt(this.calculatePercentDone(currentGuideLine));
    switch (errorType.name) {
      case 'OutOfBounds':
        error.error = errorType.name;
        error.round = this.state.roundIndex;
        error.competency = 'Writing';
        error.actual = {
          direction: direction,
          percentComplete: percentComplete
        };
        error.expected = {
          character: this.props.character,
          stroke: currentGuideLine.name || ''
        };
        if (percentComplete < 10) {
          // if its out of bounds in the first 10% its actually starting the wrong way
          error.error = ErrorTypes.STARTED_WRONG_WAY.name;
          // error.expected.direction = this.props.svgNameList[currentGuideLine.name].direction;
        }
        error.timestamp = timeStamp;
        this.props.onUpdateErrorCount(error);
        break;
      case 'ReversedDirection':
        error.error = errorType.name;
        error.round = this.state.roundIndex;
        error.competency = 'Writing';
        error.actual = {
          direction: direction,
          percentComplete: percentComplete
        };
        error.expected = {
          character: this.props.character,
          stroke: currentGuideLine.name
          // direction: this.props.svgNameList[currentGuideLine.name].direction
        };
        error.timestamp = timeStamp;
        this.props.onUpdateErrorCount(error);
        break;
      case 'LiftedFinger':
        error.error = errorType.name;
        error.competency = 'Writing';
        error.round = this.state.roundIndex;
        error.actual = {
          percentComplete: percentComplete
        };
        error.expected = {
          character: this.props.character,
          stroke: currentGuideLine.name
        };
        error.timestamp = timeStamp;
        this.props.onUpdateErrorCount(error);
        break;
    }
  }

  getFlagIndex(currentGuideLineSVG, flags) {
    if (currentGuideLineSVG.isReversed) {
      return flags.length;
    }

    return 0;
  }

  initializeTextureGroup(isFullscreen) {
    const { height, width } = this.props.canvas.current.getBoundingClientRect();
    const mask = new paper.Rectangle(
      isFullscreen ? 0 : (15 / 100) * width,
      isFullscreen ? 0 : (11.5 / 100) * height,
      isFullscreen ? width : (70 / 100) * width,
      isFullscreen ? height : height - (23.25 / 100) * height
    );
    const maskShape = new paper.Shape.Rectangle(mask);
    const textureGroup = new paper.Group([maskShape]);

    textureGroup.clipped = true;

    return textureGroup;
  }

  getGuideLines(letterSvg) {
    const guideLineAmount = parseInt(letterSvg.name.split('-')[2], 10);
    const guidelineSVGs = [];
    let currentGuideLineSVG = null;

    for (let i = 0; i < guideLineAmount; i++) {
      currentGuideLineSVG = paper.project.getItem({
        name: new RegExp(`(line|dot)-${i}`)
      });

      if (currentGuideLineSVG.name.match(/line/)) {
        currentGuideLineSVG.data = {
          coordinates: this.getGuideLineCoordinates(currentGuideLineSVG)
        };
      }

      guidelineSVGs.push(currentGuideLineSVG);
    }

    return guidelineSVGs;
  }

  getGuideLineCoordinates(guideLineSVG) {
    const coordinates = [];
    let currentPoint = null;

    for (let i = 0, len = Math.floor(guideLineSVG.length); i < len; i += this.drawConfig.density) {
      currentPoint = guideLineSVG.getPointAt(i);
      coordinates.push({
        x: currentPoint.x,
        y: currentPoint.y
      });
    }

    coordinates.push({
      x: guideLineSVG.getPointAt(guideLineSVG.length).x,
      y: guideLineSVG.getPointAt(guideLineSVG.length).y
    });

    return coordinates;
  }

  getTextureTrailState(guideLineSVGs) {
    const textureTrails = [];

    guideLineSVGs.forEach(guideLineSVG => {
      if (!guideLineSVG.isTransport) {
        textureTrails.push([]);
      } else {
        textureTrails.push(null);
      }
    });

    return textureTrails;
  }

  initializeDrawInstrument() {
    const currentGuideLine = guidelineManager.guidelineSVGs[this.guideLineIndex];
    const currentDrawInstrumentState = this.drawInstrumentStates[this.state.roundIndex];
    const isChalkPointer = currentDrawInstrumentState.name === 'chalk';
    const raster = new paper.Raster(
      isChalkPointer
        ? `${currentDrawInstrumentState.name}-pointer${this.props.leftHand ? '-left-hand' : ''}`
        : `${currentDrawInstrumentState.name}-pointer`
    );
    const { width: canvasWidth } = this.props.canvas.current.getBoundingClientRect();
    const rasterWidth = Math.floor((11 / 100) * canvasWidth);
    let startPoint = null;

    raster.opacity = 0;

    raster.onLoad = () => {
      raster.setWidth(rasterWidth);
      raster.setHeight(rasterWidth);

      switch (currentDrawInstrumentState.name) {
        case 'chalk': {
          raster.scale(
            getScaleDegree(this.props.characterType, this.props.characterCase, this.props.character)
          );
          break;
        }
        case 'cloth': {
          raster.scale(
            getScaleDegree(
              this.props.characterType,
              this.props.characterCase,
              this.props.character,
              1.2
            )
          );
          break;
        }

        case 'sponge':
        default: {
          raster.scale(
            getScaleDegree(this.props.characterType, this.props.characterCase, this.props.character)
          );
          break;
        }
      }

      startPoint = this.getPathStartPoint(currentGuideLine);

      this.distanceManager.setFormerCoordinates(startPoint.x, startPoint.y);

      raster.position = isChalkPointer
        ? this.getNextChalkPointerPosition(raster, startPoint.x, startPoint.y, this.props.leftHand)
        : {
            x: startPoint.x,
            y: startPoint.y
          };

      raster.originalScale = raster.getScaling().length;
      if (this.props.starAmount === 3) {
        raster.opacity = 0;
      } else {
        raster.opacity = 1;
      }
    };

    return raster;
  }

  moveDrawInstrument(x, y) {
    const isChalkPointer = this.drawInstrumentStates[this.state.roundIndex].name === 'chalk';

    this.currentDrawInstrument.bringToFront();

    this.currentDrawInstrument.position = isChalkPointer
      ? this.getNextChalkPointerPosition(this.currentDrawInstrument, x, y, this.props.leftHand)
      : {
          x,
          y
        };

    this.props.handleAutoScroll(
      this.currentDrawInstrument.position.x,
      this.currentDrawInstrument.position.y,
      true
    );
  }

  getNextChalkPointerPosition(chalkPointer, currentX, currentY, leftHand) {
    let nextPosition = null;

    if (leftHand) {
      nextPosition = {
        x: currentX - chalkPointer.bounds.width / 4,
        y: currentY + chalkPointer.bounds.height / 4
      };
    } else {
      nextPosition = {
        x: currentX + chalkPointer.bounds.width / 4,
        y: currentY + chalkPointer.bounds.height / 4
      };
    }

    return nextPosition;
  }

  setDrawInstrumentVisibility() {
    if (this.props.starAmount === 1) {
      this.toggleDrawInstrumentOpacity(true);
    } else if (this.props.starAmount === 2) {
      this.setBlinkingInstrument();
    } else if (this.props.starAmount === 3) {
      this.toggleDrawInstrumentOpacity();
    }
  }

  getClueSoundType() {
    if (this.state.roundIndex > 0) return null;
    if (this.props.starAmount === 2) {
      return SoundTypes.WATCH_FOR_CLUE;
    } else if (this.props.starAmount === 3) {
      return SoundTypes.NO_CLUES;
    }

    return null;
  }

  toggleDrawInstrumentOpacity(show) {
    this.currentDrawInstrument.opacity = show ? 1 : 0;
  }

  setBlinkingInstrument() {
    this.currentDrawInstrument.opacity = 1;
    this.timeoutList.add(() => {
      this.currentDrawInstrument.opacity = 0;
    }, 2000);
    clearInterval(this.blinkingInstrument);
    this.blinkingInstrument = setInterval(() => {
      this.currentDrawInstrument.opacity = 1;
      this.timeoutList.add(() => {
        this.currentDrawInstrument.opacity = 0;
      }, 2000);
    }, 8000);
  }

  resetDrawInstrumentPosition() {
    let startPoint = null;
    let currentGuideLine = guidelineManager.guidelineSVGs[this.guideLineIndex];

    this.setDrawInstrumentVisibility();

    if (currentGuideLine.isContinuePath) {
      currentGuideLine = guidelineManager.guidelineSVGs[this.guideLineIndex - 1];
    }

    if (currentGuideLine.isDot) {
      this.moveDrawInstrument(
        currentGuideLine.getBounds().center.x,
        currentGuideLine.getBounds().center.y
      );
    } else {
      startPoint = this.getPathStartPoint(currentGuideLine);
      this.moveDrawInstrument(startPoint.x, startPoint.y);
    }
  }

  drawTexturePoint(x, y, guideLineIndex) {
    const currentDrawInstrumentState = this.drawInstrumentStates[this.state.roundIndex];
    const raster = new paper.Raster(`${currentDrawInstrumentState.name}-texture`);
    const { width: canvasWidth } = this.props.canvas.current.getBoundingClientRect();
    const textureRadius = (6.3 / 100) * canvasWidth;

    raster.setWidth(textureRadius);
    raster.setHeight(textureRadius);

    switch (currentDrawInstrumentState.name) {
      case 'sponge': {
        raster.scale(
          getScaleDegree(this.props.characterType, this.props.characterCase, this.props.character)
        );
        break;
      }
      case 'chalk': {
        raster.scale(
          getScaleDegree(this.props.characterType, this.props.characterCase, this.props.character)
        );
        raster.rotate(shuffle(this.textureDegrees)[0]);
        break;
      }
      case 'cloth':
      default: {
        raster.scale(
          getScaleDegree(
            this.props.characterType,
            this.props.characterCase,
            this.props.character,
            1.2
          )
        );
        break;
      }
    }

    this.textureGroup.addChild(raster);

    raster.position = { x, y };

    if (this.textureTrails[guideLineIndex]) {
      this.textureTrails[guideLineIndex].push(raster);
    }
  }

  drawDenseTexturePoint(x, y, guideLineIndex) {
    for (let i = 0; i < 3; i++) {
      this.drawTexturePoint(x, y, guideLineIndex);
    }
  }

  removeTextureTrail(guideLineIndex, trailAmount = 1) {
    for (let i = 0; i < trailAmount; i++) {
      if (this.textureTrails[guideLineIndex - i]) {
        this.textureTrails[guideLineIndex - i].forEach(chalkPoint => {
          chalkPoint.remove();
        });
      }
      this.textureTrails[guideLineIndex - i] = [];
    }
  }

  placeFlagsOnPath(guideLineSVG, step = 50) {
    const flags = [];
    const guideLineLength = Math.floor(guidelineManager.guidelineSVGs.length);

    if (!guideLineSVG.isDot) {
      for (let i = 0; i < guideLineLength; i += step) {
        flags.push(this.placeFlagsOnPoint(guideLineSVG, i, this.currentTolerance, step));
      }
    }

    return flags;
  }

  placeFlagsOnPoint(guideLineSVG, pointIndex, flagVectorLength, step) {
    const currentPoint = guideLineSVG.getPointAt(pointIndex);
    const normalVector = guideLineSVG.getNormalAt(pointIndex);
    const guideLineLength = Math.floor(guideLineSVG.length);
    let right = null;
    let left = null;

    normalVector.setLength(flagVectorLength);

    right = new paper.Path({
      segments: [
        currentPoint,
        new paper.Point(currentPoint.x + normalVector.x, currentPoint.y + normalVector.y)
      ],
      strokeColor: 'red',
      opacity: this.flagOpacity,
      strokeWidth: 20
    });
    right.flagIndex =
      pointIndex === guideLineLength
        ? (guideLineLength - (guideLineLength % step)) / step + 1
        : pointIndex / step;
    right.parentGuideLineName = guideLineSVG.name;

    left = new paper.Path({
      segments: [
        currentPoint,
        new paper.Point(currentPoint.x - normalVector.x, currentPoint.y - normalVector.y)
      ],
      strokeColor: 'blue',
      opacity: this.flagOpacity,
      strokeWidth: 20
    });
    right.flagIndex =
      pointIndex === guideLineLength
        ? (guideLineLength - (guideLineLength % step)) / step + 1
        : pointIndex / step;
    left.parentGuideLineName = guideLineSVG.name;

    return {
      left,
      right
    };
  }

  removeFlagsFromPath() {
    this.flags.forEach(flag => {
      flag.left.remove();
      flag.right.remove();
    });
    this.flags = [];
  }

  resetFlagCache(guideLineSVG) {
    if (guideLineSVG.isReversed) {
      this.formerFlagIndex = this.flags.length;
    } else {
      this.formerFlagIndex = -1;
    }
  }

  handleTouchStart(event) {
    this.mouseTouchPressed = true;
    if (
      this.isMouseOrTouchPressed(event) &&
      !this.helpInProgress &&
      !audioManager.instructionsInProgress
    ) {
      audioManager.playSound(SoundTypes.DING_PICKUP);
      this.handleDrawStart(event);
    }
  }

  handleDrawStart(event) {
    let clientX;
    let clientY;
    let cursorPosition = this.getCursorPosition(event);

    clientX = cursorPosition.clientX;
    clientY = cursorPosition.clientY;

    const currentGuideLine = guidelineManager.guidelineSVGs[this.guideLineIndex];
    const cursorPoint = new paper.Point(clientX, clientY);
    let pathStart = null;

    this.drawEnabled = false;

    this.setPreviousCursorPosition();

    if (currentGuideLine.isDot) {
      pathStart = new paper.Point(
        currentGuideLine.getBounds().center.x,
        currentGuideLine.getBounds().center.y
      );
    } else {
      if (currentGuideLine.isReversed) {
        pathStart = currentGuideLine.getPointAt(currentGuideLine.length);
      } else {
        pathStart = currentGuideLine.getPointAt(0);
      }
    }

    if (cursorPoint.isClose(pathStart, this.currentTolerance)) {
      this.handleValidDrawStart(clientX, clientY, currentGuideLine);
    } else {
      this.wrongStart++;
      this.checkIfErrorExist(currentGuideLine);
    }
  }

  logStartingSpotError() {
    if (this.startingSpotErrors && this.startingSpotErrors.length) {
      this.startingSpotErrors.forEach(el => {
        if (el.actual.times > 0) {
          this.props.onUpdateErrorCount(el);
        }
      });
    }
  }

  checkIfErrorExist(currentGuideLine) {
    if (this.startingSpotErrors.filter(el => el.round === this.state.roundIndex).length) {
      if (
        this.startingSpotErrors
          .filter(el => el.round === this.state.roundIndex)
          .find(el => el.expected.stroke === this.props.svgNameList[currentGuideLine.name].name)
      ) {
        this.startingSpotErrors.find(
          el =>
            el.round === this.state.roundIndex &&
            el.expected.stroke === this.props.svgNameList[currentGuideLine.name].name
        ).actual.times = this.wrongStart;
      } else {
        const error = this.constructStartingSpotError(currentGuideLine);
        this.startingSpotErrors.push(error);
      }
    } else {
      const error = this.constructStartingSpotError(currentGuideLine);
      this.startingSpotErrors.push(error);
    }
  }
  constructStartingSpotError(currentGuideLine) {
    const timeStamp = new Date().toISOString();
    const error = {};
    error.error = ErrorTypes.STARTING_SPOT.name;
    error.round = this.state.roundIndex;
    error.competency = 'Writing';
    error.timestamp = timeStamp;
    error.actual = {
      times: this.wrongStart
    };
    error.expected = {
      character: this.props.character,
      stroke: currentGuideLine.name
    };
    return error;
  }

  handleValidDrawStart(clientX, clientY, currentGuideLine) {
    clearInterval(this.blinkingInstrument);

    this.drawEnabled = true;

    if (currentGuideLine.isDot) {
      this.handleDotGuideLineValidDrawStart(clientX, clientY);
    } else {
      this.timeoutList.cancelAll();

      this.toggleDrawInstrumentOpacity(true);
      this.scaleDrawInstrument();
    }
  }

  handleDotGuideLineValidDrawStart(clientX, clientY) {
    if (!this.drawnDotPath) {
      this.dotDrawStartValid = true;
      this.toggleDrawInstrumentOpacity(true);
      this.scaleDrawInstrument();

      if (this.drawInstrumentStates[this.state.roundIndex].name === 'chalk') {
        this.drawDenseTexturePoint(clientX, clientY, this.guideLineIndex);
      } else {
        this.drawTexturePoint(clientX, clientY, this.guideLineIndex);
      }

      this.timeoutList.add(() => {
        // We need to set this flag to true in order to avoid
        // the bypassing of handleDrawEnd, when pressing out of
        // the dot tolerance range, after a successful tap / tap-drag draw
        this.drawEnabled = true;
        this.handleDrawEnd();
      }, 1000);
    }
  }

  scaleDrawInstrument() {
    let scale = 1.5;
    const currentScale = Math.floor(this.currentDrawInstrument.getScaling().length);
    const originalScale = Math.floor(this.currentDrawInstrument.originalScale);

    if (originalScale !== currentScale) {
      this.currentDrawInstrument.scale(1 / scale);
    } else {
      this.currentDrawInstrument.scale(scale);
      this.timeoutList.add(() => {
        // set 1/scale, to scale back to original size
        this.currentDrawInstrument.scale(1 / scale);
      }, 100);
    }
  }

  setPreviousCursorPosition(clientX, clientY) {
    if (clientX !== undefined && clientY !== undefined) {
      this.previousCursorPosition.x = clientX;
      this.previousCursorPosition.y = clientY;
    } else {
      this.previousCursorPosition.x = null;
      this.previousCursorPosition.y = null;
    }
  }

  drawTextureCluster(clientX, clientY, guideLineIndex) {
    const diffX = clientX - this.previousCursorPosition.x;
    const diffY = clientY - this.previousCursorPosition.y;

    if (this.previousCursorPosition.x !== null && this.previousCursorPosition.y !== null) {
      for (let i = 1; i <= 3; i++) {
        this.drawTexturePoint(
          this.previousCursorPosition.x + (i / 3) * diffX,
          this.previousCursorPosition.y + (i / 3) * diffY,
          guideLineIndex
        );
      }
    }

    this.setPreviousCursorPosition(clientX, clientY);
  }

  playTraceSound() {
    let traceSoundType = this.drawInstrumentStates[this.state.roundIndex].soundType;

    if (!audioManager.isPlaying(traceSoundType)) {
      audioManager.playSound(traceSoundType);
    }

    audioManager.playSound(traceSoundType);
    if (!this.helpInProgress) {
      // pause sound when instrument/mouse is not moving
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        audioManager.pauseSound(traceSoundType);
      }, 200);
    }
  }

  checkDrawingDirection(cX, cY) {
    let directionX, directionY, diffX, diffY;
    if (this.previousCursorPosition.x < cX) {
      directionX = 'right';
      diffX = cX - this.previousCursorPosition.x;
    } else {
      directionX = 'left';
      diffX = this.previousCursorPosition.x - cX;
    }
    if (this.previousCursorPosition.y < cY) {
      directionY = 'down';
      diffY = cY - this.previousCursorPosition.y;
    } else {
      directionY = 'up';
      diffY = this.previousCursorPosition.y - cY;
    }
    this.setPreviousCursorPosition(cX, cY);
    if (diffX > diffY) {
      return directionX;
    } else {
      return directionY;
    }
  }

  processDrawEvent(event) {
    let clientX;
    let clientY;
    let cursorPosition = this.getCursorPosition(event);
    const pressedMouseButton = event.buttons !== undefined ? event.buttons : event.which;
    const isTouchEvent = event.constructor.name === 'TouchEvent';

    clientX = cursorPosition.clientX;
    clientY = cursorPosition.clientY;

    const currentGuideLine = guidelineManager.guidelineSVGs[this.guideLineIndex];
    const needsValidation = this.distanceManager.checkThreshold(clientX, clientY);

    const isDrawing =
      this.drawEnabled &&
      !this.helpInProgress &&
      needsValidation &&
      !audioManager.instructionsInProgress &&
      (pressedMouseButton || isTouchEvent);

    return {
      clientX,
      clientY,
      currentGuideLine,
      isDrawing
    };
  }

  handleDraw(event) {
    const { clientX, clientY, currentGuideLine, isDrawing } = this.processDrawEvent(event);
    const cursorPoint = new paper.Point(clientX, clientY);

    this.props.handleAutoScroll(clientX, clientY);

    if (isDrawing) {
      this.playTraceSound();
      const direction = this.checkDrawingDirection(clientX, clientY);

      if (currentGuideLine.isDot) {
        this.handleDrawOverDotPath(clientX, clientY, currentGuideLine);
      } else {
        this.drawTexturePoint(clientX, clientY, this.guideLineIndex);
        this.moveDrawInstrument(clientX, clientY);
      }

      if (!currentGuideLine.isDot) {
        if (!this.validateDrawDirection(currentGuideLine, clientX, clientY)) {
          this.handleInvalidDrawDirection(currentGuideLine, direction);
        }

        if (!this.validateDrawStray(currentGuideLine, clientX, clientY, this.currentTolerance)) {
          this.handleInvalidDrawStray(currentGuideLine, direction);
        }
        this.catchEndOfPath(cursorPoint, event);
      }
    }
  }

  handleDrawOverDotPath(clientX, clientY, currentGuideLine) {
    const pathStart = new paper.Point(
      currentGuideLine.getBounds().center.x,
      currentGuideLine.getBounds().center.y
    );
    const cursorPoint = new paper.Point(clientX, clientY);

    if (cursorPoint.isClose(pathStart, this.currentTolerance) && !this.drawnDotPath) {
      this.drawTextureCluster(clientX, clientY, this.guideLineIndex);
      this.moveDrawInstrument(clientX, clientY);
    }
  }

  catchEndOfPath(cursorPoint) {
    //catch end of path moment and force handle draw end
    if (this.endPositionIsValid(cursorPoint)) {
      this.handleDrawEnd();
    }
  }

  handleTouchEnd(event) {
    let currentGuideLine = null;

    this.mouseTouchPressed = false;
    if (
      guidelineManager.guidelineSVGs[this.guideLineIndex].isDot &&
      !this.drawnDotPath &&
      this.dotDrawStartValid
    ) {
      this.drawnDotPath = true;
      this.dotDrawStartValid = false;
    }
    let cursorPoint;

    if (event.changedTouches) {
      cursorPoint = event.changedTouches[0];
    } else {
      cursorPoint = event;
    }

    if (
      !this.isMouseOrTouchPressed(event) &&
      !this.helpInProgress &&
      this.drawEnabled &&
      !this.endPositionIsValid(cursorPoint)
    ) {
      audioManager.playSound(SoundTypes.DING_WRONG, () => {
        currentGuideLine = guidelineManager.guidelineSVGs[this.guideLineIndex];
        this.handleDrawEndInvalid(currentGuideLine, ErrorTypes.FINGER_UP);
        this.addVisualCue('handleTouchEnd', 'error');
      });
    }
  }

  isMouseOrTouchPressed(event) {
    let pressed = false;
    let isWeb = event.touches === undefined;

    if (isWeb) {
      pressed = this.mouseTouchPressed;
    } else {
      pressed = event.touches && event.touches.length === 1;
    }

    return pressed;
  }

  getCursorPosition(event) {
    let cursorPosition = { clientX: 0, clientY: 0 };
    const { left, top } = this.props.canvas.current.getBoundingClientRect();

    if (event.touches) {
      cursorPosition.clientX = event.touches[0].clientX - left;
      cursorPosition.clientY = event.touches[0].clientY - top;
    } else {
      cursorPosition.clientX = event.clientX - left;
      cursorPosition.clientY = event.clientY - top;
    }

    return cursorPosition;
  }

  handleDrawEnd() {
    audioManager.pauseSound(SoundTypes.CHALK);
    if (this.drawEnabled) {
      if (this.guideLineIndex < guidelineManager.guidelineSVGs.length - 1) {
        this.setNextPathState();
        if (
          this.guideLineIndex < guidelineManager.guidelineSVGs.length &&
          !this.isContinueGuideLine()
        ) {
          this.addVisualCue('handleDrawEnd', 'success');
          this.drawEnabled = false;
          audioManager.playSound(SoundTypes.DING_CORRECT, () => {
            if (this.state.roundIndex === this.drawInstrumentStates.length - 1) {
              audioManager.playSound(SoundTypes.SUCCESS, null, true);
            } else {
              audioManager.playSound(SoundTypes.SUCCESS, null, true);
            }
            if (this.isTransportGuideLine()) {
              this.handleTransportPathProgress();
            } else {
              this.resetDrawInstrumentPosition();
            }
          });
        }
      } else {
        this.drawEnabled = false;
        this.addVisualCue('handleDrawEnd', 'success');
        if (this.state.roundIndex === this.drawInstrumentStates.length - 1) {
          // We call the drawComplete handler immediately after the user finishes
          // the letter draw; no waiting for sound to finish playing, in this case
          this.props.onDrawComplete();
        }

        audioManager.playSound(SoundTypes.DING_CORRECT, () => {
          audioManager.playSound(
            SoundTypes.SUCCESS,
            () => {
              this.advance();
            },
            true
          );
        });
      }
    }
  }

  isContinueGuideLine() {
    return guidelineManager.guidelineSVGs[this.guideLineIndex].isContinuePath;
  }

  isTransportGuideLine() {
    return guidelineManager.guidelineSVGs[this.guideLineIndex].isTransportPath;
  }

  isSegmentedGuideLine(guideLineIndex) {
    if (guideLineIndex !== undefined && guideLineIndex !== null) {
      return guidelineManager.guidelineSVGs[guideLineIndex].isSegmented;
    }

    return guidelineManager.guidelineSVGs[this.guideLineIndex].isSegmented;
  }

  // TODO: see if this method is still needed in some situation
  // validateDrawEnd(clientX, clientY, currentGuideLine) {
  //   const cursorPoint = new paper.Point(clientX, clientY);
  //
  //   if (!cursorPoint.isClose(this.pathEndPoint, this.currentTolerance)) {
  //     timeoutList.add(() => {
  //       this.handleDrawEndInvalid(currentGuideLine);
  //     }, 1000);
  //   } else {
  //     this.advance();
  //   }
  // }

  advance() {
    if (this.guideLineIndex < guidelineManager.guidelineSVGs.length - 1) {
      this.nextPath();
    } else {
      if (this.state.roundIndex < this.drawInstrumentStates.length - 1) {
        this.nextRound();
      } else {
        this.drawEnabled = false;

        this.timeoutList.add(() => {
          this.toggleDrawInstrumentOpacity();
          this.logStartingSpotError();
          this.props.onComplete();
        }, 1000);
      }
    }
  }

  handleDrawEndInvalid(currentGuideLine, errorType, direction) {
    let soundType = errorType.soundType;
    this.constructAndLogError(currentGuideLine, errorType, direction);
    this.consecutiveErrorsAmount++;
    if (this.consecutiveErrorsAmount === 3) {
      this.consecutiveErrorsAmount = 0;
      this.helpInProgress = true;

      if (audioManager.isPlaying(SoundTypes.REPLAY_DEMO)) {
        return;
      }

      if (audioManager.isPlaying(soundType)) {
        audioManager.pauseSound(soundType);
        audioManager.stopInstructions();
      }
      audioManager.playSound(
        SoundTypes.REPLAY_DEMO,
        () => {
          this.resetPath(currentGuideLine);
          this.getHelp(() => {
            this.helpInProgress = false;
          });
        },
        true
      );
    } else {
      audioManager.playSound(
        soundType,
        () => {
          this.addVisualCue('handleDrawEndInvalid', 'error');
          this.resetPath(currentGuideLine);
          this.endPathNormal = this.placeEndPathNormalOnPath(
            guidelineManager.guidelineSVGs[this.guideLineIndex]
          );
        },
        true
      );
    }
  }

  resetPath(currentGuideLine) {
    if (currentGuideLine.isContinuePath) {
      this.handleContinuousPathDrawInvalid(currentGuideLine);
    }

    this.resetDrawInstrumentPosition();
    this.removeTextureTrail(this.guideLineIndex);
    this.resetFlagCache(currentGuideLine);
  }

  handleInvalidDrawStray(currentGuideLine, direction) {
    this.drawEnabled = false;
    this.addVisualCue('handleInvalidDrawStray', 'error');
    audioManager.playSound(SoundTypes.DING_WRONG, () => {
      this.handleDrawEndInvalid(currentGuideLine, ErrorTypes.OUT_OF_BOUNDS, direction);
    });
  }

  handleTransportPathProgress() {
    const currentGuideLine = guidelineManager.guidelineSVGs[this.guideLineIndex];

    if (this.props.starAmount === 1) {
      this.drawEnabled = false;

      this.drawPath(currentGuideLine, this.drawConfig.timeInterval).then(() => {
        this.nextPath();
      });
    } else {
      this.nextPath();
    }
  }

  drawPath(guideLineSVG, timeInterval) {
    const direction = guideLineSVG.isReversed;

    const length = guideLineSVG.data.coordinates.length - 1;
    let traceSoundType = this.drawInstrumentStates[this.state.roundIndex].soundType;
    let index = direction ? length : 0;
    let x = 0;
    let y = 0;

    if (guideLineSVG.isTransportPath) {
      this.currentDrawInstrument.opacity = 0.5;
    } else {
      this.currentDrawInstrument.opacity = 1;
    }
    if (!this.isTransportGuideLine()) {
      this.playTraceSound();
    }

    return new Promise(resolve => {
      this.handbrake = setInterval(() => {
        if (direction) {
          if (index >= 0) {
            ({ x, y } = guideLineSVG.data.coordinates[index]);
            if (!guideLineSVG.isTransportPath) {
              this.drawTexturePoint(x, y, this.guideLineIndex);
            }
            this.moveDrawInstrument(x, y);
            index--;
          } else {
            audioManager.pauseSound(traceSoundType);
            clearInterval(this.handbrake);
            this.setDrawInstrumentVisibility();
            resolve();
          }
        } else {
          if (index <= length) {
            ({ x, y } = guideLineSVG.data.coordinates[index]);
            if (!guideLineSVG.isTransportPath) {
              this.drawTexturePoint(x, y, this.guideLineIndex);
            }
            this.moveDrawInstrument(x, y);
            index++;
          } else {
            audioManager.pauseSound(traceSoundType);
            clearInterval(this.handbrake);
            this.setDrawInstrumentVisibility();
            resolve();
          }
        }
      }, timeInterval);
    });
  }

  handleContinuousPathDrawInvalid(currentGuideLine) {
    const pathAmount = currentGuideLine.removeSegments;
    this.removeTextureTrail(this.guideLineIndex, pathAmount);
    this.resetPathState(pathAmount);
  }

  nextPath() {
    let timeoutBetweenPathsDrawing = 0;

    this.drawEnabled = false;

    this.timeoutList.add(() => {
      this.setNextPathState();
      this.resetDrawInstrumentPosition();
    }, timeoutBetweenPathsDrawing);
  }

  setNextPathState() {
    this.removeFlagsFromPath(guidelineManager.guidelineSVGs[this.guideLineIndex]);
    this.guideLineIndex++;

    if (guidelineManager.guidelineSVGs[this.guideLineIndex].isDot) {
      this.drawnDotPath = false;
    }

    if (!this.isContinueGuideLine()) {
      this.consecutiveErrorsAmount = 0;
    } else {
      if (!this.helpInProgress && this.isSegmentedGuideLine(this.guideLineIndex)) {
        this.addVisualCue('setNextPathState', 'success');
        audioManager.playSound(SoundTypes.DING_CONTINUE);
      }
    }
    if (!this.isTransportGuideLine()) {
      this.flags = this.placeFlagsOnPath(guidelineManager.guidelineSVGs[this.guideLineIndex]);
      this.formerFlagIndex = this.getFlagIndex(
        guidelineManager.guidelineSVGs[this.guideLineIndex],
        this.flags
      );
      this.endPathNormal = this.placeEndPathNormalOnPath(
        guidelineManager.guidelineSVGs[this.guideLineIndex]
      );
    }
  }

  getPathStartPoint(guideLineSVG) {
    let pathStart = null;

    const { coordinates } = guideLineSVG.data;

    if (guideLineSVG.isDot) {
      pathStart = new paper.Point(
        guideLineSVG.getBounds().center.x,
        guideLineSVG.getBounds().center.y
      );
    } else {
      if (!guideLineSVG.isReversed) {
        pathStart = coordinates?.[0] || guideLineSVG.getPointAt(0);
      } else {
        pathStart =
          coordinates?.[coordinates.length - 1] || guideLineSVG.getPointAt(guideLineSVG.length);
      }
    }

    return pathStart;
  }

  getPathEndPoint(guideLineSVG) {
    let pathEnd = null;

    const { coordinates } = guideLineSVG.data;

    if (guideLineSVG.isDot) {
      pathEnd = new paper.Point(
        guideLineSVG.getBounds().center.x,
        guideLineSVG.getBounds().center.y
      );
    } else {
      if (guideLineSVG.isReversed) {
        pathEnd = coordinates?.[0] || guideLineSVG.getPointAt(0);
      } else {
        pathEnd =
          coordinates?.[coordinates.length - 1] || guideLineSVG.getPointAt(guideLineSVG.length);
      }
    }

    return pathEnd;
  }

  placeEndPathNormalOnPath(currentGuideLine) {
    let normalVector = null;
    let endPathNormal = null;

    if (this.endPathNormal) {
      this.endPathNormal.remove();
    }

    this.pathEndPoint = this.getPathEndPoint(currentGuideLine);

    if (!currentGuideLine.isDot) {
      if (currentGuideLine.isReversed) {
        normalVector = currentGuideLine.getNormalAt(0);
      } else {
        normalVector = currentGuideLine.getNormalAt(currentGuideLine.length);
      }

      normalVector.setLength(this.currentTolerance);

      endPathNormal = new paper.Path({
        segments: [
          new paper.Point(
            this.pathEndPoint.x - normalVector.x,
            this.pathEndPoint.y - normalVector.y
          ),
          new paper.Point(
            this.pathEndPoint.x + normalVector.x,
            this.pathEndPoint.y + normalVector.y
          )
        ],
        strokeColor: 'red',
        opacity: this.flagOpacity,
        strokeWidth: 1
      });
    }

    return endPathNormal;
  }

  resetPathState(pathAmount) {
    this.removeFlagsFromPath(guidelineManager.guidelineSVGs[this.guideLineIndex]);
    this.guideLineIndex -= pathAmount - 1;
    this.flags = this.placeFlagsOnPath(guidelineManager.guidelineSVGs[this.guideLineIndex]);
    this.formerFlagIndex = this.getFlagIndex(
      guidelineManager.guidelineSVGs[this.guideLineIndex],
      this.flags
    );
    this.endPathNormal = this.placeEndPathNormalOnPath(
      guidelineManager.guidelineSVGs[this.guideLineIndex]
    );
  }

  computeTolerance() {
    let staticTolerance = ToleranceManager.calculateTolerance(
      this.state.roundIndex,
      this.props.starAmount,
      this.props.easierStrokeTolerance
    );
    let scaleProportion = this.getScaleProportion();

    this.currentTolerance = staticTolerance * scaleProportion * this.props.zoomToleranceFactor;
  }

  nextRound() {
    this.drawEnabled = false;

    if (this.state.roundIndex > 0) {
      this.removeTouchEventListeners();
    }

    this.props.resetAutoScroll();

    this.timeoutList.add(() => {
      this.setState({
        roundIndex: this.state.roundIndex + 1
      });

      this.computeTolerance();
    }, 1000);
  }

  getScaleProportion() {
    let scaleProportion = 1;
    const iPadWidth = 1536 / 2;
    let currentWidth = document.querySelector('.background img').width;

    scaleProportion = currentWidth / iPadWidth;

    return scaleProportion;
  }
  setNextRoundState() {
    if (this.currentDrawInstrument) {
      this.currentDrawInstrument.remove();
    }
    if (this.state.roundIndex > 0) {
      this.removeFlagsFromPath(guidelineManager.guidelineSVGs[this.guideLineIndex]);
    }

    this.guideLineIndex = 0;
    this.consecutiveErrorsAmount = 0;
    this.currentDrawInstrument = this.initializeDrawInstrument();
    this.toggleDrawInstrumentOpacity(false);
    let drawInstrument = this.drawInstrumentStates[this.state.roundIndex];

    audioManager.playSound(drawInstrument.soundTypeInstruction, () => {
      audioManager.playSound(!this.getClueSoundType() ? '' : this.getClueSoundType(), () => {
        this.setDrawInstrumentVisibility();
      });
    });

    this.textureTrails = this.getTextureTrailState(guidelineManager.guidelineSVGs);
    this.flags = this.placeFlagsOnPath(guidelineManager.guidelineSVGs[this.guideLineIndex]);

    this.formerFlagIndex = this.getFlagIndex(
      guidelineManager.guidelineSVGs[this.guideLineIndex],
      this.flags
    );

    this.endPathNormal = this.placeEndPathNormalOnPath(
      guidelineManager.guidelineSVGs[this.guideLineIndex]
    );
  }

  validateDrawDirection(currentGuideLine, clientX, clientY) {
    const circle = new paper.Shape.Circle(new paper.Point(clientX, clientY), 10);
    for (let i = 0; i < this.flags.length; i++) {
      if (circle.intersects(this.flags[i].left || circle.intersects(this.flags[i].right))) {
        if (i !== this.formerFlagIndex) {
          if (currentGuideLine.isReversed) {
            if (this.formerFlagIndex < i && this.formerFlagIndex !== -1) {
              return false;
            }
          } else {
            if (this.formerFlagIndex > i) {
              return false;
            }
          }
          this.formerFlagIndex = i;
        }
      }
    }
    return true;
  }

  endPositionIsValid(cursorPoint) {
    let positionIsValid = null;
    let nearestLocation = null;

    if (guidelineManager.guidelineSVGs[this.guideLineIndex].isDot) {
      positionIsValid = true;
    } else {
      nearestLocation = this.endPathNormal.getNearestLocation(cursorPoint);
      positionIsValid = nearestLocation.distance < 10;
    }

    return positionIsValid;
  }

  handleInvalidDrawDirection(currentGuideLine, direction) {
    this.drawEnabled = false;

    audioManager.playSound(SoundTypes.DING_WRONG, () => {
      this.handleDrawEndInvalid(currentGuideLine, ErrorTypes.WRONG_WAY, direction);
      this.addVisualCue('handleInvalidDrawDirection', 'error');
      // ?? this.resetFlagCache(currentGuideLine);
    });
  }

  validateDrawStray(currentGuideLine, cursorX, cursorY, currentTolerance) {
    const offset = new paper.Point(cursorX, cursorY);
    const tangent = currentGuideLine.getNearestPoint(new paper.Point(cursorX, cursorY));

    return offset.isClose(tangent, currentTolerance);
  }

  getHelp(onComplete) {
    let currentGuideLine = null;
    let continuousPathData = null;

    this.drawPath(
      guidelineManager.guidelineSVGs[this.guideLineIndex],
      this.drawConfig.timeInterval
    ).then(() => {
      if (
        this.guideLineIndex < guidelineManager.guidelineSVGs.length - 1 &&
        guidelineManager.guidelineSVGs[this.guideLineIndex + 1].removeSegments > 0
      ) {
        //draw next continue path
        this.setNextPathState();
        this.getHelp(onComplete);
      } else {
        //reset to initial position
        currentGuideLine = guidelineManager.guidelineSVGs[this.guideLineIndex];
        continuousPathData = currentGuideLine.removeSegments > 0 ? currentGuideLine : null;

        if (continuousPathData) {
          this.handleContinuousPathDrawInvalid(continuousPathData);
        }

        this.resetDrawInstrumentPosition();
        this.removeTextureTrail(this.guideLineIndex);
        this.resetFlagCache(guidelineManager.guidelineSVGs[this.guideLineIndex]);

        this.endPathNormal = this.placeEndPathNormalOnPath(
          guidelineManager.guidelineSVGs[this.guideLineIndex]
        );

        onComplete();
      }
    });
  }

  render() {
    return (
      <div className="draw-instruments">
        {this.drawInstrumentStates.map((drawInstrumentState, index) => {
          return (
            <div className={drawInstrumentState.name} key={index}>
              <img
                id={`${drawInstrumentState.name}-texture`}
                src={drawInstrumentState.texturePath}
                alt={`${drawInstrumentState.name} ${tA11y('texture')}`}
              />
              <img
                id={`${drawInstrumentState.name}-pointer`}
                src={drawInstrumentState.pointerPath}
                alt={`${drawInstrumentState.name} ${tA11y('pointer')}`}
              />
              <img
                id="chalk-pointer-left-hand"
                src={chalkPointerLeftHandPath}
                alt={tA11y('chalkPointerLeftHand')}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

DrawActivityGame.propTypes = {
  character: PropTypes.string.isRequired,
  svgNameList: PropTypes.object,
  characterType: PropTypes.string.isRequired,
  characterCase: PropTypes.string.isRequired,
  easierStrokeTolerance: PropTypes.bool,
  leftHand: PropTypes.bool,
  logError: PropTypes.func,
  strayTolerance: PropTypes.number.isRequired,
  starAmount: PropTypes.number.isRequired,
  landscape: PropTypes.bool,
  fullscreen: PropTypes.bool,
  onComplete: PropTypes.func.isRequired,
  onDrawComplete: PropTypes.func.isRequired,
  onUpdateErrorCount: PropTypes.func.isRequired,
  paperScope: PropTypes.object,
  canvas: PropTypes.object,
  showVisualCue: PropTypes.func,
  hideVisualCue: PropTypes.func,
  zoomToleranceFactor: PropTypes.number,
  handleAutoScroll: PropTypes.func,
  resetAutoScroll: PropTypes.func
};
const mapActionsToProps = {
  showVisualCue,
  hideVisualCue
};
export default connect(null, mapActionsToProps)(DrawActivityGame);
