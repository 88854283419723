// /* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './AudioCaptions.module.scss';
import { useLwtStore } from '@lwtears/lwt-common-frontend/lib/LwtApp';

const AudioCaptions = ({ className, currentCaption, style }) => {
  const [state] = useLwtStore();
  const {
    settings: {
      accessibility: {
        closedCaptionsSettings: { showClosedCaptions, closedCaptionSize, displayCCControl }
      }
    }
  } = state;
  if (!displayCCControl || !showClosedCaptions || !currentCaption) return null;

  return (
    <div className={`${styles.captionsContainer} ${className}`} style={style}>
      <p className={styles.captionTrack} style={{ fontSize: closedCaptionSize }}>
        {currentCaption}
      </p>
    </div>
  );
};

const mapStateToProps = state => ({
  currentCaption: state.ui.captions.currentCaption
});

AudioCaptions.propTypes = {
  currentCaption: PropTypes.string,
  className: PropTypes.string,
  closedCaptionSize: PropTypes.number,
  style: PropTypes.object
};

export default connect(mapStateToProps)(AudioCaptions);
