import { SET_SETTINGS } from '../types/settings';

const defaultSettings = {
  accessibilityMode: false
};

export default (state = defaultSettings, action) => {
  switch (action.type) {
    case SET_SETTINGS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
