class GuidelineManager {
  constructor() {
    this.guidelineSVGs = [];
    this.currentGuideline = null;
  }

  setCurrentGuideline(index) {
    this.currentGuideline = this.guidelineSVGs[index];
  }

  getGuidelineBounds() {
    return {
      x: this.currentGuideline.bounds.x,
      y: this.currentGuideline.bounds.y
    };
  }

  _getGuideLineCoordinates(guideLineSVG, density) {
    const coordinates = [];
    let currentPoint = null;

    for (let i = 0, len = Math.floor(guideLineSVG.length); i < len; i += density) {
      currentPoint = guideLineSVG.getPointAt(i);
      coordinates.push({
        x: currentPoint.x,
        y: currentPoint.y
      });
    }

    coordinates.push({
      x: guideLineSVG.getPointAt(guideLineSVG.length).x,
      y: guideLineSVG.getPointAt(guideLineSVG.length).y
    });

    return coordinates;
  }

  getGuidelines(segments, paperProject, density) {
    const guidelineSVGs = segments
      .filter(segment => segment && Object.keys(segment).length !== 0)
      .map(segment => {
        const {
          id,
          name,
          type,
          isContinue,
          isReversed,
          isSegmented,
          isTransport,
          isWait,
          removeSegments
        } = segment;

        const currentGuideLineSVG = paperProject.getItem({
          name: id
        });

        const isLine = type === 'line';
        const isDot = type === 'dot';

        if (!isDot && !isLine) return null;

        if (isLine) {
          currentGuideLineSVG.data = {
            coordinates: this._getGuideLineCoordinates(currentGuideLineSVG, density)
          };
        } else {
          currentGuideLineSVG.bounds = {
            x: currentGuideLineSVG.getBounds().center.x,
            y: currentGuideLineSVG.getBounds().center.y
          };
        }

        currentGuideLineSVG.name = name;
        currentGuideLineSVG.isLine = isLine;
        currentGuideLineSVG.isDot = isDot;
        currentGuideLineSVG.isReversed = isReversed;
        currentGuideLineSVG.isTransportPath = isTransport;
        currentGuideLineSVG.isContinuePath = isContinue;
        currentGuideLineSVG.isSegmented = isSegmented;
        currentGuideLineSVG.isWait = isWait;
        currentGuideLineSVG.removeSegments = removeSegments;

        return currentGuideLineSVG;
      });

    this.guidelineSVGs = guidelineSVGs.filter(guideline => !!guideline);
  }
}

export const guidelineManager = new GuidelineManager();
