import { Howl } from 'howler';

const ERR_HOWL = src => ({
  /* eslint-disable no-console */
  play: () => console.warn(`There was an error loading the sound ${src}, unable to play it.`),
  stop: () => console.warn(`There was an error loading the sound ${src}, unable to stop it.`),
  pause: () => console.warn(`There was an error loading the sound ${src}, unable to pause it.`),
  mute: () => console.warn(`There was an error loading the sound ${src}, unable to mute it.`)
  /* eslint-enable no-console */
});

export function getAudioAsset(filePath, url = null) {
  return new Promise(resolve => {
    try {
      let getSoundContext = require.context('../assets/sounds/', true);
      const src = url ? url : getSoundContext(filePath).default;

      const audio = new Howl({
        src,
        html5: false,
        preload: true,
        autoUnlock: false,
        ctx: true,
        onload: () => {
          resolve(audio);
        },
        onloaderror: (e, msg) => {
          audio.unload();
          resolve(ERR_HOWL(filePath, msg));
        },
        onplayerror: () => {
          audio.once('unlock', function() {
            audio.play();
          });
        }
      });
    } catch (e) {
      resolve(ERR_HOWL(filePath));
    }
  });
}
