import {
  ERROR_LOGGER_START,
  ERROR_LOGGER_STOP,
  ERROR_LOGGER_ADD_ERROR,
  ERROR_LOGGER_RESET
} from 'store/types/errorLogger';

export const initialState = {
  startTime: '',
  endTime: '',
  errorArray: [],
  isRunning: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ERROR_LOGGER_START:
      return { ...state, isRunning: true, startTime: action.payload };
    case ERROR_LOGGER_STOP:
      return { ...state, isRunning: false, endTime: action.payload };
    case ERROR_LOGGER_ADD_ERROR:
      return { ...state, errorArray: state.errorArray.concat(action.payload) };
    case ERROR_LOGGER_RESET:
      return initialState;
    default:
      return state;
  }
};
