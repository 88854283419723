import { showSpinner, hideSpinner, logApiError } from './ui';
import Api, { createEvent } from 'utils/api';
import { audioManager } from 'utils/draw-activity/audio-manager';
import { CharacterType, CharacterCase } from 'constants/enums/characters';
import {
  GET_ACTIVITY_DATA_SUCCESS,
  START_ACTIVITY,
  RESET_ACTIVITY_STORE
} from 'store/types/activity';

import { errorLoggerStart, errorLoggerStop, errorLoggerReset } from './errorLogger';
import { showAward } from './award';

import { getViewportSize } from 'utils/get-viewport-size';
import { AuthStrategy } from '@lwtears/lwt-common-frontend/lib/@common/util/api-util';

export const activityDataSuccess = data => ({ type: GET_ACTIVITY_DATA_SUCCESS, payload: data });

export const startActivity = () => ({ type: START_ACTIVITY });

export const resetActivityStore = () => ({ type: RESET_ACTIVITY_STORE });

export const getActivitySequence = character => async (dispatch, getState) => {
  dispatch(resetActivityStore());
  dispatch(showSpinner());

  const {
    ui: { module }
  } = getState();

  const { data, status, error } = await Api.get('/students/me/next-sequence', {
    module,
    params: { character }
  });

  if (status < 400 && data && !error) {
    const { character, activities, sequenceId } = data;
    const {
      audio,
      data: {
        instructions,
        svg: { segments, src: svgSrc },
        card
      },
      sequenceActivityId,
      activityId,
      activityTemplateId,
      type,
      level
    } = activities[0];

    const activityData = {
      activityId,
      activityTemplateId,
      audio,
      card,
      character,
      instructions,
      level,
      segments,
      sequenceId,
      sequenceActivityId,
      svg: svgSrc,
      type
    };

    dispatch(activityDataSuccess(activityData));
    await audioManager.loadActivitySpecificSounds(activityData);
  } else {
    dispatch(logApiError(error));
  }

  dispatch(hideSpinner());

  return status;
};

export const handleActivityStart = () => dispatch => {
  dispatch(startActivity());
  dispatch(errorLoggerStart());
};

export const handleRoundEnd = () => async dispatch => {
  dispatch(errorLoggerStop());
  dispatch(showSpinner());

  const { status } = await dispatch(postProgressData());

  if (status < 400) {
    dispatch(sendEventData());
    dispatch(endRound());
    dispatch(showAward());
  } else {
    // error handling ?
    dispatch(logApiError());
  }

  dispatch(hideSpinner());
};

export const endRound = () => dispatch => {
  dispatch(errorLoggerReset());
};

export const postProgressData = () => async (dispatch, getState) => {
  const {
    errorLogger: { startTime, endTime, errorArray },
    activity: {
      data: { sequenceActivityId = 0 }
    },
    student: { classroom },
    ui: { module }
  } = getState();

  const elapsedTime = Math.floor((endTime - startTime) / 1000);
  const options = {
    module,
    data: {
      classroomId: classroom.id,
      sequenceActivityId,
      clientInfo: {
        viewport: getViewportSize()
      },
      progressData: {
        complete: true,
        elapsedTime
      },
      ...(errorArray.length ? { errorData: errorArray } : {})
    }
  };

  const { status, error } = await Api.post('/students/me/progress', options);

  if (status >= 400 || error) return { error, status };

  return { status, error };
};

export const sendEventData = () => (dispatch, getState) => {
  const {
    errorLogger: { startTime, endTime, errorArray: errorData },
    ui: { module }
  } = getState();

  const elapsedTime = Math.floor((endTime - startTime) / 1000);
  const progressData = { complete: true, elapsedTime };

  createEvent('activityCompleted', {
    module,
    data: {
      progressData,
      ...(errorData ? { errorCount: errorData.length } : {})
    }
  });
};

const getModule = (character, characterType, characterCase) => {
  if (characterType === CharacterType.PRINT)
    return characterCase === CharacterCase.UPPERCASE ? 'wdt-capitals' : 'wdt-lowercase';
  if (characterType === CharacterType.NUMBER) return 'wdt-numbers';
  if (character.length === 2) return 'wdt-cursive-connections';

  return characterCase === CharacterCase.UPPERCASE
    ? 'wdt-cursive-capitals'
    : 'wdt-cursive-lowercase';
};

export const getLiteData = ({
  character,
  liteCharacterSequence = [],
  characterType,
  characterCase,
  liteCharacterCaseSequence = [],
  level = 1
}) => async dispatch => {
  dispatch(showSpinner());

  const { data, status, error } = await Api.get(
    '/activities',
    AuthStrategy.APP_SERVICE_CREDENTIALS,
    {
      module: getModule(character, characterType, characterCase),
      params: {
        character,
        ...(characterCase && { characterCase }),
        characterType,
        level,
        limit: 1
      }
    }
  );

  if (status < 400 && data && !error) {
    const { character, activities, sequenceId } = data[0];
    const {
      audio,
      data: {
        instructions,
        svg: { segments, src: svgSrc }
      },
      sequenceActivityId,
      activityId,
      activityTemplateId,
      type,
      level
    } = activities[0];
    const activityData = {
      activityId,
      activityTemplateId,
      audio,
      character,
      liteCharacterSequence,
      liteCharacterCaseSequence,
      instructions,
      level,
      segments,
      sequenceId,
      sequenceActivityId,
      svg: svgSrc,
      type
    };

    dispatch(activityDataSuccess(activityData));
    await audioManager.loadActivitySpecificSounds(activityData);
  } else {
    dispatch(logApiError(error));
  }

  dispatch(hideSpinner());

  return status;
};
