/* eslint-disable */
import React, { Component } from 'react';
import { getSessionId, setSessionId } from '@lwtears/lwt-common-frontend/lib/@common';
import { withRouter, useHistory } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { requestModules } from 'store/actions/login';
import { requestStudentSession, setStudentTolerance } from 'store/actions/student';
import { loadAssets, setLiteVersion, setParentZoomFactor } from 'store/actions/ui';
import { getLiteData } from 'store/actions/activity';
import { setSettings } from 'store/actions/settings';
import { toBoolean } from 'utils/string-util';
import { formatCharacterCase, getSequence } from 'utils/lite-route-char-arr-query-param-util';

import qs from 'qs';

import Routes from 'constants/routes';
import Navigation from 'components/Navigation';

import { showNavigation, hideNavigation } from 'store/actions/ui';
import LoadScreen from '../../components/LoadScreen/LoadScreen';
import { applyA11ySettings } from '@lwtears/lwt-common-frontend/lib/@common/util/a11y-util';
import { getAudioVendor } from 'utils/accessibilityUtils';
import { withLwtStore } from '@lwtears/lwt-common-frontend/lib/LwtApp';

const Version = () => {
  const history = useHistory();

  return history.location.pathname === '/modules' ? (
    <div
      style={{
        color: 'lightgray',
        textAlign: 'right',
        paddingBottom: 4,
        paddingRight: 8,
        fontSize: 8
      }}
    >
      Version: {process.env.REACT_APP_APP_VERSION}
    </div>
  ) : (
    <></>
  );
};

class App extends Component {
  constructor() {
    super();

    this.state = {
      deviceReady: false
    };

    this.logoutTimeout = null;
    this.logoutTimeoutDuration = (parseInt(process.env.REACT_APP_LOGOUT_TIMER) || 60) * 60 * 1000;
  }

  getModules = async sessionId => {
    const { history, requestModules, requestStudentSession, loadAssets } = this.props;
    await requestStudentSession(sessionId, this.props.lwtStoreDispatch);
    // settings here
    setSessionId(sessionId);
    await requestModules();
    await loadAssets();
    history.push(`/modules`);
    sessionStorage.setItem('loaded', 'true');
  };

  deviceReady() {
    this.setState({
      deviceReady: true
    });
  }

  onRouteChanged() {
    const {
      props: {
        showNavigation,
        hideNavigation,
        location: { pathname },
        accessibilityMode,
        isLiteVersion
      }
    } = this;

    if (isLiteVersion && !accessibilityMode) {
      hideNavigation();
    } else if (pathname === '/' || pathname.includes('/session') || pathname === '/modules') {
      hideNavigation();
    } else {
      showNavigation();
    }
  }

  redirectToLogin() {
    const { REACT_APP_GSS_LOGIN_URL } = process.env;
    window.location.href = REACT_APP_GSS_LOGIN_URL;
  }

  async componentDidMount() {
    this.initEventListeners();
    const {
      props: {
        location: { search },
        history,
        setLiteVersion,
        getLiteData,
        setStudentTolerance,
        lwtStoreDispatch,
        setParentZoomFactor,
        requestStudentSession
      }
    } = this;

    this.deviceReady();

    const {
      session: routeSession,
      lite,
      character,
      characterCase,
      characterType,
      level,
      tolerance,
      accessibilityMode,
      zoom
    } = qs.parse(search, {
      comma: true,
      ignoreQueryPrefix: true
    });

    const isAccessibilityMode =
      toBoolean(accessibilityMode) || toBoolean(sessionStorage.getItem('accessibilityMode'));

    const sessionId = routeSession || getSessionId();

    if (isAccessibilityMode) {
      sessionStorage.setItem('accessibilityMode', isAccessibilityMode);
      this.props.setSettings({
        accessibilityMode: isAccessibilityMode
      });
    }

    if (lite) {
      setLiteVersion(true);

      sessionId && (await requestStudentSession(sessionId, this.props.lwtStoreDispatch));

      const liteCharacterCaseSequence = getSequence(characterCase);
      const liteCharacterSequence = getSequence(character);
      await getLiteData({
        character: formatCharacterCase(liteCharacterSequence[0], liteCharacterCaseSequence[0]),
        liteCharacterSequence,
        characterType,
        characterCase: liteCharacterCaseSequence[0],
        liteCharacterCaseSequence,
        level
      });

      tolerance && setStudentTolerance(tolerance);
      lwtStoreDispatch.setSettings({ isLiteRoute: true });
      history.push('/draw-character');

      setParentZoomFactor(zoom ? parseFloat(zoom) : 1);
    } else if (sessionId) {
      await this.getModules(sessionId);
    } else this.redirectToLogin();

    applyA11ySettings(this.props.lwtStoreState.settings, getAudioVendor());
  }

  componentDidUpdate(prevProps) {
    const { activityTimestamp: prevActivityTimestamp, location: prevLocation } = prevProps;
    const {
      props: { activityTimestamp, location },
      logoutTimeoutDuration,
      isLiteVersion
    } = this;

    if (location !== prevLocation) {
      this.onRouteChanged();
    }

    if (activityTimestamp && prevActivityTimestamp !== activityTimestamp) {
      // eslint-disable-next-line no-console
      console.log(
        `setting new logout timer, you have ${logoutTimeoutDuration /
          1000 /
          60} minutes. use them wisely!`
      );

      if (!isLiteVersion) {
        this.logoutTimeout && clearTimeout(this.logoutTimeout);
        this.logoutTimeout = setTimeout(() => this.redirectToLogin(), logoutTimeoutDuration);
      }
    }
  }

  componentWillUnmount() {
    this.logoutTimeout && clearTimeout(this.logoutTimeout);
  }

  initEventListeners = () => {
    const classList = document.body.classList;
    document.addEventListener('mousedown', () => {
      if (classList.contains('using-keyboard')) classList.remove('using-keyboard');
    });
    document.addEventListener('keydown', event => {
      if (event.key === 'Tab' && !classList.contains('using-keyboard')) {
        classList.add('using-keyboard');
      }
    });
  };

  render() {
    const {
      props: { loading, hasNavigation }
    } = this;

    return (
      <>
        <LoadScreen loading={loading} />
        <div className={`mainApp app-container ${hasNavigation ? 'hasNav' : ''}`} role="main">
          <Routes />
        </div>
        <Navigation />
        <Version />
      </>
    );
  }
}

App.propTypes = {
  loading: PropTypes.bool.isRequired,
  requestModules: PropTypes.func,
  requestStudentSession: PropTypes.func,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string
  }),
  history: PropTypes.object,
  hasNavigation: PropTypes.bool.isRequired,
  showNavigation: PropTypes.func,
  hideNavigation: PropTypes.func,
  loadAssets: PropTypes.func.isRequired,
  activityTimestamp: PropTypes.number,
  isLiteVersion: PropTypes.bool,
  setLiteVersion: PropTypes.func,
  setLiteData: PropTypes.func,
  setStudentTolerance: PropTypes.func,
  studentSettings: PropTypes.object,
  parentZoomFactor: PropTypes.number,
  setParentZoomFactor: PropTypes.func
};

const mapStateToProps = ({ ui, student, settings }) => ({
  loading: ui.loading,
  hasNavigation: ui.navigation.showNavigation,
  activityTimestamp: ui.activityTimestamp,
  isLiteVersion: ui.isLiteVersion,
  studentSettings: student.settings,
  accessibilityMode: settings.accessibilityMode,
  parentZoomFactor: ui.parentZoomFactor
});

const mapDispatchToProps = {
  showNavigation,
  hideNavigation,
  requestModules,
  requestStudentSession,
  loadAssets,
  setLiteVersion,
  getLiteData,
  setStudentTolerance,
  setSettings,
  setParentZoomFactor
};
const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(withLwtStore(App));

export default withRouter(ConnectedApp);
