import { SoundTypes } from './../constants/enums/sound-types';

const modulesAudio = 'modules/audio';
const activityAudio = 'activities/wet-dry-try/audio';

export const SoundsMapping = {
  // Welcome page
  [SoundTypes.SUCCESS.name]: {
    path: `${activityAudio}/affirmations`,
    fileList: [
      {
        src: 'alright.mp3',
        captions: 'alright.json',
        vtt: 'alright.vtt'
      },
      {
        src: 'cool-beans.mp3',
        captions: 'cool-beans.json',
        vtt: 'cool-beans.vtt'
      },
      {
        src: 'excellent.mp3',
        captions: 'excellent.json',
        vtt: 'excellent.vtt'
      },
      {
        src: 'good.mp3',
        captions: 'good.json',
        vtt: 'good.vtt'
      },
      {
        src: 'great-job.mp3',
        captions: 'great-job.json',
        vtt: 'great-job.vtt'
      },
      {
        src: 'great.mp3',
        captions: 'great.json',
        vtt: 'great.vtt'
      },
      {
        src: 'nice.mp3',
        captions: 'nice.json',
        vtt: 'nice.vtt'
      },
      {
        src: 'perfect.mp3',
        captions: 'perfect.json',
        vtt: 'perfect.vtt'
      },
      {
        src: 'thats-right.mp3',
        captions: 'thats-right.json',
        vtt: 'thats-right.vtt'
      },
      {
        src: 'very-good.mp3',
        captions: 'very-good.json',
        vtt: 'very-good.vtt'
      },
      {
        src: 'way-to-go.mp3',
        captions: 'way-to-go.json',
        vtt: 'way-to-go.vtt'
      },
      {
        src: 'wonderful.mp3',
        captions: 'wonderful.json',
        vtt: 'wonderful.vtt'
      },
      {
        src: 'yes.mp3',
        captions: 'yes.json',
        vtt: 'yes.vtt'
      },
      {
        src: 'you-are-smart.mp3',
        captions: 'you-are-smart.json',
        vtt: 'you-are-smart.vtt'
      },
      {
        src: 'you-got-it-right.mp3',
        captions: 'you-got-it-right.json',
        vtt: 'you-got-it-right.vtt'
      },
      {
        src: 'you-got-it.mp3',
        captions: 'you-got-it.json',
        vtt: 'you-got-it.vtt'
      },
      {
        src: 'you-rock.mp3',
        captions: 'you-rock.json',
        vtt: 'you-rock.vtt'
      }
    ]
  },
  // Errors instructions
  [SoundTypes.REVERSAL.name]: {
    path: `${activityAudio}/errors/reversal`,
    fileList: [
      {
        src: 'dont-go-backwards-2.mp3',
        captions: 'dont-go-backwards-2.json',
        vtt: 'dont-go-backwards-2.vtt'
      },
      {
        src: 'dont-go-backwards.mp3',
        captions: 'dont-go-backwards.json',
        vtt: 'dont-go-backwards.vtt'
      },
      {
        src: 'not-that-way.mp3',
        captions: 'not-that-way.json',
        vtt: 'not-that-way.vtt'
      },
      {
        src: 'oops-wrong-way.mp3',
        captions: 'oops-wrong-way.json',
        vtt: 'oops-wrong-way.vtt'
      },
      {
        src: 'whoa-not-that-way.mp3',
        captions: 'whoa-not-that-way.json',
        vtt: 'whoa-not-that-way.vtt'
      },
      {
        src: 'oops-not-backwards.mp3',
        captions: 'whoops-not-backwards.json',
        vtt: 'whoops-not-backwards.vtt'
      }
    ]
  },
  [SoundTypes.FINGER_DOWN.name]: {
    path: `${activityAudio}/errors/finger-up`,
    fileList: [
      {
        src: 'keep-that-finger-on-the-board.mp3',
        captions: 'keep-that-finger-on-the-board.json',
        vtt: 'keep-that-finger-on-the-board.vtt'
      },
      {
        src: 'opps-keep-your-finger-down.mp3',
        captions: 'opps-keep-your-finger-down.json',
        vtt: 'opps-keep-your-finger-down.vtt'
      },
      {
        src: 'whoa-stay-on-the-board.mp3',
        captions: 'whoa-stay-on-the-board.json',
        vtt: 'whoa-stay-on-the-board.vtt'
      }
    ]
  },
  [SoundTypes.OUT_OF_BOUNDS.name]: {
    path: `${activityAudio}/errors/out-of-bounds`,
    fileList: [
      {
        src: 'give-it-another-shot.mp3',
        captions: 'give-it-another-shot.json',
        vtt: 'give-it-another-shot.vtt'
      },
      {
        src: 'give-it-another-shot.mp3',
        captions: 'give-it-another-shot.json',
        vtt: 'give-it-another-shot.vtt'
      },
      {
        src: 'give-that-another-go.mp3',
        captions: 'give-that-another-go.json',
        vtt: 'give-that-another-go.vtt'
      },
      {
        src: 'hey-give-it-another-shot.mp3',
        captions: 'hey-give-it-another-shot.json',
        vtt: 'hey-give-it-another-shot.vtt'
      },
      {
        src: 'hey-try-it-once-more.mp3',
        captions: 'hey-try-it-once-more.json',
        vtt: 'hey-try-it-once-more.vtt'
      },
      {
        src: 'lets-give-it-another-try.mp3',
        captions: 'lets-give-it-another-try.json',
        vtt: 'lets-give-it-another-try.vtt'
      },
      {
        src: 'lets-try-that-again-2.mp3',
        captions: 'lets-try-that-again-2.json',
        vtt: 'lets-try-that-again-2.vtt'
      },
      {
        src: 'lets-try-that-again.mp3',
        captions: 'lets-try-that-again.json',
        vtt: 'lets-try-that-again.vtt'
      },
      {
        src: 'oh-try-it-another-time.mp3',
        captions: 'oh-try-it-another-time.json',
        vtt: 'oh-try-it-another-time.vtt'
      },
      {
        src: 'oops-try-that-one-again.mp3',
        captions: 'oops-try-that-one-again.json',
        vtt: 'oops-try-that-one-again.vtt'
      },
      {
        src: 'uh-oh-lets-try-again.mp3',
        captions: 'uh-oh-lets-try-again.json',
        vtt: 'uh-oh-lets-try-again.vtt'
      },
      {
        src: 'whoa-try-that-part-again.mp3',
        captions: 'whoa-try-that-part-again.json',
        vtt: 'whoa-try-that-part-again.vtt'
      }
    ]
  },
  [SoundTypes.REPLAY_DEMO.name]: {
    path: `${activityAudio}/replay-demo`,
    fileList: [
      {
        src: 'here-like-this.mp3',
        captions: 'here-like-this.json',
        vtt: 'here-like-this.vtt'
      },
      {
        src: 'heres-how-it-goes.mp3',
        captions: 'heres-how-it-goes.json',
        vtt: 'heres-how-it-goes.vtt'
      },
      {
        src: 'ill-show-you-that-part-again.mp3',
        captions: 'ill-show-you-that-part-again.json',
        vtt: 'ill-show-you-that-part-again.vtt'
      },
      {
        src: 'let-me-show-you-again.mp3',
        captions: 'let-me-show-you-again.json',
        vtt: 'let-me-show-you-again.vtt'
      },
      {
        src: 'let-me-show-you-this-part-again.mp3',
        captions: 'let-me-show-you-this-part-again.json',
        vtt: 'let-me-show-you-this-part-again.vtt'
      },
      {
        src: 'now-you-try-it.mp3',
        captions: 'now-you-try-it.json',
        vtt: 'now-you-try-it.vtt'
      },
      {
        src: 'watch-that-part-one-more-time.mp3',
        captions: 'watch-that-part-one-more-time.json',
        vtt: 'watch-that-part-one-more-time.vtt'
      }
    ]
  },
  // [SoundTypes.NOW_YOU.name]: {
  //   path: activityAudio,
  //   fileList: [
  //     {
  //       src: 'now-you-try.mp3',
  //       captions: 'now-you-try.json',
  //       vtt: 'now-you-try.vtt'
  //     }
  //   ]
  // },
  [SoundTypes.WATCH_FOR_CLUE.name]: {
    path: activityAudio,
    fileList: [
      {
        src: 'watch-for-the-clue-about-where-to-start-each-stroke.mp3',
        captions: 'watch-for-the-clue-about-where-to-start-each-stroke.json',
        vtt: 'watch-for-the-clue-about-where-to-start-each-stroke.vtt'
      }
    ]
  },
  [SoundTypes.NO_CLUES.name]: {
    path: activityAudio,
    fileList: [
      {
        src: 'no-clues-this-time-start-each-stroke-correctly-on-your-own.mp3',
        captions: 'no-clues-this-time-start-each-stroke-correctly-on-your-own.json',
        vtt: 'no-clues-this-time-start-each-stroke-correctly-on-your-own.vtt'
      }
    ]
  },
  // Welcome page
  [SoundTypes.PICK_AND_PRACTICE.name]: {
    path: modulesAudio,
    fileList: [
      {
        src: 'pick-and-practice.mp3',
        captions: 'pick-and-practice.json',
        vtt: 'pick-and-practice.vtt'
      }
    ]
  },
  // effects
  [SoundTypes.DING_WRONG.name]: {
    path: `${activityAudio}/sound-effects`,
    fileList: [
      { src: 'ding-incorrect.mp3', captions: 'ding-incorrect.json', vtt: 'ding-incorrect.vtt' }
    ]
  },
  [SoundTypes.DING_CORRECT.name]: {
    path: `${activityAudio}/sound-effects`,
    fileList: [{ src: 'ding-correct.mp3', captions: 'ding-correct.json', vtt: 'ding-correct.vtt' }]
  },
  [SoundTypes.DING_CONTINUE.name]: {
    path: `${activityAudio}/sound-effects`,
    fileList: [
      { src: 'ding-continue.mp3', captions: 'ding-continue.json', vtt: 'ding-continue.vtt' }
    ]
  },
  [SoundTypes.DING_PICKUP.name]: {
    path: `${activityAudio}/sound-effects`,
    fileList: [{ src: 'ding-pickup.mp3', captions: 'ding-pickup.json', vtt: 'ding-pickup.vtt' }]
  },
  [SoundTypes.CHALK.name]: {
    path: `${activityAudio}/sound-effects`,
    fileList: [{ src: 'chalk.mp3', captions: 'chalk.json', vtt: 'chalk.vtt' }]
  },
  [SoundTypes.PAPER.name]: {
    path: `${activityAudio}/sound-effects`,
    fileList: [{ src: 'paper-towel.mp3', captions: 'paper-towel.json', vtt: 'paper-towel.vtt' }]
  },
  [SoundTypes.SPONGE.name]: {
    path: `${activityAudio}/sound-effects`,
    fileList: [{ src: 'sponge.mp3', captions: 'sponge.json', vtt: 'sponge.vtt' }]
  },
  [SoundTypes.BUTTON_CLICK.name]: {
    path: `${activityAudio}/sound-effects`,
    fileList: [{ src: 'button-click.mp3', captions: 'button-click.json', vtt: 'button-click.vtt' }]
  }
};
