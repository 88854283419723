import paper from 'paper/dist/paper-full.min';

export function scaleSVG(SVG, canvasEl, isFullscreen) {
  const { height, width } = canvasEl.getBoundingClientRect();
  const mask = new paper.Rectangle(
    0,
    isFullscreen ? 0 : (12.5 / 100) * height,
    width,
    isFullscreen ? height : height - (25 / 100) * height
  );

  SVG.fitBounds(mask);
}
