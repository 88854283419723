export const CharacterType = {
  PRINT: 'print',
  CURSIVE: 'cursive',
  NUMBER: 'number'
};

export const CharacterCase = {
  UPPERCASE: 'uppercase',
  LOWERCASE: 'lowercase'
};

export const OrderType = {
  ABC_ORDER: 'abc',
  HWT_ORDER: 'hwt'
};
