import { SHOW_AWARD, HIDE_AWARD, SET_CURRENT_CARD } from '../types/award';

export const initialState = {
  showAward: false,
  currentCard: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_AWARD:
      return { ...state, showAward: true };
    case HIDE_AWARD:
      return { ...state, showAward: false };
    case SET_CURRENT_CARD:
      return { ...state, currentCard: action.payload };
    default:
      return state;
  }
};
