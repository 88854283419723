import React from 'react';
import PropTypes from 'prop-types';
import MatManWdt from 'assets/photo/mat-man-spinner/mat-man-wdt.png';
import Spinner from 'assets/svg/images/spinner.svg';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const LoadScreen = ({ loading }) => {
  if (!loading) return null;

  return (
    <div className="loader-container">
      <div className="mat-man-container">
        <div className="loader-wrapper">
          <img className="mat-man-image" src={MatManWdt} alt={tA11y('loading')} />
          <p className="load-text">{loading && 'Loading...'}</p>
          <div className="spinner">
            <img src={Spinner} alt={tA11y('spinner')} />
          </div>
        </div>
      </div>
    </div>
  );
};

LoadScreen.propTypes = {
  loading: PropTypes.bool
};

export default LoadScreen;
