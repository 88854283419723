import {
  ERROR_LOGGER_START,
  ERROR_LOGGER_STOP,
  ERROR_LOGGER_ADD_ERROR,
  ERROR_LOGGER_RESET
} from 'store/types/errorLogger';

export const errorLoggerStart = () => ({ type: ERROR_LOGGER_START, payload: Date.now() });
export const errorLoggerStop = () => ({ type: ERROR_LOGGER_STOP, payload: Date.now() });
export const errorLoggerLogError = error => ({ type: ERROR_LOGGER_ADD_ERROR, payload: error });
export const errorLoggerReset = () => ({ type: ERROR_LOGGER_RESET });
