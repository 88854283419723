import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import getDrawConfiguration from 'utils/draw-config';
import chalkTexturePath from 'assets/photo/draw-stage/chalk-texture.png';
import chalkPointerPath from 'assets/photo/draw-stage/chalk-pointer.png';
import chalkPointerLeftHandPath from 'assets/photo/draw-stage/chalk-pointer-left-hand.png';
import { getScaleDegree } from 'utils/draw-activity/canvas-manager';
import { SoundTypes } from 'constants/enums/sound-types';
import { TextureTypes } from 'constants/enums/texture-types';
import { audioManager } from 'utils/draw-activity/audio-manager';
import textureManager from 'utils/draw-activity/texture-manager';
import { scaleSVG } from 'utils/draw-activity/svg-manager';
import { resizeCanvas } from 'utils/draw-activity/canvas-manager';
import { guidelineManager } from 'utils/draw-activity/guideline-manager';
import paper from 'paper/dist/paper-full.min';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

class DrawActivityTutorial extends Component {
  constructor(props) {
    super(props);

    this._canvas = null;
    this.paperScope = null;
    this.chalkPointer = null;
    this.handbrake = null;
    this.guideLineSVGs = null;
    this.guideLineIndex = 0;
    this.handleSVGLoaded = this.handleSVGLoaded.bind(this);
    this.segmentSoundNames = [];
    this.shouldComplete = false;
  }

  componentDidMount() {
    const {
      props: { paperScope, container, canvas, character, characterType, characterCase, segments }
    } = this;

    paperScope.setup(canvas.current);
    paperScope.install(paper);
    paperScope.activate();

    let scaleProportion = 1;
    const iPadWidth = 1536 / 2;
    let currentWidth = container.current.getBoundingClientRect().width;
    let originalDrawConfig = getDrawConfiguration(character, characterType, characterCase);

    scaleProportion = currentWidth / iPadWidth;

    this.drawConfig = {
      density: originalDrawConfig.density * scaleProportion,
      timeInterval: originalDrawConfig.timeInterval
    };

    this.props.paperScope.project.importSVG(this.props.characterSVGPath, {
      onLoad: this.handleSVGLoaded
    });

    this.segmentSoundNames = segments.map(segment => segment.id);
  }

  componentDidUpdate(prevProps) {
    if (this.shouldComplete && !!prevProps.ongoingSound && !this.props.ongoingSound) {
      this.props.onComplete();
    }
  }

  handleSVGLoaded(characterSVG) {
    scaleSVG(characterSVG, this.props.canvas.current, this.props.fullscreen);

    resizeCanvas(this.props.canvas.current, this.props.container.current);

    guidelineManager.getGuidelines(
      this.props.segments,
      this.props.paperScope.project,
      this.drawConfig.density
    );

    guidelineManager.setCurrentGuideline(this.guideLineIndex);

    this.chalkPointer = this.initializeChalkPointer(guidelineManager.currentGuideline);

    textureManager
      .initializeTexture(TextureTypes.CHALK, this.props.paperScope, this.props.canvas.current)
      .then(this.drawCharacter.bind(this), this.props.setShowBackButton());

    const exposedData = guidelineManager.guidelineSVGs.map(el => ({
      name: el.name,
      coordinates: el.data.coordinates
    }));
    // eslint-disable-next-line
    console.log(exposedData);
  }

  componentWillUnmount() {
    if (this.timeoutList && this.timeoutList.length > 0) {
      this.timeoutList.cancelAll();
    }

    if (this.handbrake) {
      clearInterval(this.handbrake);
    }
  }

  drawCharacter() {
    this.setChalkPointOpacity(guidelineManager.currentGuideline.isTransportPath);

    if (guidelineManager.currentGuideline.isLine) {
      this.drawPath(this.drawConfig.timeInterval).then(() => {
        this.advanceCharacterDraw();
      });
    } else {
      const sound = {
        name: this.segmentSoundNames[this.guideLineIndex],
        category: 'charInstructions'
      };
      audioManager.playSound(sound);
      const { x, y } = guidelineManager.getGuidelineBounds();

      textureManager.drawDenseTexturePoint({
        x,
        y,
        characterType: this.props.characterType,
        characterCase: this.props.characterCase,
        character: this.props.character
      });
      this.advanceCharacterDraw();
    }
  }

  advanceCharacterDraw() {
    this.guideLineIndex++;
    guidelineManager.setCurrentGuideline(this.guideLineIndex);

    if (this.guideLineIndex < guidelineManager.guidelineSVGs.length) {
      this.drawCharacter();
    } else {
      this.setChalkPointOpacity(false, true);
      this.shouldComplete = true;
      if (this.props.onComplete && this.props.animationsModeOn) {
        this.props.onComplete();
      }
    }
  }

  setChalkPointOpacity(isTransportPath, hide) {
    if (hide) {
      this.chalkPointer.opacity = 0;
    } else {
      if (isTransportPath) {
        this.chalkPointer.opacity = 0.5;
      } else {
        this.chalkPointer.opacity = 1;
      }
    }
  }

  getDontWaitForSound() {
    let isNextContinuePath = false;
    let nextSoundWaits = false;
    let dontWaitForSound = false;

    if (this.guideLineIndex < guidelineManager.guidelineSVGs.length - 1) {
      isNextContinuePath =
        guidelineManager.guidelineSVGs[this.guideLineIndex + 1].removeSegments > 0;
      nextSoundWaits = guidelineManager.guidelineSVGs[this.guideLineIndex + 1].isWait;

      if (isNextContinuePath) dontWaitForSound = true;

      if (nextSoundWaits) dontWaitForSound = false;
    }

    return dontWaitForSound;
  }

  drawPath(timeInterval) {
    const guideLineSVG = guidelineManager.currentGuideline;
    const length = guideLineSVG.data.coordinates.length - 1;
    let index = guideLineSVG.isReversed ? length : 0;
    let x = 0;
    let y = 0;

    let dontWaitForSound = this.getDontWaitForSound();

    if (!guideLineSVG.isTransportPath) {
      audioManager.playSound(SoundTypes.CHALK);
    }

    return new Promise(resolve => {
      let soundReady = false;
      let drawReady = false;
      let failPlay = false;

      const sound = {
        name: this.segmentSoundNames[this.guideLineIndex],
        category: 'charInstructions'
      };

      audioManager.playSound(sound, isFail => {
        failPlay = isFail;
        if (failPlay) {
          soundReady = true;
          dontWaitForSound = true;
        } else {
          soundReady = true;
          if (drawReady && !dontWaitForSound) {
            clearInterval(this.handbrake);
            resolve();
          }
        }
      });

      this.handbrake = setInterval(() => {
        if (guideLineSVG.isReversed) {
          if (index >= 0) {
            ({ x, y } = guideLineSVG.data.coordinates[index]);
            if (!guideLineSVG.isTransportPath) {
              textureManager.drawTexturePoint({
                x,
                y,
                characterType: this.props.characterType,
                characterCase: this.props.characterCase,
                character: this.props.character
              });
            }
            this.moveChalkPointer(this.chalkPointer, x, y);
            index--;
          } else {
            audioManager.pauseSound(SoundTypes.CHALK);
            drawReady = true;

            if (!this.props.lineInstructionWasStopped) {
              if (soundReady || dontWaitForSound) {
                clearInterval(this.handbrake);
                resolve();
              }
            } else {
              if (soundReady) {
                clearInterval(this.handbrake);
                resolve();
              }
            }
          }
        } else {
          if (index <= length) {
            ({ x, y } = guideLineSVG.data.coordinates[index]);
            if (!guideLineSVG.isTransportPath) {
              textureManager.drawTexturePoint({
                x,
                y,
                characterType: this.props.characterType,
                characterCase: this.props.characterCase,
                character: this.props.character
              });
            }
            this.moveChalkPointer(this.chalkPointer, x, y);
            index++;
          } else {
            audioManager.pauseSound(SoundTypes.CHALK);
            drawReady = true;

            if (!this.props.lineInstructionWasStopped) {
              if (soundReady || dontWaitForSound) {
                clearInterval(this.handbrake);
                resolve();
              }
            } else {
              if (soundReady) {
                clearInterval(this.handbrake);
                resolve();
              }
            }
          }
        }
      }, timeInterval);
    });
  }

  initializeChalkPointer(firstGuideLine) {
    const chalkPointer = new this.props.paperScope.Raster(
      `chalk-pointer${this.props.leftHand ? '-left-hand' : ''}`
    );
    const initialDirection = firstGuideLine.isReversed;
    const guideLineLength = firstGuideLine.length;
    let x = 0;
    let y = 0;
    const { width: canvasWidth } = this.props.canvas.current.getBoundingClientRect();
    const textureRadius = Math.floor((11 / 100) * canvasWidth);

    chalkPointer.opacity = 0;

    chalkPointer.onLoad = () => {
      chalkPointer.setWidth(textureRadius);
      chalkPointer.setHeight(textureRadius);
      chalkPointer.scale(
        getScaleDegree(this.props.characterType, this.props.characterCase, this.props.character)
      );

      if (initialDirection) {
        ({ x, y } = firstGuideLine.getPointAt(guideLineLength));
      } else {
        ({ x, y } = firstGuideLine.getPointAt(0));
      }

      chalkPointer.position = this.getNextChalkPointerPosition(
        chalkPointer,
        x,
        y,
        this.props.leftHand
      );

      chalkPointer.opacity = 1;
    };

    return chalkPointer;
  }

  moveChalkPointer(chalkPointer, x, y) {
    chalkPointer.position = this.getNextChalkPointerPosition(
      chalkPointer,
      x,
      y,
      this.props.leftHand
    );

    this.props.handleAutoScroll(x, y);
  }

  getNextChalkPointerPosition(chalkPointer, currentX, currentY, leftHand) {
    let nextPosition = null;

    if (leftHand) {
      nextPosition = {
        x: currentX - chalkPointer.bounds.width / 4,
        y: currentY + chalkPointer.bounds.height / 4
      };
    } else {
      nextPosition = {
        x: currentX + chalkPointer.bounds.width / 4,
        y: currentY + chalkPointer.bounds.height / 4
      };
    }

    return nextPosition;
  }

  render() {
    return (
      <div className="draw-instruments">
        <img id="chalk-texture" src={chalkTexturePath} alt={tA11y('chalkTexture')} />
        <img id="chalk-pointer" src={chalkPointerPath} alt={tA11y('chalkPointer')} />
        <img
          id="chalk-pointer-left-hand"
          src={chalkPointerLeftHandPath}
          alt={tA11y('chalkPointerLeftHand')}
        />
      </div>
    );
  }
}

DrawActivityTutorial.propTypes = {
  character: PropTypes.string.isRequired,
  characterType: PropTypes.string.isRequired,
  characterCase: PropTypes.string.isRequired,
  leftHand: PropTypes.bool,
  landscape: PropTypes.bool,
  fullscreen: PropTypes.bool,
  onComplete: PropTypes.func,
  container: PropTypes.object,
  canvas: PropTypes.object,
  paperScope: PropTypes.object,
  setShowBackButton: PropTypes.func,
  segments: PropTypes.array,
  characterSVGPath: PropTypes.string,
  handleAutoScroll: PropTypes.func,
  ongoingSound: PropTypes.object || null,
  animationsModeOn: PropTypes.bool,
  lineInstructionWasStopped: PropTypes.bool
};

const mapStateToProps = state => ({
  ongoingSound: state.ui.onGoingAnimation,
  animationsModeOn: state.ui.animationsModeOn,
  lineInstructionWasStopped: state.ui.lineInstructionWasStopped
});

export default connect(mapStateToProps)(DrawActivityTutorial);
