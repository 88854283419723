export const numbersList = require
  .context('assets/photo/character-lists/characters/numbers', false)
  .keys()
  .map(key => key);

export const upperCaseCharacterList = require
  .context('assets/photo/character-lists/characters/print/uppercase', false)
  .keys()
  .map(key => key);

export const lowerCaseCharacterList = require
  .context('assets/photo/character-lists/characters/print/lowercase', false)
  .keys()
  .map(key => key);

export const plainStickersNumbers = require
  .context('assets/photo/character-lists/plain-stickers/numbers', false)
  .keys()
  .map(key => key);

export const plainStickersUpperCase = require
  .context('assets/photo/character-lists/plain-stickers/print/uppercase', false)
  .keys()
  .map(key => key);

export const plainStickersLowerCase = require
  .context('assets/photo/character-lists/plain-stickers/print/lowercase', false)
  .keys()
  .map(key => key);

export const stickersNumbers = require
  .context('assets/photo/character-lists/plain-stickers/numbers', false)
  .keys()
  .map(key => key);

export const stickersUpperCase = require
  .context('assets/photo/character-lists/stickers/print/uppercase', false)
  .keys()
  .map(key => key);

export const stickersLowerCase = require
  .context('assets/photo/character-lists/stickers/print/lowercase', false)
  .keys()
  .map(key => key);

export const commonImages = [
  'assets/icons/one-star.png',
  'assets/photo/mat-man-spinner/mat-man-wdt.png',
  'assets/svg/images/spinner.svg'
];
