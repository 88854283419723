/********************************
Density - higher is less
Time Interval - lower is faster
********************************/

import { CharacterType } from '../constants/enums/characters';

const printConfig = {
  uppercase: {
    density: 10,
    timeInterval: 30
  },
  lowercase: {
    density: 5,
    timeInterval: 35
  }
};

const cursiveConfig = {
  uppercase: {
    density: 10,
    timeInterval: 30
  },
  lowercase: {
    density: 5,
    timeInterval: 45
  }
};

const numberConfig = {
  density: 10,
  timeInterval: 40
};

const connectionConfig = {
  density: 5,
  timeInterval: 40
};

const defaultConfig = {
  print: printConfig,
  cursive: cursiveConfig,
  number: numberConfig,
  connection: connectionConfig
};

const exceptionList = {
  print: {
    uppercase: {
      S: {
        density: 10,
        timeInterval: 50
      },
      O: {
        density: 10,
        timeInterval: 35
      },
      Q: {
        density: 10,
        timeInterval: 40
      }
    },
    lowercase: {
      t: {
        density: 5,
        timeInterval: 25
      },
      d: {
        density: 5,
        timeInterval: 30
      },
      e: {
        density: 5,
        timeInterval: 25
      },
      f: {
        density: 5,
        timeInterval: 25
      },
      i: {
        density: 5,
        timeInterval: 60
      },
      k: {
        density: 5,
        timeInterval: 25
      },
      l: {
        density: 5,
        timeInterval: 20
      },
      s: {
        density: 5,
        timeInterval: 60
      }
    }
  },
  cursive: {
    uppercase: {
      D: {
        density: 10,
        timeInterval: 35
      },
      G: {
        density: 10,
        timeInterval: 40
      },
      H: {
        density: 10,
        timeInterval: 25
      }
    },
    lowercase: {
      h: {
        density: 5,
        timeInterval: 45
      },
      a: {
        density: 5,
        timeInterval: 45
      },
      d: {
        density: 5,
        timeInterval: 30
      },
      e: {
        density: 5,
        timeInterval: 45
      },
      i: {
        density: 5,
        timeInterval: 45
      },
      j: {
        density: 5,
        timeInterval: 35
      },
      l: {
        density: 5,
        timeInterval: 35
      },
      m: {
        density: 5,
        timeInterval: 32
      },
      o: {
        density: 5,
        timeInterval: 35
      },
      p: {
        density: 5,
        timeInterval: 35
      },
      r: {
        density: 5,
        timeInterval: 35
      },
      t: {
        density: 5,
        timeInterval: 30
      },
      w: {
        density: 5,
        timeInterval: 13
      },
      x: {
        density: 5,
        timeInterval: 50
      },
      y: {
        density: 5,
        timeInterval: 35
      }
    }
  },
  number: {
    '8': {
      density: 10,
      timeInterval: 30
    },
    '0': {
      density: 10,
      timeInterval: 25
    }
  }
};

export default function getDrawConfiguration(character, characterType, characterCase) {
  if (characterType === CharacterType.NUMBER) {
    if (exceptionList[CharacterType.NUMBER][character])
      return exceptionList[CharacterType.NUMBER][character];
    return defaultConfig[CharacterType.NUMBER];
  }

  if (exceptionList[characterType][characterCase][character])
    return exceptionList[characterType][characterCase][character];

  if (character.length === 2) defaultConfig.connection;

  return defaultConfig[characterType][characterCase];
}
